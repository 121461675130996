import Vue from 'vue'
import VueRouter from 'vue-router'
import { getToken } from '@/utils/auth'
import store from '../store'

import homePage from '@/views/home/index'
import indexPage from '@/views/index/index'
import billPage from '@/views/bill/bill'
import orderPage from '@/views/order/order'
import teamPage  from '@/views/team/team'
import minePage from '@/views/mine/mine'

import loginPage from '@/views/login/login'
import registerPage from '@/views/login/register'
import resetPasswordPage from '@/views/login/resetPassword'

import orderDetailPage from '@/views/order/orderDetail'
import orderListPage from '@/views/order/orderList'
import billDetailPage from '@/views/bill/billDetail'

import settingPage from '@/views/setting/setting'
import langPage from '@/views/setting/lang'
import helpPage from '@/views/setting/help'
import editPasswordPage from '@/views/setting/editPassword'
import editSafePasswordPage from '@/views/setting/editSafePassword'
import downloadPage from '@/views/setting/download'

import aboutPage from '@/views/content/about'
import platformPage from '@/views/content/platform'
import companyPage from '@/views/content/company'
import rolePage from '@/views/content/role'
import shareRolePage from '@/views/content/shareRole'
import kefuPage from '@/views/content/kefu'
import kf2 from '@/views/content/kefu2'
import classroomPage from '@/views/content/classroom'

import financePage from '@/views/index/finance'
import financeLogPage from '@/views/index/financeLog'
import signPage from '@/views/index/sign'
import signLogPage from '@/views/index/signLog'
import integralPage from '@/views/index/integral'
import integralLogPage from '@/views/index/integralLog'
import rafflePage from '@/views/index/raffle'
import raffleLogPage from '@/views/index/raffleLog'

import rechargePage from '@/views/mine/recharge'
import rechargeUsdtPage from '@/views/mine/rechargeUsdt'
import rechargeBankPage from '@/views/mine/rechargeBank'
import rechargeInfoPage from '@/views/mine/rechargeInfo'
import rechargeLogPage from '@/views/mine/rechargeLog'
import withdrawPage from '@/views/mine/withdraw'
import withdrawUsdtPage from '@/views/mine/withdrawUsdt'
import withdrawBankPage from '@/views/mine/withdrawBank'
import withdrawLogPage from '@/views/mine/withdrawLog'
import bindCardPage from '@/views/mine/bindCard'
import bindUsdtPage from '@/views/mine/bindUsdt'
import sharePage from '@/views/mine/share'
import addressPage from '@/views/mine/address'
import bindAddressPage from '@/views/mine/bindAddress'
import debtPage from '@/views/mine/debt'

import otherPage from '@/views/index/other'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    meta: {
      index: 3
    },
    component: loginPage
  },
  {
    path: '/register',
    name: 'register',
    meta: {
      index: 4
    },
    component: registerPage
  },
  {
    path: '/resetPassword',
    name: 'resetPassword',
    meta: {
      index: 4
    },
    component: resetPasswordPage
  },
  {
    path: '/home',
    name: 'home',
    component: homePage,
    children: [
      {
        path: '/index',
        name: 'index',
        meta: {
          index: 1
        },
        component: indexPage,
      },
      {
        path: '/bill',
        name: 'bill',
        meta: {
          index: 1
        },
        component: billPage,
      },
      {
        path: '/order',
        name: 'order',
        meta: {
          index: 1
        },
        component: orderPage,
      },
      {
        path: '/team',
        name: 'team',
        meta: {
          index: 1
        },
        component: teamPage,
      },
      {
        path: '/mine',
        name: 'mine',
        meta: {
          index: 1
        },
        component: minePage,
      },

    ]
  },
  {
    path: '/finance',
    name: 'finance',
    meta: {
      index: 2
    },
    component: financePage,
  },
  {
    path: '/financeLog',
    name: 'financeLog',
    meta: {
      index: 3
    },
    component: financeLogPage,
  },
  {
    path: '/sign',
    name: 'sign',
    meta: {
      index: 2
    },
    component: signPage,
  },
  {
    path: '/signLog',
    name: 'signLog',
    meta: {
      index: 3
    },
    component: signLogPage,
  },
  {
    path: '/integral',
    name: 'integral',
    meta: {
      index: 2
    },
    component: integralPage,
  },
  {
    path: '/integralLog',
    name: 'integralLog',
    meta: {
      index: 3
    },
    component: integralLogPage,
  },
  {
    path: '/raffle',
    name: 'raffle',
    meta: {
      index: 2
    },
    component: rafflePage,
  },
  {
    path: '/raffleLog',
    name: 'raffleLog',
    meta: {
      index: 3
    },
    component: raffleLogPage,
  },
  {
    path: '/orderDetail',
    name: 'orderDetail',
    meta: {
      index: 4
    },
    component: orderDetailPage,
  },
  {
    path: '/orderList',
    name: 'orderList',
    meta: {
      index: 3
    },
    component: orderListPage,
  },
  {
    path: '/billDetail',
    name: 'billDetail',
    meta: {
      index: 3
    },
    component: billDetailPage,
  },
  {
    path: '/recharge',
    name: 'recharge',
    meta: {
      index: 2
    },
    component: rechargePage,
  },
  {
    path: '/rechargeUsdt',
    name: 'rechargeUsdt',
    meta: {
      index: 2
    },
    component: rechargeUsdtPage,
  },
  {
    path: '/rechargeBank',
    name: 'rechargeBank',
    meta: {
      index: 2
    },
    component: rechargeBankPage,
  },
  {
    path: '/rechargeInfo',
    name: 'rechargeInfo',
    meta: {
      index: 3
    },
    component: rechargeInfoPage,
  },
  {
    path: '/rechargeLog',
    name: 'rechargeLog',
    meta: {
      index: 3
    },
    component: rechargeLogPage,
  },
  {
    path: '/rechargeInfo',
    name: 'rechargeInfo',
    meta: {
      index: 3
    },
    component: rechargeInfoPage,
  },
  {
    path: '/withdraw',
    name: 'withdraw',
    meta: {
      index: 2
    },
    component: withdrawPage,
  },
  {
    path: '/withdrawUsdt',
    name: 'withdrawUsdt',
    meta: {
      index: 3
    },
    component: withdrawUsdtPage,
  },
  {
    path: '/withdrawBank',
    name: 'withdrawBank',
    meta: {
      index: 3
    },
    component: withdrawBankPage,
  },
  {
    path: '/withdrawLog',
    name: 'withdrawLog',
    meta: {
      index: 3
    },
    component: withdrawLogPage,
  },
  {
    path: '/bindCard',
    name: 'bindCard',
    meta: {
      index: 2
    },
    component: bindCardPage,
  },
  {
    path: '/bindUsdt',
    name: 'bindUsdt',
    meta: {
      index: 2
    },
    component: bindUsdtPage,
  },
  {
    path: '/setting',
    name: 'setting',
    meta: {
      index: 2
    },
    component: settingPage,
  },
  {
    path: '/lang',
    name: 'lang',
    meta: {
      index: 3
    },
    component: langPage,
  },
  {
    path: '/help',
    name: 'help',
    meta: {
      index: 3
    },
    component: helpPage,
  },
  {
    path: '/editPassword',
    name: 'editPassword',
    meta: {
      index: 3
    },
    component: editPasswordPage,
  },
  {
    path: '/editSafePassword',
    name: 'editSafePassword',
    meta: {
      index: 3
    },
    component: editSafePasswordPage,
  },


  {
    path: '/about',
    name: 'about',
    meta: {
      index: 5
    },
    component: aboutPage,
  },
  {
    path: '/platform',
    name: 'platform',
    meta: {
      index: 5
    },
    component: platformPage,
  },
  {
    path: '/company',
    name: 'company',
    meta: {
      index: 5
    },
    component: companyPage,
  },
  {
    path: '/role',
    name: 'role',
    meta: {
      index: 5
    },
    component: rolePage,
  },
  {
    path: '/shareRole',
    name: 'shareRole',
    meta: {
      index: 5
    },
    component: shareRolePage,
  },
  {
    path: '/kefu',
    name: 'kefu',
    meta: {
      index: 5
    },
    component: kefuPage,
  },
  {
    path: '/kf2',
    name: 'kf2',
    meta: {
      index: 5
    },
    component: kf2,
  },
  {
    path: '/classroom',
    name: 'classroom',
    meta: {
      index: 5
    },
    component: classroomPage,
  },
  {
    path: '/share',
    name: 'share',
    meta: {
      index: 4
    },
    component: sharePage,
  },
  {
    path: '/address',
    name: 'address',
    meta: {
      index: 4
    },
    component: addressPage,
  },
  {
    path: '/bindAddress',
    name: 'bindAddress',
    meta: {
      index: 5
    },
    component: bindAddressPage,
  },
  {
    path: '/debt',
    name: 'debt',
    meta: {
      index: 5
    },
    component: debtPage,
  },
  {
    path: '/download',
    name: 'download',
    meta: {
      index: 4
    },
    component: downloadPage,
  },
  {
    path: '/other',
    name: 'other',
    meta: {
      index: 4
    },
    component: otherPage,
  },
  {
    path: '*',
    redirect: '/index'
  }
]

const router = new VueRouter({
  routes
})

const whiteArr = ['register', 'login', 'lang', 'index', 'task', 'setting', 'about', 'help'] //白名单
const grayPageArr = ['orderDetail', 'billDetail', 'orderList', 'setting', 'sign', 'address'] //页面背景灰色
const loadingArr = [ // loading页面
  'index', 'bill', 'order', 'team', 'mine', 'finance', 'financeLog', 'integral', 'integralLog', 'raffle', 'raffleLog', 'sign', 'address', 'rechargeLog', 'share', 'withdrawLog', 'orderList', 'help', 'setting'
]
const statusBarPage = ['index']
router.beforeEach((to, from, next) => {
  // 背景色
  if (to.name === 'raffle') {
    document.body.style.background = '#B5201D'
  } else if ( to.name === 'download') {
    document.body.style.background = '#F83544'
  } else if (grayPageArr.indexOf(to.name) > -1) {
    document.body.style.background = '#f2f2f2'
  } else {
    document.body.style.background = '#ffffff'
  }

  let token = getToken()
  // loading
  if (loadingArr.indexOf(to.name) > -1) {
    store.state.showLoading = true
    // 超过10秒隐藏loading页面
    setTimeout(() => {
      store.state.showLoading = false
    }, 3000)
  }

  // 状态栏颜色
  if (statusBarPage.indexOf(to.name) > -1) {
    let metaList = document.querySelectorAll('meta')
    metaList.forEach(v => {
      if (v.name == 'theme-color') {
        v.content = '#F83544'
      }
    })
  }
  if (statusBarPage.indexOf(from.name) > -1) {
    let metaList = document.querySelectorAll('meta')
    metaList.forEach(v => {
      if (v.name == 'theme-color') {
        v.content = '#ffffff'
      }
    })
  }

  if (token) {
    next()
  } else {
    // 白名单
    if (whiteArr.indexOf(to.name) > -1) {
      next()
    } else {
      // next('login')
      next()
    }
  }
})

router.afterEach(() => {
  window.scrollTo({top: 0})
})

export default router
