<template>
  <div class="order-page home-container fixed">
		<div class="flex-center top fixed border">
			{{i18n.t('order.title')}}
		</div>

		<div class="order-info flex-center-start">
			<div class="logo">
				<img :src="shoppingUrl" alt="">
			</div>
			<div class="flex-2 flex-column-center">
				<div class="label">{{i18n.t('order.label1')}}</div>
				<div class="flex-center count">
					V{{vipLevelObj.Leave}}
					<img :src="levelObj[`level${vipLevelObj.Leave || 1}`]" alt="">
				</div>
			</div>
			<!-- <div class="flex-2 flex-column-center">
				<div class="label">{{i18n.t('order.label2')}} USDT</div>
				<div class="count">{{commissionData.Commission.toFixed(2)}}</div>
			</div> -->
			<div class="flex-2 flex-column-center">
				<div class="label">{{i18n.t('order.label3')}}</div>
				<div class="count">{{commissionData.FinishCount}}/{{vipLevelObj.Num}}</div>
			</div>
		</div>

		<div class="space-line"></div>

		<div class="modal-title">{{i18n.t('order.title2')}}</div>

		<div class="card-list ">
			<div class="flex-center-between">

				<div class="card">
					<div class="label" :class="i18n.locale == 'zh-CN' || i18n.locale == 'zh-TW' ? '' : 'small'">
							{{i18n.t('order.label4')}}
					</div>
					<div class="flex-center-between">
						<div class="icon">
							<img :src="cardUrl1" alt="">
						</div>
						<div class="count" v-if="$store.state.payType == 'THB'">
							<span class="big" :class="getClassName(userMoney)">{{userMoney}}</span>
							<span class="label small">{{ $store.state.payType }}</span>
						</div>
            <div class="count" v-else>
              <span class="label small mr-5">{{ $store.state.payType }}</span>
              <span class="big" :class="getClassName(userMoney)">{{userMoney}}</span>
            </div>
					</div>
				</div>
				<div class="card purple">
					<div class="label" :class="i18n.locale == 'zh-CN' || i18n.locale == 'zh-TW' ? '' : 'small'">
						{{i18n.t('order.label5')}}
					</div>
					<div class="flex-center-between">
						<div class="icon">
							<img :src="cardUrl2" alt="">
						</div>
						<div class="count" v-if="$store.state.payType == 'THB'">
							<span class="big" :class="getClassName(commissionData.TodayOrderMoney)">{{commissionData.TodayOrderMoney.toFixed(2)}}</span>
							<span class="label small">{{ $store.state.payType }}</span>
						</div>
            <div class="count" v-else>
              <span class="label small mr-5">{{ $store.state.payType }}</span>
              <span class="big" :class="getClassName(commissionData.TodayOrderMoney)">{{commissionData.TodayOrderMoney.toFixed(2)}}</span>
            </div>
					</div>
				</div>
			</div>

			<div class="flex-center-between">
				<div class="card blue">
					<div class="label" :class="i18n.locale == 'zh-CN' || i18n.locale == 'zh-TW' ? '' : 'small'">
							{{i18n.t('order.label6')}}
					</div>
					<div class="flex-center-between">
						<div class="icon">
							<img :src="cardUrl3" alt="">
						</div>
						<div class="count" v-if="$store.state.payType == 'THB'">
							<span class="big" :class="getClassName(commissionData.TodayCommission)">{{commissionData.TodayCommission.toFixed(2)}}</span>
							<span class="label small">{{ $store.state.payType }}</span>
						</div>
            <div class="count" v-else>
              <span class="label small mr-5">{{ $store.state.payType }}</span>
              <span class="big" :class="getClassName(commissionData.TodayCommission)">{{commissionData.TodayCommission.toFixed(2)}}</span>
            </div>
					</div>
				</div>
				<div class="card yellow">
					<div class="label" :class="i18n.locale == 'zh-CN' || i18n.locale == 'zh-TW' ? '' : 'small'">
						{{i18n.t('order.label7')}}
					</div>
					<div class="flex-center-between">
						<div class="icon">
							<img :src="cardUrl4" alt="">
						</div>
						<div class="count" v-if="$store.state.payType == 'THB'">
							<span class="big" :class="getClassName(freezeMoney)">{{freezeMoney}}</span>
							<span class="label small">{{ $store.state.payType }}</span>
						</div>
            <div class="count" v-else>
              <span class="label small mr-5">{{ $store.state.payType }}</span>
              <span class="big" :class="getClassName(freezeMoney)">{{freezeMoney}}</span>
            </div>
					</div>
				</div>
			</div>
		</div>

		<div class="order-btn">
			<Button @click="submit">{{i18n.t('order.btn')}}</Button>
		</div>

		<div class="modal-title clear">{{i18n.t('order.title3')}}</div>

		<div class="order-desc">{{i18n.t('order.desc1')}}</div>
		<div class="order-desc">{{i18n.t('order.desc2')}}</div>


  </div>
</template>
<script>
import { userInfo, commissionInfo } from '@/api/user'
import { createOrder  } from '@/api/task'
import {  List, Button, Popup } from 'vant';
import { getToken } from '@/utils/auth'
export default {
  components: {
    List,
		Button,
		Popup
  },
  data() {
    return {
      closeUrl: require('../../assets/imgs/icon-close.png'),
      rightUrl: require('../../assets/imgs/icon-arrow-right.png'),
      shoppingUrl: require('../../assets/imgs/shopping.png'),
      levelObj: {
				level1: require('../../assets/imgs/level.png'),
				level2: require('../../assets/imgs/level2.png'),
				level3: require('../../assets/imgs/level3.png'),
				level4: require('../../assets/imgs/level4.png'),
				level5: require('../../assets/imgs/level5.png'),
				level6: require('../../assets/imgs/level6.png'),
			},
      cardUrl1: require('../../assets/imgs/icon-money3.png'),
      cardUrl2: require('../../assets/imgs/icon-card.png'),
      cardUrl3: require('../../assets/imgs/icon-order.png'),
      cardUrl4: require('../../assets/imgs/icon-report.png'),
			userMoney: '0.00',
			freezeMoney: '0.00',
      isLogin: false,
			commissionData: {
				Commission: 0,
				FinishCount: 0,
				OrderCommission: 0,
				TodayOrderMoney: 0,
				TodayCommission: 0,
			},
			vipLevelObj: {
				Leave: 1,
				Num: 0
			},
			okNum: 0
    }
  },
  mounted() {
    if (getToken()) {
      this.isLogin = true
    } else {
      this.isLogin = false
    }

    if (this.isLogin) {
      this.init()
    }
  },
  methods: {
    init() {
			userInfo().then(res => {
				this.okNum += 1
				this.checkPage()
				this.userMoney = res.data.UserAccount ? res.data.UserAccount.Balance.toFixed(2) : '0.00'
				this.freezeMoney = res.data.UserAccount ? res.data.UserAccount.Freeze.toFixed(2) : '0.00'
				this.vipLevelObj = res.data.Leave
			})
			commissionInfo().then(res => {
				this.okNum += 1
				this.checkPage()
				this.commissionData = res.data
			})
		},
		checkPage() {
      if (this.okNum == 2) {
        this.$store.state.showLoading = false
      }
    },
		submit() {
			createOrder().then(res => {
				if (res.code == 0) {
					let data = res.data
					if (data.length > 0) {
						sessionStorage.setItem('orderDetail', JSON.stringify(data[0]))
						this.$router.push({
							path: '/orderDetail'
						})
					} else {
						this.$toast(this.i18n.t('order.error'))
					}
				} else {
					this.$toast(res.msg)
				}
			})

		},
		getClassName(val) {
			let str = val + ''
			if (str.length > 8 && str.length < 12) {
				return 'small'
			} else if (str.length >= 12) {
				return 'mini'
			} else {
				return ''
			}
		}
  }
}
</script>