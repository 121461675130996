
import Stomp from 'stompjs';
import store from '../store';
import moment from 'moment'
import { getToken } from './auth'

import { Locale } from 'vant';
import zhCN from 'vant/es/locale/lang/zh-CN';
import zhTW from 'vant/es/locale/lang/zh-TW';
import enUS from 'vant/es/locale/lang/en-US';
import esES from 'vant/es/locale/lang/es-ES';
import frFR from 'vant/es/locale/lang/fr-FR';
import jaJP from 'vant/es/locale/lang/ja-JP';
import thTH from 'vant/es/locale/lang/th-TH';
import deDE from 'vant/es/locale/lang/de-DE';


export function initWebSocket(id, websocketonmessage, reconnect) {
  let socketUrl = '';
  const env = process.env.NODE_ENV;
  if (env == 'development') {
    socketUrl = process.env.VUE_APP_WEBSOCKET_URL;
  } else {
    let host = window.location.host;
    let href = window.location.href;
    socketUrl = (href.indexOf('https:') == 0 ? 'wss://' : 'ws://') + host + '/api/user/ws';
  }

  let retryCount = 0; // 重试次数
  const maxRetryCount = 10; // 最大重试次数
  let retryInterval = 100; // 重试间隔时间（单位：毫秒）

  function increaseInterval() {
    retryCount++;
    retryInterval = Math.pow(2, retryCount) * 100; // 重试间隔时间指数递增
    retryInterval = Math.min(retryInterval, 60000); // 重试间隔时间最大不超过 1 分钟
  }

  return new Promise((resolve) => {
    function connect() {
      let websocket = new WebSocket(socketUrl);
      websocket.onopen = function(evt) {
        console.log('open');
        store.state.isClose = false
        store.state.isSend = false
        resolve(websocket);
        retryCount = 0; // 连接成功后重试次数清零
        retryInterval = 100; // 连接成功后重试间隔时间重置为 100 毫秒
      };
      websocket.onclose = function(evt) {
        console.log('close');
        store.state.isSend = false;
        if (reconnect && retryCount < maxRetryCount && !store.state.isClose) {
          setTimeout(() => {
            console.log(`Attempting onclose reconnect (attempt ${retryCount + 1} of ${maxRetryCount})...`);
            connect();
            increaseInterval(); // 重试间隔时间指数递增
          }, retryInterval);
        }
      };
      websocket.onmessage = function(evt) {
        websocketonmessage({
          data: evt.data,
        });
      };
      websocket.onerror = function(evt) {
        store.state.isSend = false;
        if (reconnect && retryCount < maxRetryCount) {
          setTimeout(() => {
            console.log(`now:${new Date().toLocaleTimeString()} Attempting onerror reconnect (attempt ${retryCount + 1} of ${maxRetryCount})...`);
            connect();
            increaseInterval(); // 重试间隔时间指数递增
          }, retryInterval);
        }
      };
    }
    connect();
  });
}

// 数字格式化
export function formatNumber(num) {
  return parseFloat(num).toFixed(4)
  if (num.toString().indexOf('.') !== -1) {
    const arr = num.toString().split('.');
    if (/^0+$/.test(arr[1])) {
      return arr[0] + '.00';
    } else if (arr[1].length === 1) {
      return arr[0] + '.' + arr[1] + '0';
    } else if (arr[1].length === 3) {
      return arr[0] + '.' + arr[1] + '00';
    } else if (arr[1].length > 4) {
      return arr[0] + '.' + arr[1].slice(0, 4);
    } else {
      return arr[0] + '.' + arr[1].padEnd(4, '0');
    }
  } else {
    return num + '.00';
  }
}

// 默认语言
export function getDefaultLanguage () {
  const defaultLanguage = store.state.language;
  const language = navigator.language
  const clientLanguage = (language != 'zh-CN' && language != 'zh-TW') ? language.substring(0, 2) : language
  const messages = [
    'zh-CN',
    'zh-TW',
    'ar',
    'en',
    'es',
    'fr',
    'id',
    'ja',
    'ko',
    'pt',
    'ru',
    'th',
    'he',
    'tr',
    'hi',
    'vi',
    'de',
    'it',
    'kk',
    'ms'
  ]
  const local = messages.indexOf(clientLanguage) > -1 ? clientLanguage : defaultLanguage
  return local
}

export  function formatTime(inputTime) {
  const dateObject = new Date(inputTime);

  const year = dateObject.getFullYear();
  const month = String(dateObject.getMonth() + 1).padStart(2, '0');
  const day = String(dateObject.getDate()).padStart(2, '0');
  const hours = String(dateObject.getHours()).padStart(2, '0');
  const minutes = String(dateObject.getMinutes()).padStart(2, '0');
  const seconds = String(dateObject.getSeconds()).padStart(2, '0');

  const newTimeFormat = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;

  return newTimeFormat;
}

export function resetTime(time) {
  if (time) {
    return moment(time).format('YYYY/MM/DD HH:mm:ss')
  } else {
    return ''
  }
}