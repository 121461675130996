<template>
  <div class="finance-page">
    <top-bar :title="i18n.t(`finance.title`)" class="border" @back="back"></top-bar>
    <div class="top-img">
      <img :src="bannerUrl" alt="">
      <div class="text1">{{i18n.t('content.label2')}}</div>
      <div class="text2 flex-center-between" v-show="false">
        <span>{{i18n.t('content.label3')}}</span>
        <span>{{i18n.t('content.label4')}}</span>
        <span>{{i18n.t('content.label5')}}</span>
      </div>
      <div class="text3" :class="i18n.locale == 'zh-CN' || i18n.locale == 'zh-TW' ? '' : 'clear'">{{i18n.t('content.label6')}}</div>
    </div>

    <div class="space"></div>

    <div class="form-box">
      <div class="form">
        <div class="flex-center-between label">
          <div class="flex-1">{{i18n.t(`finance.label1`)}}</div>
          <div class="text-right flex-1">{{i18n.t(`finance.label2`)}}</div>
        </div>
        <div class="flex-center-between">
          <div class="count" v-if="$store.state.payType == 'THB'">
            {{userMoney}}
            <span class="small">{{ $store.state.payType }}</span>
          </div>
          <div class="count" v-else>
            <span class="small">{{ $store.state.payType }}</span>
            {{userMoney}}
          </div>
          <div class="count" v-if="$store.state.payType == 'THB'">
            {{financeMoney}}
            <span class="small">{{ $store.state.payType }}</span>
          </div>
          <div class="count" v-else>
            <span class="small">{{ $store.state.payType }}</span>
            {{financeMoney}}
          </div>
        </div>

        <div class="label mt-10">{{i18n.t(`finance.label3`)}}</div>

        <div class="input flex-center" v-if="$store.state.payType == 'THB'">
          <input type="number"  inputmode="decimal" v-model="form.money">
          <div class="adot">{{ $store.state.payType }}</div>
        </div>
        <div class="input flex-center" v-else >
<!--          <div class="adot" style="margin-left: -0.23333rem">{{ $store.state.payType }}</div>-->
          <input type="number"  inputmode="decimal" v-model="form.money" >
        </div>

        <div class="flex-center-between label">
          <div>{{i18n.t(`finance.label4`)}}</div>
          <div>{{i18n.t(`finance.label5`)}}</div>
        </div>
        <div class="flex-center-between">
          <div class="count" v-if="$store.state.payType == 'THB'">
            {{totalCommission}}
            <span class="small">{{ $store.state.payType }}</span>
          </div>
          <div class="count" v-else>
            <span class="small">{{ $store.state.payType }}</span>
            {{totalCommission}}
          </div>
          <div class="count" :class="todayCommission > 0 ? 'red' : 'green'" v-if="$store.state.payType == 'THB'">
            {{todayCommission > 0 ? '+' : ''}}{{todayCommission}}
            <span class="small">{{ $store.state.payType }}</span>
          </div>
          <div class="count" :class="todayCommission > 0 ? 'red' : 'green'" v-else>
            <span class="small">{{ $store.state.payType }}</span>
            {{todayCommission > 0 ? '+' : ''}}{{todayCommission}}
          </div>
        </div>
      </div>
      <div class="flex-center-between btn-box">
        <Button class="red-btn" @click="submit(1)">{{i18n.t(`finance.btn1`)}}</Button>
        <Button class="gray-btn" @click="submit(2)">{{i18n.t(`finance.btn2`)}}</Button>
      </div>
    </div>

    <div class="space"></div>

    <div class="finance-list">
      <div class="title">{{i18n.t(`finance.label6`)}}</div>

      <div class="list flex" :class="item.ID == financeId ? 'active' : ''" v-for="(item, i) in financeList" :key="i" @click="chooseFinance(item)">
        <div class="left">
          <div class="flex-end-start mb-5">
            <div class="count">{{item.DayRate}}</div>
            <div class="small">%</div>
          </div>
          <div class="label">{{item.Deadline}}{{i18n.t(`finance.label7`)}}</div>
        </div>
        <div class="right flex-1">
          <div class="name">{{i18n.t(`finance.label12`)}}</div>

          <div class="flex-center-between ">
            <div class="label flex-1">
              <div>{{i18n.t(`finance.label9`)}}：{{item.moneySum}} {{ $store.state.payType }}</div>
            </div>
            <div class="tag flex-center">
              {{i18n.t(`finance.label10`)}}：{{item.Deadline}}{{i18n.t(`finance.label8`)}}
            </div>
          </div>
        </div>
      </div>

      <div class="title top">{{i18n.t(`finance.label11`)}}</div>

      <div class="desc">{{i18n.t(`finance.desc`)}}</div>
    </div>
  </div>
</template>
<script>
import topBar from '@/components/topBar'
import { Button } from 'vant'
import { financialList, financialBuy, financialOut, financialLog } from '@/api/index'
import { userInfo } from '@/api/user'
export default {
  components: {
    topBar,
    Button
  },
  data() {
    return {
      bannerUrl: require('../../assets/imgs/finance-bg2.png'),
      form: {
        money: ''
      },
      userMoney: '0.00',
      financeMoney: '0.00',
      totalCommission: '0.00',
      todayCommission: '0.00',
      financeListBak: [],
      financeList: [ ],
      financeId: '',
      isListOk: false,
      isLogOk: false,
      logList: [],
      okNum: 0
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    checkPage() {
      if (this.okNum == 3) {
        this.$store.state.showLoading = false
      }
    },
    init() {
      financialList().then(res => {
        this.financeListBak = res.data.Items.sort((a, b) => {
          return b.Deadline - a.Deadline
        })
        this.isListOk = true
        this.resetList()
        this.okNum += 1
				this.checkPage()
      })
      userInfo().then(res => {
        this.userMoney = res.data.UserAccount ? res.data.UserAccount.Balance.toFixed(2) : '0.00'
        this.okNum += 1
				this.checkPage()
      })

      let form = new FormData()
      form.append('status', 2)
      form.append('page', 1)
      form.append('limit', 100)
      financialLog(form).then(res => {
        this.logList = res.data.Items
        if(res.data.Items.length > 0) {
          this.financeMoney = res.data.Items[0].InvestmentAmount.toFixed(2)
        }
        this.isLogOk = true
        this.resetList()
        this.okNum += 1
				this.checkPage()
      })
    },
    resetList() {
      if (this.isListOk && this.isLogOk) {
        this.financeListBak.forEach(v => {
          let sum = 0
          let list = this.logList.filter(val => {
            return v.ID == val.FinancialSettingID && val.Status == 2
          })
          list.forEach(value => {
            sum += value.InvestmentAmount
          })
          v.moneySum = sum
        })
        this.financeList = [].concat(this.financeListBak)
      }
    },
    back() {
      this.$router.go(-1)
    },
    chooseFinance(data) {
      this.financeId = data.ID
    },
    submit(type) {
      if (!this.form.money) {
        this.$toast(this.i18n.t(`finance.placeholder${type}`))
        return
      }
      if (!this.financeId) {
        this.$toast(this.i18n.t(`finance.placeholder3`))
        return
      }
      let form = new FormData()
      form.append('FinancialSettingID', this.financeId)
      form.append('InvestmentAmount', this.form.money)
      if (type == 1) {
        financialBuy(form).then(res => {
          if (res.code == 0) {
            this.$toast(this.i18n.t('finance.success1'))
            this.financeId = ''
            this.form.money = ''
            this.init()
          } else {
            this.$toast(res.msg)
          }
        })
      } else {
        financialOut(form).then(res => {
           if (res.code == 0) {
            this.$toast(this.i18n.t('finance.success2'))
            this.financeId = ''
            this.form.money = ''
            this.init()
          } else {
            this.$toast(res.msg)
          }
        })
      }
    }
  }
}
</script>