<template>
  <div class="login-page">
    <topBar :title="i18n.t('resetPassword.title')" @back="back" class="border" rightIcon="kefu" @detail="toPath('kefu')"></topBar>

    <div class="logo" v-show="step == 1">
      <img :src="logoUrl" alt="">
    </div>

    <div class="login-tab flex-start" v-show="step == 1">
      <div class="tab" :class="tabIndex == 1 ? 'active' : ''" @click="changeTab(1)">{{i18n.t('login.tab1')}}</div>
<!--      <div class="tab" :class="tabIndex == 2 ? 'active' : ''" @click="changeTab(2)">{{i18n.t('login.tab2')}}</div>-->
    </div>

    <div class="login-form">
      <div class="desc"  v-show="step == 2">
        {{i18n.t('register.label1')}}：{{form.phone}} {{i18n.t('register.label2')}}
      </div>
      <div class="desc"  v-show="step == 3">
        {{i18n.t('register.label3')}} ID：{{form.phone}} {{i18n.t('register.label4')}}
      </div>

      <div class="form-item  flex-center" v-if="step == 1 && tabIndex == 1">
        <div class="pre flex-center" @click="changePre">
          {{phonePre}}
          <img :src="downUrl" alt="">
        </div>
        <div class="flex-1">
          <div class="input clear"> 
            <input type="tel" v-model="form.phone" :placeholder="i18n.t('register.labelPlaceholder8')">
          </div>
        </div>
      </div>

      <div class="form-item  flex-center" v-show="step == 1 && tabIndex == 2">
        <div class="flex-1">
          <div class="input "> 
            <input type="text" v-model="form.email" :placeholder="i18n.t('register.labelPlaceholder1')">
          </div>
        </div>
      </div>

      <div class="form-item hide-input" v-show="step == 2">
        <div class="input flex-center">
          <input ref="codeInput" type="number" v-model="form.code" @input="checkCodeSize"  :placeholder="i18n.t('register.labelPlaceholder7')"/>
        </div>
				<div class="number-box flex-center-between">
					<div class="box flex-center" :class="getCodeActive(i)" v-for="i in 6" :key="i" @click="inputCode">{{getCodeStr(i)}}</div>
				</div>
      </div>

      <div class="desc clear"  v-show="step == 2">
        <span v-if="!isDisabled" @click="resend">{{i18n.t('register.resend')}}</span>
        <span v-if="isDisabled">{{timeCount}}</span>
      </div>

      <div class="form-item flex-center" v-show="step == 3" >
        <div class="input"> 
          <input type="password" @input="changePassword" v-model="form.password" :placeholder="i18n.t('register.labelPlaceholder3')">
        </div>
      </div>
     

    


      <Button class="login-btn" @click="submit">{{i18n.t(`resetPassword.btn${step}`)}}</Button>
    </div>

  

    <Popup v-model:show="showPopup" position="bottom">
			<div class="country-list" >
				<div class="title flex-center-start">
          <Icon name="arrow-left" size="18" @click="cancel" ></Icon>
          <span>{{i18n.t('register.labelPlaceholder6')}}</span>
        </div>
        <div class="search-list flex-center-between">
          <div class="flex-center">
            <img :src="searchUrl" alt="">
            <input type="text" v-model="keyword" :placeholder="i18n.t('register.labelPlaceholder7')" @input="searchCountry" @change="searchCountry">
          </div>
          <div @click="cancel">{{i18n.t('register.cancel')}}</div>
        </div>
        <div class="group-list login" v-for="(item, i) in countryList" :key="i">
          <div class="list flex-center-between" :class="child.key == phonePre ? 'active' : ''" v-for="(child, index) in item.children" :key="index" @click.stop="chooseCountry(child)">
            <div class="img-box flex-center">
              <img :src="child.icon" alt="">
              <span>{{getCountryName(child.title)}}</span>
            </div>
            <div>
              {{child.key}}
            </div>
          </div>
        </div>
			</div>
    </Popup>
  </div>
</template>
<script>
  import { Button, Popup, Divider } from 'vant'
  import { resetPassword, sendSms, checkPhoneCode, userArticle, sendEmail, checkEmailCode } from '@/api/login'
  import { setToken, setUser, setUserId } from '@/utils/auth'
  import topBar from '@/components/topBar'
  export default {
    components: {
      Button,
      Popup,
      Divider,
      topBar
    },
    data() {
      return {
        step: 1,
        form: {
          email: '',
          phone: '',
          password: '',
          safePassword: '',
          safePassword2: '',
          code: '',
          inviteCode: ''
        },
        searchUrl: require('../../assets/imgs/icon-search.png'),
        downUrl: require('../../assets/imgs/icon-arrow-down.png'),
        logoUrl: require('../../assets/imgs/logo3.png'),
        passwordType: '',
        keyboardValue: '',
        showPassword: false,
        showSafePassword: false,
        showSafePassword2: false,
        activeIndex: 1,
        isDisabled: false,
        sendTime: '',
        showInvite: true,
        showFlag: false,
        passwordAuthList: [],
        phonePre: '+66',
        tabIndex: 1,
        showPopup: false,
        countryList: [],
        keyword: '',
        serviceData: {},
        privacyData: {},
      }
    },
    computed: {
      timeCount() {
        return this.sendTime + 'S'
      },
      countryListBak() {
        return this.$store.state.countryList
      }
    },
    mounted() {
      let code = this.$route.query.code
      if (code) {
        this.form.inviteCode = code
      }
      this.countryList = [].concat(this.countryListBak)
    },
    methods: {
      toPath(path) {
        if (path) {
          this.$router.push({
            name: path
          })
        }
      },
      changeTab(index) {
        if (this.step == 1) {
          this.tabIndex = index
        } 
      },
      back() {
        this.$router.go(-1)
      },
      toLogin() {
        this.$router.push({
          name: 'login'
        })
      },
      toCheckCode() {
        if (!this.form.code || (this.form.code + '').length != 6) {
          this.$toast(this.i18n.t('resetPassword.labelPlaceholder4'))
          return
        }
        let form = new FormData()
        form.append('Code', this.form.code)
        form.append('Type', 2)
        if (this.tabIndex == 1) {
          form.append('Phone', this.phonePre + this.form.phone)
          checkPhoneCode(form).then(res => {
            if (res.code == 0) {
              this.step = 3
            } else {
              this.$toast(res.msg)
            }
          })
        } else {
          form.append('Email', this.form.email)
          checkEmailCode(form).then(res => {
            if (res.code == 0) {
              this.step = 3
            } else {
              this.$toast(res.msg)
            }
          })
        }
      },
      submit() {
        if (this.step == 1) {
          if (!this.form.phone && this.tabIndex == 1) {
            this.$toast(this.i18n.t('register.labelPlaceholder8'))
            return
          }
          if (!this.form.email && this.tabIndex == 2) {
            this.$toast(this.i18n.t('register.labelPlaceholder1'))
            return
          }
          if (this.tabIndex == 1) {
            this.sendPhoneSms()
          } else {
            this.sendEmailSms()
          }
        } else if (this.step == 2) {
          this.toCheckCode()
        } else {
          if (!this.form.password) {
            this.$toast(this.i18n.t('resetPassword.labelPlaceholder2'))
            return
          }

          let form = new FormData()
          if (this.tabIndex == 1) {
            form.append('Phone', this.phonePre + this.form.phone)
          } else {
            form.append('Email', this.form.email)
          }
          form.append('Code', this.form.code)
          form.append('Password', this.form.password)
          resetPassword(form).then(res => {
            if (res.code == 0) {
              this.$toast(this.i18n.t('resetPassword.success'))
              this.$router.push({
                name: 'login'
              })
            } else {
              this.$toast(res.msg)
            }
          })
        }
      },
      sendEmailSms(){
        let form = new FormData()
        form.append('Email', this.form.email)
        form.append('Type', 2)
        sendEmail(form).then(res => {
          if (res.code == 0) {
            this.countDown(60)
            this.step = 2
            this.$nextTick(() => {
              this.inputCode()
            }, 10)
          } else {
            this.$toast(res.msg)
          }
        })
      },
      sendPhoneSms(){
        let form = new FormData()
        form.append('Phone', this.phonePre + this.form.phone)
        form.append('Type', 2)
        sendSms(form).then(res => {
          if (res.code == 0) {
            this.countDown(60)
            this.step = 2
            this.$nextTick(() => {
						this.inputCode()
					}, 10)
          } else {
            this.$toast(res.msg)
          }
        })
      },
      countDown(time) {
        if (time > 0) {
          this.isDisabled = true
          time -= 1
          this.sendTime = time
          setTimeout(() => {
            this.countDown(time)
          }, 1000)
        } else {
          this.sendTime = ''
          this.isDisabled = false
        }
      },
      cancel() {
        this.showPopup = false
      },
      checkCodeSize() {
        let code = this.form.code + ''
        if (code.length > 6) {
          code = code.substring(0, 6)
          this.form.code = parseInt(code)
        }
      },
      getCodeActive(i) {
        let code = this.form.code + ''
        if (!code) {
          return i == this.activeIndex ? 'active' : ''
        }
        return i == code.length + 1 ? 'active' : ''
      },
      inputCode() {
        this.activeIndex = 1
        this.$refs.codeInput.focus()
      },
      getCodeStr(i) {
        let code = this.form.code + ''
        if (!code) {
          return ''
        }
        let str = code.substring(i - 1, i)
        return str
      },
      resend() {
        if (this.isDisabled) {
          return
        } else {
          if (this.tabIndex == 1) {
            this.sendPhoneSms()
          } else {
            this.sendEmailSms()
          }
        }
      },
      changeShowPassword() {
        this.showPassword = !this.showPassword
      },
      changeShowSafePassword() {
        this.showSafePassword = !this.showSafePassword
      },
      changeShowSafePassword2() {
        this.showSafePassword2 = !this.showSafePassword2
      },
      changeShowInvite() {
        this.showInvite = !this.showInvite
      },
      changePassword() {
        if (this.step == 1) {
          return
        }
        this.showPasswordAuth = true
        let password = this.form.password
        let result = []
        if (password.length >= 8 && password.length <= 30) {
          result.push(0)
        }
        if (/[a-z]/g.test(password)) {
          result.push(1)
        }
        if (/[A-Z]/g.test(password)) {
          result.push(2)
        }
        if (/[0-9]/g.test(password)) {
          result.push(3)
        }

        this.passwordAuthList = result
      },
      changePre() {
        this.showPopup = true
      },
      chooseCountry(data) {
        this.phonePre = data.key
        this.showPopup = false
      },
      getCountryName(name) {
        if (!name) {
          return ''
        }
        let newName = ''
        newName = name.substring(0, name.indexOf('(') - 1)
        return newName
      },
      searchCountry() {
        if (this.keyword) {
          this.countryList = this.countryListBak.map(v => {
            let children = v.children.filter(val => {
              return val.title.toLowerCase().indexOf(this.keyword.toLowerCase()) > -1
            })
            if (children.length > 0) {
              return {
                children: children
              }
            } else {
              return {
                children: []
              }
            }
          })
        } else {
          this.countryList = [].concat(this.countryListBak)
        }
      },
    },
  }
</script>