<template>
  <div class="download-page ">
    <top-bar :title="i18n.t(`download.title`)"  :hideBack="true" @back="back"></top-bar>

    <div class="bg-outer">
    </div>
    <div class="bg"></div>

    <div class="info flex-column-center">
      <img :src="phoneUrl" alt="">
      <img :src="logoUrl" class="small" alt="">

      <div class="desc">{{i18n.t(`download.label1`)}}</div>
      <div class="desc">{{i18n.t(`download.label2`)}}</div>
      <div class="desc">{{i18n.t(`download.label3`)}}</div>

      <div class="btn-box flex-center">
        <Button class="btn" @click="download('ios')">
          <div class="flex-center">
            <img :src="iosUrl" alt="">
            <span>App Store</span>
          </div>
        </Button>
        <Button class="btn" @click="download('android')">
          <div class="flex-center">
            <img :src="androidUrl" alt="">
            <span>Android</span>
          </div>
        </Button>
      </div>
    </div>
  </div>
</template>
<script>
import { Button } from 'vant'
import {configList} from "@/api/user";
import topBar from '@/components/topBar'
export default {
  components: {
    topBar,
    Button
  },
  data() {
    return {
      noticeList: [],
      roleInfo: {},
      type: '',
      phoneUrl: require('../../assets/imgs/phone.png'),
      logoUrl: require('../../assets/imgs/ShopMall.png'),
      androidUrl: require('../../assets/imgs/icon-android.png'),
      iosUrl: require('../../assets/imgs/icon-ios.png'),
      iosDownloadUrl: '',
      androidDownloadUrl: ''
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      configList().then(res => {
        let data = res.data
       this.iosDownloadUrl = data.list.filter(v => {
          return v.name == 'download_ios'
        })[0].value
       this.androidDownloadUrl = data.list.filter(v => {
          return v.name == 'download_android'
        })[0].value
      })
    },
    back() {
      this.$router.go(-1)
    },
    download(type) {
      let url = type == 'ios' ? this.iosDownloadUrl : this.androidDownloadUrl
      let a = document.createElement('a')
      a.href = '/upload/' + url+"?v="+new Date().getTime()
      a.target = '_blank'
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    },
  }
}
</script>