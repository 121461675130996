<template>
	<div class="withdraw-page page-top">
		<top-bar :title="i18n.t('withdraw.title')" class="border"  @back="back" ></top-bar>



    <div class="bank-info type-bit" >
      <div class="flex-center-start title">
        <img :src="bitUrl4" alt="">
        <span class="">{{bankInfo.protocol}}</span>
      </div>
      <div class="card top address" v-if="bankInfo.address">{{resetAddress(bankInfo.address)}}</div>
      <div class="card top address error" :class="['vi', 'id', 'ru', 'ja', 'hi', 'ms'].indexOf(i18n.locale) > -1 ? 'small' : ''" v-else @click="toPath('bindUsdt', { type: bankInfo.protocol})">{{i18n.t('bindCard.desc1')}}</div>
      <div class="card  right money" >${{userMoney}}</div>
    </div>

    <div class="default-form">
      <div class="form-label">{{i18n.t('withdraw.label11')}}</div>
      <div class="form-item">
        <div class="prefit">
          <img :src="bitUrl" alt="">
        </div>
        <input type="text" :class="bitUrl ? 'pre' : ''" :placeholder="i18n.t('bindCard.placeholder5')" v-model="protocolName" readonly @click="changeType"/>
        <div class="adot small" >
          <img :src="downUrl" alt="" >
        </div>
      </div>

      <div class="form-label">{{i18n.t('withdraw.label1')}}</div>
      <div class="form-item">
        <input type="number" inputmode="inputmode" :placeholder="i18n.t('withdraw.placeholder1')" @change="changeMoney" v-model="form.money"/>
      </div>
      <div class="form-desc">
          {{i18n.t('withdraw.Prompt1')}}：<br />
          {{i18n.t('withdraw.Prompt2')}}：
      </div>

      <div class="form-label">{{i18n.t('withdraw.label3')}}</div>
      <div class="form-item">
        <input type="password" inputmode="decimal" :placeholder="i18n.t('withdraw.placeholder2')" v-model="form.password"/>
      </div>

      <Button class="btn" @click="submit">{{i18n.t('withdraw.btn')}}</Button>
    </div>

    <Popup v-model="showPopup" class="msg-popup" position="bottom">
      <div class="picker-box">
        <div class="close flex-center-between" @click="cancel">
          <div>{{i18n.t('bindCard.label5')}}</div>
          <img :src="closeUrl" alt="">
        </div>
        <div class="list">
          <div class="bit flex-center-between"  v-for="(item, i) in columns" :key="i" @click="chooseType(item)">
            <div class="flex-center-start">
              <img :src="item.icon" alt="">
              <span class="name">{{item.text}}</span>
            </div>
            <div class="icon">
              <Icon name="success" size="20" color="#000" v-if="item.protocol == protocol"/>
            </div>
          </div>
        </div>
      </div>
    </Popup>
	</div>
</template>
<script>
import topBar from '@/components/topBar'
import { Popup, Button, Tabs, Tab, Icon } from 'vant'
import { getBankList, userInfo, doWithdraw, getUsdtList  } from '@/api/user'
export default {
	components: {
    Popup,
    Button,
		topBar,
		Tabs,
		Tab,
    Icon
  },
  data() {
		return {
      bankUrl: require('../../assets/imgs/bank.png'),
      bitUrl4: require('../../assets/imgs/bit4.png'),
      downUrl: require('../../assets/imgs/icon-arrow-down.png'),
      closeUrl: require('../../assets/imgs/icon-close.png'),
      form: {
        money: '',
        id: '',
        password: ''
      },
      bitUrl: '',
      protocol: '',
      protocolName: '',
      showPopup: false,
      userMoney: '0.00',
      bankInfo: {},
      okNum: 0,
      columns: [
        {
          protocol: 'USDT-TRC20',
          text: 'USDT-TRC20',
          address: '',
          icon: require('../../assets/imgs/bit1.png'),
        },
        {
          protocol: 'USDT-ERC20',
          text: 'USDT-ERC20',
          address: '',
          icon: require('../../assets/imgs/bit1.png'),
        },
        {
          protocol: 'USDC-ERC20',
          text: 'USDC-ERC20',
          address: '',
          icon: require('../../assets/imgs/bit3.png'),
        },
        {
          protocol: 'ETH-ERC20',
          text: 'ETH-ERC20',
          address: '',
          icon: require('../../assets/imgs/bit2.png'),
        },
      ]
		}
	},
  mounted() {
    this.bankInfo = this.columns[0]
    this.bitUrl = this.bankInfo.icon
    this.protocol = this.bankInfo.protocol
    this.protocolName = this.bankInfo.text
    this.init()
    this.initBank()
  },
	methods: {
    checkPage() {
      if (this.okNum == 2) {
        this.$store.state.showLoading = false
      }
    },
    initBank() {
      getUsdtList().then(res => {
        this.okNum += 1
        this.checkPage()
        let list = res.data.list
        this.columns.forEach(v => {
          let index = list.findIndex(val => {
            return v.protocol == val.protocol
          })
          if (index > -1) {
            v.address = list[index].address
            v.id = list[index].id
          }
        })
        this.bankInfo = this.columns[0]
      })
    },
		init() {

      userInfo().then(res => {
        this.userMoney = res.data.UserAccount ? res.data.UserAccount.Withdraw.toFixed(2) : '0.00'
        this.okNum += 1
				this.checkPage()
      })
		},

		back() {
			this.$router.go(-1)
		},

    toPath(path, query) {
      if (path) {
        this.$router.push({
          name: path,
          query: query || {}
        })
      }
    },
    resetCard(card) {
      let newCard = ''
      try {
        if(card.length < 4) {
          return card + "******"
        }
        if (card) {
          newCard = card.substring(0, 4) + new Array(card.length - 6).join('*') + card.substring(card.length - 3)
        }
        return newCard
      } catch (e) {
        return card + "******"
      }
    },
    submit() {
      if (!this.form.money) {
        this.$toast(this.i18n.t('withdraw.placeholder1'))
        return
      }
      if (!this.form.password) {
        this.$toast(this.i18n.t('withdraw.placeholder2'))
        return
      }
      let form = new FormData()
      console.log("this.bankInfo", this.bankInfo)
      form.append('Price', this.form.money)
      form.append('Passwd', this.form.password)
      form.append('Type', this.bankInfo.protocol)
      form.append('Id', this.form.id )
      doWithdraw(form).then(res => {
        if (res.code == 0) {
          this.$toast(this.i18n.t('withdraw.success'))
          this.form.money = ''
          this.form.password = ''
          this.init()
        } else {
          this.$toast(res.msg)
        }
      })
    },
    changeMoney() {
      // if (this.form.money > parseFloat(this.userMoney)) {
      //   this.form.money = parseFloat(this.userMoney)
      // }
    },
    resetAddress(address) {
      if (address) {
        return address.substring(0, 8) + new Array(address.length - 15).join('*') + address.substring(address.length - 8)
      } else {
        return ''
      }
    },
    chooseType(data) {
      this.protocol = data.protocol
      this.protocolName = data.text
      this.bitUrl = data.icon
      this.bankInfo = data
      this.showPopup = false
    },
    changeType() {
      this.showPopup = true
    },
    cancel() {
      this.showPopup = false
    },
	}
}
</script>