<template>
  <div class="tab-bar" >
    <div class="tab" :class="{active: tabIndex == i}" v-for="(tab, i) in tabArr" :key="i" @click="changeTab(tab, i)">
      <div class="icon">
        <img :src="homeIcon" alt="" v-if="tabIndex !== 0 && i == 0">
        <img :src="homeActiveIcon" alt="" v-if="i == 0 && tabIndex == 0">
        
        <img :src="billIcon" alt="" v-if="tabIndex !== 1 && i == 1">
        <img :src="billActiveIcon" alt="" v-if="i == 1 && tabIndex == 1">

        <img :src="orderIcon" alt="" v-if="tabIndex !== 2 && i == 2">
        <img :src="orderActiveIcon" alt="" v-if="i == 2 && tabIndex == 2">

        <img :src="teamIcon" alt="" v-if="tabIndex !== 3 && i == 3">
        <img :src="teamActiveIcon" alt="" v-if="i == 3 && tabIndex == 3">

        <img :src="mineIcon" alt="" v-if="tabIndex !== 4 && i == 4">
        <img :src="mineActiveIcon" alt="" v-if="i == 4 && tabIndex == 4">
      </div>
      <div class="title" :class="i18n.locale == 'zh-CN' || i18n.locale == 'zh-TW' ? '' : 'small'">{{i18n.t(`common.${tab.title}`)}}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'tabbar',
  props:{

  },
  data() {
    return {
      homeIcon: require('../../assets/imgs/tab/index.png'),
      homeActiveIcon: require('../../assets/imgs/tab/index_select.png'),
      billIcon: require('../../assets/imgs/tab/bill.png'),
      billActiveIcon: require('../../assets/imgs/tab/bill_select.png'),
      orderIcon: require('../../assets/imgs/tab/order.png'),
      orderActiveIcon: require('../../assets/imgs/tab/order_select.png'),
      teamIcon: require('../../assets/imgs/tab/team.png'),
      teamActiveIcon: require('../../assets/imgs/tab/team_select.png'),
      mineIcon: require('../../assets/imgs/tab/mine.png'),
      mineActiveIcon: require('../../assets/imgs/tab/mine_select.png'),
      tabIndex: 0,
      defaultIcon: '',
      tabRoute: ['index', 'bill', 'order', 'team', 'mine'],
      tabArr: [
        {title: 'tab1', icon: 'home', path: '/index'},
        {title: 'tab2', icon: 'bill', path: '/bill'},
        {title: 'tab3', icon: 'order', path: '/order'},
        {title: 'tab4', icon: 'team', path: '/team'},
        {title: 'tab5', icon: 'mine', path: '/mine'},
      ]
    }
  },
  watch: {
    $route(val) {
      let name = val.name
      if (this.tabRoute.indexOf(name) > -1) {
        this.init()
      } else {
        this.tabIndex = -1
      }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      let name = this.$route.name
      switch(name) {
        case 'index': this.tabIndex = 0; break;
        case 'bill': this.tabIndex = 1; break;
        case 'order': this.tabIndex = 2; break;
        case 'team': this.tabIndex = 3; break;
        case 'mine': this.tabIndex = 4; break;
        default: this.tabIndex = -1; break;
      }
    },
    changeTab(tab, i) {
      this.tabIndex = i
      this.$router.push({
        path: tab.path
      })
    }
  }
}
</script>