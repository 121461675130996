<template>
	<div class="home-container fixed">
    <div class="flex-center-between top red fixed">
      <div class="left flex-center-start">
        <Icon name="friends-o" color="#fff" size="24" @click="toPath('setting')"/>
      </div>
      <div>{{i18n.t('index.label1')}}</div>
      <div class="right"></div>
    </div>

    <div class="banner">
      <Swipe
        :autoplay="3000"
        :show-indicators="false"
      >
        <SwipeItem  v-for="(item, i) in bannerList" :key="i" >
          <div class="flex-center-between banner-inner" :class="`type${item.Position}`">
            <img :src="`${item.Img}`" alt="">
            <div class="text1 content" :class="i18n.locale == 'zh-CN' || i18n.locale == 'zh-TW' ? 'big' : ''">{{item.Content}}</div>
            <div class="text2 content" :class="i18n.locale == 'zh-CN' || i18n.locale == 'zh-TW' ? 'big' : ''">{{item.Content1}}</div>
            <div class="text3 content">{{item.Content2}}</div>
          </div>
        </SwipeItem>
      </Swipe>
    </div>

    <div class="notice-line ">
      <NoticeBar :text="noticeMsg"  color="#454545" :left-icon="noticeUrl"></NoticeBar>
    </div>

    <div class="tab-box flex-center-between">
      <div class="tab flex-column-center" @click="toRecharge" >
        <img :src="rechargeUrl" alt="">
        <span>{{i18n.t('index.label2')}}</span>
      </div>
      <div class="tab flex-column-center" @click="toWithdraw">
        <img :src="withdrawUrl" alt="">
        <span>{{i18n.t('index.label3')}}</span>
      </div>
    </div>

    <div class="menu-list flex-start-around">
      <div class="menu flex-column-center" v-for="(item, i) in menuList1" :key="i" @click="toPath(item.path)">
        <img :src="item.url" alt="">
        <span>{{item.name}}</span>
      </div>
    </div>
    <div class="menu-list flex-start-around">
      <div class="menu flex-column-center" v-for="(item, i) in menuList2" :key="i" @click="toPath(item.path)">
        <img :src="item.url" alt="">
        <span>{{item.name}}</span>
      </div>
    </div>

    <div class="space-line"></div>

    <div class="modal-title flex-center-start">
      <img :src="giftUrl" alt="">
      <span>{{i18n.t('index.label4')}}</span>
    </div>

    <div class="goods-list flex-wrap">
      <div class="goods flex-column-center" v-for="(item, i) in goodsList" :key="i">
        <div class="img-box flex-center">
          <img :src="`${item.Img}`" alt="">
        </div>
        <div class="title">{{item.Name}}</div>
        <div class="desc">{{item.ExchangeIntegral}}</div>
        <Button class="btn" @click="toExchange">{{i18n.t('index.label6')}}</Button>
      </div>
    </div>

    <div class="modal-title flex-center-start" >
      <img :src="warningUrl" alt="">
      <span>{{i18n.t('index.label5')}}</span>
    </div>

    <!-- <div class="about" @click="toPath('about')">
      <img :src="aboutUrl" alt="">
    </div> -->

    <div class="about-list flex-wrap">
      <div class="box" @click="toLink('https://www.amazon.com/')">
        <img :src="aboutUrl1" alt="">
        <div>{{$t('index.label7')}}</div>
      </div>
      <div class="box" @click="toLink('https://shopee.com/')">
        <img :src="aboutUrl2" alt="">
        <div>{{$t('index.label8')}}</div>
      </div>
      <div class="box" @click="toLink('https://www.lazada.com/')">
        <img :src="aboutUrl3" alt="">
        <div>{{$t('index.label10')}}</div>
      </div>
      <div class="box" @click="toLink('https://pgmall.my/')">
        <img :src="aboutUrl4" alt="">
        <div>{{$t('index.label9')}} </div>
      </div>
    </div>

    <Popup v-model="showMsg" class="msg-popup">
      <div class="msg-box">
        <div class="close" @click="cancel">
          <img :src="closeUrl" alt="">
        </div>
        <div class="content flex-column-center">
          <div class="msg">{{msg}}</div>
        </div>
      </div>
    </Popup>
	</div>
</template>
<script>
import { Button, Swipe, SwipeItem, Popup, Icon, NoticeBar  } from 'vant'
import { getBannerList, getNoticeList, giftList } from '@/api/index'
import { getBankList, getUsdtList } from '@/api/user'
import moment from 'moment'
import {getToken} from "@/utils/auth";
export default {
  components: {
    Button,
    Swipe,
    SwipeItem,
    Popup,
    Icon,
    NoticeBar
  },
  data() {
    return {
      isLogin: false,
      showMsg: false,
      avatar: '',
      closeUrl: require('../../assets/imgs/icon-close.png'),
      integralUrl: require('../../assets/imgs/icon-integral2.png'),
      userUrl: require('../../assets/imgs/icon-user.png'),
      noticeUrl: require('../../assets/imgs/icon-notice.png'),
      rechargeUrl: require('../../assets/imgs/icon-recharge.png'),
      withdrawUrl: require('../../assets/imgs/icon-withdraw.png'),
      giftUrl: require('../../assets/imgs/icon-gift.png'),
      warningUrl: require('../../assets/imgs/icon-warning.png'),
      aboutUrl: require('../../assets/imgs/about.png'),
      aboutUrl1: require('../../assets/imgs/about-1.png'),
      aboutUrl2: require('../../assets/imgs/about-2.png'),
      aboutUrl3: require('../../assets/imgs/about-3.png'),
      aboutUrl4: require('../../assets/imgs/about-4.png'),
      headerObj:{
        'header-1':  require('../../assets/imgs/header/header-1.png'),
        'header-2':  require('../../assets/imgs/header/header-2.png'),
        'header-3':  require('../../assets/imgs/header/header-3.png'),
        'header-4':  require('../../assets/imgs/header/header-4.png'),
        'header-5':  require('../../assets/imgs/header/header-5.png'),
        'header-6':  require('../../assets/imgs/header/header-6.png'),
        'header-7':  require('../../assets/imgs/header/header-7.png'),
        'header-8':  require('../../assets/imgs/header/header-8.png'),
        'header-9':  require('../../assets/imgs/header/header-9.png'),
        'header-10':  require('../../assets/imgs/header/header-10.png'),
        'header-11':  require('../../assets/imgs/header/header-11.png'),
        'header-12':  require('../../assets/imgs/header/header-12.png'),
        'header-13':  require('../../assets/imgs/header/header-13.png'),
        'header-14':  require('../../assets/imgs/header/header-14.png'),
      },
      bannerList: [],
      noticeList: [],
      noticeMsg: '',
      goodsList: [  ],
      isBind: true,
      okNum: 0
    }
  },
  computed: {
    payType() {
      return this.$store.state.payType
    },
    msg() {
      return this.i18n.t('index.errorMsg')
    },
    menuList1() {
      return  [
        {
          url:  require('../../assets/imgs/icon-finance.png'),
          name: this.i18n.t('index.menu1'),
          path: 'finance'
        },
        {
          url:  require('../../assets/imgs/icon-sign.png'),
          name: this.i18n.t('index.menu2'),
          path: 'sign'
        },
        {
          url:  require('../../assets/imgs/icon-integral.png'),
          name: this.i18n.t('index.menu3'),
          path: 'integral'
        },
        {
          url:  require('../../assets/imgs/icon-kefu.png'),
          name: this.i18n.t('index.menu8'),
          path: 'kefu'
        },

      ]
    },
    menuList2() {
      return [
        {
          url:  require('../../assets/imgs/icon-platform.png'),
          name: this.i18n.t('index.menu5'),
          path: 'platform'
        },
        {
          url:  require('../../assets/imgs/icon-role.png'),
          name: this.i18n.t('index.menu6'),
          path: 'role'
        },
        {
          url:  require('../../assets/imgs/icon-share.png'),
          name: this.i18n.t('index.menu7'),
          path: 'shareRole'
        },
        {
          url:  require('../../assets/imgs/icon-company.png'),
          name: this.i18n.t('index.menu4'),
          path: 'company'
        },
      ]
    }
  },
  watch: {
    payType() {
      this.initBank()
    }
  },
  mounted() {
    // localStorage.setItem('bannerList', JSON.stringify(res.data.Items))
    // 从 localStorage 中获取数据
    let bannerList = localStorage.getItem('bannerList')
    if(bannerList !== null) {
      this.bannerList = JSON.parse(bannerList)
    }
    this.init()
    let token =  getToken()
    if(token !== null && token.length > 0) {
      this.initBank()
    } else {
      this.okNum += 1
    }
  },
  methods: {
    toPath(path) {
      if (path) {
        this.$router.push({
          name: path
        })
      }
    },
    checkPage() {
      if (this.okNum == 4) {
        this.$store.state.showLoading = false
      }
    },
    initBank() {
      if (this.payType ==  "usdt") {
        getUsdtList().then(res => {
          this.okNum += 1
          this.checkPage()
          if (res.data.list.length == 0) {
            this.isBind = false
          } else {
            this.isBind = true
          }
        })
      } else {
        getBankList().then(res => {
          this.okNum += 1
          this.checkPage()
          if (res.data.Items.length == 0) {
            this.isBind = false
          } else {
            this.isBind = true
          }
        })
      }
    },
    init() {
      getBannerList().then(res => {
        this.bannerList = res.data.Items
        this.okNum += 1
        this.checkPage()
      })

      getNoticeList().then(res => {
        let list = res.data.Items
        this.okNum += 1
        this.checkPage()
        if (list.length > 0) {
          this.noticeMsg = list[0].Content
        }
      })

      giftList().then(res => {
        this.okNum += 1
        this.checkPage()
        this.goodsList = res.data.Items.slice(0, 6)
      })
    },
    resetTime(time) {
      return moment(time).format('MM/DD HH:mm')
    },
    toWithdraw() {
      this.$router.push({
        name: 'withdraw'
      })
      // if (this.isBind) {

      // } else {
      //   this.$toast(this.payType == 'usdt' ? this.i18n.t('index.desc1') : this.i18n.t('index.desc2'))
      //   this.$router.push({
      //     name: this.payType == 'usdt' ? 'bindUsdt' : 'bindCard'
      //   })
      // }
    },
    toRecharge() {
      this.$router.push({
        name: this.payType == 'usdt' ? 'rechargeUsdt' : 'recharge'
      })
    },
    toExchange(data) {
      this.showMsg = true
    },
    cancel() {
      this.showMsg = false
    },
    toLink(url) {
      if (!url) {
        return
      }
      let a = document.createElement('a')
      a.href = url
      a.target = '_blank'
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    }
  }
}
</script>