<template>
	<div class="order-detail-page page-top">
		<top-bar :title="i18n.t('orderDetail.title')"  @back="back" ></top-bar>

		<div class="top-info" v-if="orderInfo.BurstOrderID != 0" :class="i18n.locale == 'ja' ? 'small' : ''">
      {{i18n.t('orderDetail.desc6')}}
		</div>
    <div class="top-info" v-if="orderInfo.PresetOrderId != 0" :class="i18n.locale == 'ja' ? 'small' : ''">
      {{i18n.t('orderDetail.desc6')}} {{orderInfo.Current}} / {{orderInfo.Total}}
    </div>
		<div class="goods-info">
			<div class="img-box" >
				<div class="img" :style="getGooodsBg(`${orderInfo.Goods.ImgUrl}`)"></div>
			</div>
			<div class="info">
				<div class="goods-title">{{orderInfo.Goods.GoodsName}}</div>
				<div class="price">${{orderInfo.Price}}</div>
			</div>
		</div>

		<div class="page-title">{{i18n.t('orderDetail.title2')}}</div>

		<div class="detail-box">
			<div class="top-box">
				<div class="flex-center-between line" :class="i18n.locale == 'zh-CN' || i18n.locale == 'zh-TW' ? '' : 'small'">
					<div>{{i18n.t('orderDetail.label1')}}：</div>
					<div>{{orderInfo.Multiple || 1}}</div>
				</div>
				<div class="flex-center-between line" :class="i18n.locale == 'zh-CN' || i18n.locale == 'zh-TW' ? '' : 'small'">
					<div>{{i18n.t('orderDetail.label2')}}：</div>
					<div v-if="$store.state.payType == 'THB'">{{parseFloat(orderInfo.Price).toFixed(2)}} {{ $store.state.payType }}</div>
					<div v-else>{{ $store.state.payType }} {{parseFloat(orderInfo.Price).toFixed(2)}} </div>
				</div>
				<div class="flex-center-between line" :class="i18n.locale == 'zh-CN' || i18n.locale == 'zh-TW' ? '' : 'small'">
					<div>{{i18n.t('orderDetail.label3')}}：</div>
					<div v-if="$store.state.payType == 'THB'">{{parseFloat(orderInfo.EarnCommission).toFixed(2)}} {{ $store.state.payType }}</div>
					<div v-else>{{ $store.state.payType }} {{parseFloat(orderInfo.EarnCommission).toFixed(2)}} </div>
				</div>
			</div>
			<div class="bottom-box" v-show="false">
				<div class="flex-center-start label">
					<img :src="addressUrl" alt="">
					<span>{{i18n.t('orderDetail.label4')}}：</span>
				</div>
				<div class="line">
					{{orderInfo.ShippingAddress.Address}}
				</div>
				<div class="line">{{orderInfo.ShippingAddress.ZipCode}} {{orderInfo.ShippingAddress.City}}</div>
			</div>
		</div>

		<div class="page-title">{{i18n.t('orderDetail.title3')}}</div>

		<div class="goods-desc">
			<div class="desc" v-html="orderInfo.Goods.Description"></div>
		</div>

		<div class="order-bottom-btn flex-center-between" v-if="orderInfo.Status == 2">
			<div class="goods-price">${{orderInfo.Price}}</div>
			<Button @click="submit">{{i18n.t('orderDetail.btn')}}</Button>
		</div>

		<Popup v-model="showPopup" class="msg-popup" :close-on-click-overlay="false">
      <div class="msg-box">
        <div class="content flex-column-center clear">
          <div class="logo" >
            <img :src="shoppingUrl2" alt="">
          </div>
          <div class="msg flex-center">
						<img :src="successUrl" alt="">
						<span>{{msg}}</span>
          </div>
        </div>
      </div>
    </Popup>
		<div class="preview-img">
			<img :src="shoppingUrl2" alt="">
		</div>
	</div>
</template>
<script>
import { taskSubmit } from '@/api/task'
import topBar from '@/components/topBar'
import { Popup, Button, Tabs, Tab } from 'vant'
export default {
	components: {
    Popup,
    Button,
		topBar,
		Tabs,
		Tab
  },
  data() {
		return {
			showPopup: false,
			msg: '',
			goodsUrl: require('../../assets/imgs/goods1.png'),
      closeUrl: require('../../assets/imgs/icon-close.png'),
			addressUrl: require('../../assets/imgs/icon-address2.png'),
      shoppingUrl: require('../../assets/imgs/shopping2.png'),
      shoppingUrl2: require('../../assets/imgs/loading4.gif'),
      successUrl: require('../../assets/imgs/icon-success2.png'),
			orderInfo: {
				Goods: {},
				ShippingAddress: {},
			},
			pageType: ''
		}
	},
  mounted() {
		let info = sessionStorage.getItem('orderDetail')
    if (info) {
      this.orderInfo = JSON.parse(info)
      this.orderInfo.ShippingAddress.City = this.resetUserInfo(this.orderInfo.ShippingAddress.City)
      this.orderInfo.ShippingAddress.ZipCode = this.resetUserInfo(this.orderInfo.ShippingAddress.ZipCode)
      this.orderInfo.ShippingAddress.Address = this.resetUserInfo(this.orderInfo.ShippingAddress.Address)
    }
  },
	methods: {
		back() {
			this.$router.go(-1)
		},
		getGooodsBg(url) {
			return `background: url(${url}) center center / contain no-repeat;`
		},
		resetUserInfo(info) {
			if (!info) {
				return ''
			}
      let newInfo = ''
      if (info.length <= 4) {
        newInfo = new Array(info.length - 1).join('*') + info.substring(info.length - 2)
      } {
        newInfo = info.substring(0, 2) + new Array(info.length - 3).join('*') + info.substring(info.length - 2)
      }
      return newInfo
    },
		submit() {
			let param = {
				Id: this.orderInfo.ID
			}
			this.showPopup = true
			this.msg = this.i18n.t('orderDetail.desc1')
			taskSubmit(param).then(res => {
				if (res.code == 0) {
					setTimeout(() => {
						this.msg = this.i18n.t('orderDetail.desc2')
						setTimeout(() => {
							this.msg = this.i18n.t('orderDetail.desc3')
							setTimeout(() => {
								this.msg = this.i18n.t('orderDetail.desc4')
								setTimeout(() => {
									this.msg = this.i18n.t('orderDetail.desc5')
									setTimeout(() => {
										this.showPopup = false
										this.back()
									}, 850)
								}, 850)
							}, 850)
						}, 850)
					}, 500)
				} else {
					this.$toast(res.msg)
					this.showPopup = false
				}
			}).catch(e => {
				this.$toast(this.i18n.t('common.error'))
				this.showPopup = false
			})
		},
		cancel() {
			this.showPopup = false
		}
	}
}
</script>