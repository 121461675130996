import fetch from "../utils/fetch";

// 全局配置
export function configList(data) {
  return fetch({
    url: '/api/mobile/setting/list',
    method: 'get',
    data: data
  });
}
// 用户信息
export function userInfo(data) {
  return fetch({
    url: '/api/mobile/user/info',
    method: 'post',
    data: data
  });
}
// 收益详情
export function commissionInfo(data) {
  return fetch({
    url: '/api/mobile/home-commission',
    method: 'post',
    data: data
  });
}
// 团队收益
export function teamReport(data) {
  return fetch({
    url: '/api/mobile/team-report',
    method: 'post',
    data: data
  });
}
// 退出登录
export function logout(data) {
  return fetch({
    url: '/api/mobile/user/logout',
    method: 'delete',
  });
}
// 上传附件
export function uploadFile(data) {
  return fetch({
    url: '/api/mobile/user/uploadImg',
    method: 'put',
    data,
  });
}
// 银行卡列表
export function getBankList (data) {
  return fetch({
    url: '/api/mobile/bank-card-list',
    method: 'post',
    data
  })
}

export function manifest (data) {
  return fetch({
    url: '/api/manifest.json',
    method: 'post',
    data
  })
}
// 添加银行卡
export function addBank (data) {
  return fetch({
    url: '/api/mobile/bank-card-bind',
    method: 'post',
    data
  })
}
// usdt列表
export function getUsdtList (data) {
  return fetch({
    url: '/api/mobile/usdt/list',
    method: 'get',
    data
  })
}
// 添加USDT
export function addUsdt (data) {
  return fetch({
    url: '/api/mobile/usdt/bind',
    method: 'post',
    data
  })
}
// 提现
export function doWithdraw (data) {
  return fetch({
    url: '/api/mobile/withdraw/add',
    method: 'post',
    data
  })
}
// 提现记录
export function getWithdrawLog (data) {
  return fetch({
    url: '/api/mobile/withdraw-list',
    method: 'post',
    data
  })
}
// 充值
export function doRecharge (data) {
  return fetch({
    url: '/api/mobile/top-up-submission',
    method: 'post',
    data
  })
}
//新增充值记录
export function addRecharge (data) {
  return fetch({
    url: '/api/mobile/payRecord/add',
    method: 'post',
    data
  })
}
// 充值地址信息
export function getRechargeInfo () {
  return fetch({
    url: '/api/mobile/company-bank',
    method: 'post',
    data: {}
  })
}
// 获取充值信息
export function getPayManageList () {
  return fetch({
    url: '/api/mobile/payManage-info',
    method: 'post',
    data: {}
  })
}
// 充值记录
export function getRechargeLog (data) {
  return fetch({
    url: '/api/mobile/recharge-list',
    method: 'post',
    data
  })
}
// 资金类型
export function debtType (data) {
  return fetch({
    url: '/api/mobile/debt-type',
    method: 'post',
    data
  })
}
// 资金明细
export function debtList (data) {
  return fetch({
    url: '/api/mobile/debt-list',
    method: 'post',
    data
  })
}