<template>
  <div class="password-page page-top gray">
    <top-bar :title="i18n.t('editPassword.title2')" class="black small"   @back="back"></top-bar>


    <div class="login-form password">
      
      <div class="form-label bold">{{i18n.t('editPassword.label1')}}</div>
      <div class="form-item flex-center" >
        <div class="input clear"> 
          <input :type="showOldPassword ? 'number' : 'password'" inputmode="decimal" v-model="form.oldPassword" :placeholder="i18n.t('editPassword.labelPlaceholder4')">
        </div>
        <div class="adot" @click="changeShowOldPassword">
          <img :src="showOldPassword ? eyeUrl2 : eyeUrl" alt="" >
        </div>
      </div>
    

      <div class="form-label bold">{{i18n.t('editPassword.label2')}}</div>
      <div class="form-item flex-center" >
        <div class="input clear"> 
          <input :type="showPassword ? 'number' : 'password'" inputmode="decimal" v-model="form.password" :placeholder="i18n.t('editPassword.labelPlaceholder2')">
        </div>
        <div class="adot" @click="changeShowPassword">
          <img :src="showPassword ? eyeUrl2 : eyeUrl" alt="" >
        </div>
      </div>


      <div class="form-label bold">{{i18n.t('editPassword.label3')}}</div>
      <div class="form-item flex-center" >
         <div class="input clear"> 
          <input :type="showPassword2 ? 'number' : 'password'" inputmode="decimal" v-model="form.password2" :placeholder="i18n.t('editPassword.labelPlaceholder3')">
        </div>
        <div class="adot" @click="changeshowPassword2">
          <img :src="showPassword2 ? eyeUrl2 : eyeUrl" alt="" >
        </div>
      </div>

      <Button class="login-btn" @click="submit" :disabled="btnDisabled">{{i18n.t(`editPassword.btn`)}}</Button>
    </div>

  </div>
</template>
<script>
  import topBar from '@/components/topBar'
  import { Button, Popup } from 'vant'
  import { editSafePassword } from '@/api/setting'
  import { getUser } from '@/utils/auth'
  export default {
    components: {
      Button,
      Popup,
      topBar,
    },
    data() {
      return {
        form: {
          oldPassword: '',
          password: '',
          password2: '',
        },
        keyboardValue: '',
        extra: '',
        eyeUrl: require('../../assets/imgs/icon-eye.png'),
        eyeUrl2: require('../../assets/imgs/icon-eye2.png'),
        showPassword: false,
        showPassword2: false,
        showOldPassword: false,
        isDisabled: false,
        showPasswordAuth: false,
        passwordAuthList: [],
        inputType: 1
      }
    },
    computed: {
      btnDisabled() {
        return !(this.form.password && this.form.password2 && this.form.oldPassword) 
      }
    },
    methods: {
      back() {
        this.$router.go(-1)
      },
      toLogin() {
        this.$router.push({
          name: 'login'
        })
      },
      submit() {
        if (!this.form.oldPassword) {
          this.$toast(this.i18n.t('editPassword.labelPlaceholder4'))
          return
        }
        if (!this.form.password) {
          this.$toast(this.i18n.t('editPassword.labelPlaceholder2'))
          return
        }
        if (!this.form.password2) {
          this.$toast(this.i18n.t('editPassword.labelPlaceholder3'))
          return
        }
        if (this.form.password != this.form.password2) {
          this.$toast(this.i18n.t('editPassword.labelPlaceholder5'))
          return
        }

        let form = new FormData()
        form.append('transaction_password', this.form.oldPassword)
        form.append('new_transaction_password', this.form.password)
        form.append('user_name', getUser())
        editSafePassword(form).then(res => {
          if (res.code == 0) {
            this.$toast(this.i18n.t('editPassword.success2'))
            setTimeout(() => {
              this.back()
            }, 1000)
          } else {
            this.$toast(res.msg)
          }
        })
      },
      changeShowOldPassword() {
        this.showOldPassword = !this.showOldPassword
      },
      changeShowPassword() {
        this.showPassword = !this.showPassword
      },
      changeshowPassword2() {
        this.showPassword2 = !this.showPassword2
      },
      changeValue(val) {
        this.$nextTick(() => {
          if (this.inputType == 1) {
            this.form.oldPassword = this.keyboardValue
          }
          if (this.inputType == 2) {
            this.form.password = this.keyboardValue
          }
          if (this.inputType == 3) {
            this.form.password2 = this.keyboardValue
          }
        })
      },
      changeInput(type) {
        this.inputType = type
        this.keyboardValue = type == 1 ? this.form.oldPassword : (type == 2 ? this.form.password : this.form.password2)
      },
      resetValue(value) {
        let length = value ? value.length : 0
        return new Array(length + 1).join('*')
      },
    },
  }
</script>