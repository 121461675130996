<template>
	<div class="help-page page-top">
		<top-bar :title="i18n.t('help.title')"  @back="back" ></top-bar>

		<div class="top-info">
			<div class="text-box flex-column-start-center">
				<div class="text1">{{i18n.t('content.label11')}}</div>
				<div class="text2">{{i18n.t('content.label12')}}</div>
			</div>
		</div>

		<div class="help-list">
			<div class="list " :class="item.showContent ? 'show' : ''" v-for="(item, i) in helpData" :key="i" >
				<div class="title flex-center-between" @click="changeOpen(item, i)">
					<div  class="label">{{item.Title}}</div>
					<div class="icon">
						<img :src="downUrl" alt="">
					</div>
				</div>

				<div class="content" v-html="item.Content">
				</div>
			</div>
		</div>

	</div>
</template>
<script>
import topBar from '@/components/topBar'
import { Popup, Button, Tabs, Tab } from 'vant'
import { helpList  } from '@/api/setting'
export default {
	components: {
    Popup,
    Button,
		topBar,
		Tabs,
		Tab
  },
  data() {
		return {
			moneyUrl: require('../../assets/imgs/icon-money2.png'),
			downUrl: require('../../assets/imgs/icon-arrow-down-gray.png'),
			tabIndex: 0,
			helpData: [],
			okNum: 0
		}
	},
  mounted() {
		this.init()
  },
	methods: {
		init() {
			helpList().then(res => {
				this.helpData = res.data.Items
				this.$store.state.showLoading = false
			})
		},
		
		back() {
			this.$router.go(-1)
		},
		changeOpen(data, i) {
			let index = this.helpData.findIndex(v => {
				return v.showContent
			})
			if (index > -1 && index != i) {
				this.$set(this.helpData[index], 'showContent', false)
			}
			let show = !data.showContent
			this.$set(this.helpData[i], 'showContent', show)
		}
	
	}
}
</script>