<template>
	<div class="recharge-info-page page-top">
		<top-bar :title="i18n.t('recharge.title')" class="border"  @back="back" ></top-bar>

    <div class="top flex">
      <div class="step " :class="i % 2 === 0 ? 'flex-start align-top' : 'flex-end align-bottom'" v-for="(item, i) in stepList" :key="i">
        <div class="flex-column-center box">
          <img :src="item.url" alt="" v-if="i % 2 === 1">
          <div class="flex-center number" v-if="i % 2 === 0">{{i + 1}}</div>
          <div class="label" :class="i18n.locale == 'zh-CN' || i18n.locale == 'zh-TW' ? '' : 'small'">{{item.name}}</div>
          <div class="flex-center number" v-if="i % 2 === 1">{{i + 1}}</div>
          <img :src="item.url" alt="" v-if="i % 2 === 0">
        </div>
      </div>
    </div>

    <div class="default-form">
      <!-- <div class="form-label">{{i18n.t('recharge.label6')}}</div>
      <div class="form-item">
        <input type="text" :placeholder="i18n.t('recharge.placeholder3')" v-model="form.address"/>
      </div> -->

      <div class="form-label">{{i18n.t('recharge.label1')}}</div>
      <div class="form-item">
        <input type="number" inputmode="inputmode" :placeholder="i18n.t('recharge.placeholder')" v-model="form.money"/>
      </div>

      <div class="form-label">{{i18n.t('recharge.label2')}}</div>
      <div class="form-item flex-center-start">
        <Uploader 
          :before-read="doUpload" 
          v-model="fileList" 
          @delete="deleteFile" 
          :max-count="1" 
        >
          <div class="upload-btn flex-center">
            <Icon name="plus" size="40" color="#000"></Icon>
          </div>
        </Uploader>
        <span class="form-label">（{{i18n.t('recharge.label3')}}）</span>
      </div>

      <Button class="btn" @click="submit">{{i18n.t('recharge.btn')}}</Button>

      <div class="small-label">{{i18n.t('recharge.label4')}}</div>

      <div class="bottom-desc">{{i18n.t('recharge.desc')}}</div>
    </div>

	</div>
</template>
<script>
import topBar from '@/components/topBar'
import { Icon, Button, Uploader,  } from 'vant'
import { uploadFile, getRechargeInfo, doRecharge, getPayManageList, addRecharge  } from '@/api/user'
export default {
	components: {
    Icon,
    Button,
		topBar,
    Uploader
  },
  data() {
		return {
      form: {
        money: '',
        address: ''
      },
      fileList: [],
      fileIds: '',
      stepList: [
        {
          url: require('../../assets/imgs/icon-step1.png'),
          name: this.i18n.t('recharge.step1')
        },
        {
          url: require('../../assets/imgs/icon-step2.png'),
          name: this.i18n.t('recharge.step2')
        },
        {
          url: require('../../assets/imgs/icon-step3.png'),
          name: this.i18n.t('recharge.step3')
        },
        {
          url: require('../../assets/imgs/icon-step4.png'),
          name: this.i18n.t('recharge.step4')
        },
      ],
      bankInfo: {},
      address: ''
		}
	},
  mounted() {
    this.init()
  },
	methods: {
		init() {
      getRechargeInfo().then(res => {
        if (res.data.Items.length > 0) {
          this.bankInfo = res.data.Items[0]
        }
      })
      getPayManageList().then(res => {
        // this.address = res.data
      })
		},
		
		back() {
			this.$router.go(-1)
		},
    toLog() {

    },
    toPath(path) {
      if (path) {
        this.$router.push({
          name: path
        })
      }
    },
     doUpload(file) {
      let form = new FormData()
      form.append('file', file)
      return new Promise((resolve, reject) => {
        uploadFile(form).then(res => {
          if (res.code == 0) {
            resolve(file)
            this.fileIds = res.data.url
          } else {
            this.$toast(this.i18n.t('recharge.error'))
            reject()
          }
        }).catch(e => {
          this.$toast(this.i18n.t('recharge.error'))
          reject()
        })
      })
    },
    deleteFile() {
      this.fileIds = ''
    },
    submit() {
      // if (!this.form.address) {
      //   this.$toast(this.i18n.t('recharge.placeholder3'))
      //   return
      // }
      if (!this.form.money) {
        this.$toast(this.i18n.t('recharge.placeholder'))
        return
      }
      if (!this.fileIds) {
        this.$toast(this.i18n.t('recharge.placeholder2'))
        return
      }
      // let form = new FormData()
      // form.append('BankId', this.bankInfo.ID)
      // form.append('Amount', this.form.money)
      // form.append('Img', this.fileIds )
      // doRecharge(form).then(res => {
      //   if (res.code == 0) {  
      //     this.$toast(this.i18n.t('recharge.success'))
      //     this.form.money = ''
      //     this.fileIds = ''
      //     this.fileList = []
      //   } else {
      //     this.$toast(res.msg)
      //   }
      // })

      let form2 = new FormData()
      form2.append('address', '')
      form2.append('protocol', 'TRC20')
      form2.append('amount', this.form.money)
      form2.append('payment_voucher', this.fileIds)
      addRecharge(form2).then(res => {
        if (res.code == 0) {  
          this.$toast(this.i18n.t('recharge.success'))
          this.form.money = ''
          this.fileIds = ''
          this.fileList = []
        } else {
          this.$toast(res.msg)
        }
      })
    }
	}
}
</script>