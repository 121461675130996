import fetch from "../utils/fetch";


// 修改资金密码
export function editSafePassword (data) {
  return fetch({
    url: '/api/mobile/user/changeTransactionPassword',
    method: 'post',
    data
  })
}
// 修改登录密码
export function editPassword (data) {
  return fetch({
    url: '/api/mobile/user/changePassword',
    method: 'post',
    data
  })
}
// 客服列表
export function kefuList(data) {
  return fetch({
    url: '/api/mobile/customer-list',
    method: 'post',
    data: data
  });
}
// 帮助列表
export function helpList(data) {
  return fetch({
    url: '/api/mobile/help-lists',
    method: 'post',
    data: data
  });
}
// 新增地址
export function addAddress(data) {
  return fetch({
    url: '/api/mobile/DeliveryAddress-add',
    method: 'post',
    data: data
  });
}
// 地址列表
export function getAddressList(data) {
  return fetch({
    url: '/api/mobile/DeliveryAddress-list',
    method: 'post',
    data: data
  });
}
// 地址选择
export function addressSelect(data) {
  return fetch({
    url: '/api/mobile/DeliveryAddress-select',
    method: 'post',
    data: data
  });
}
// 积分是否可以领取
export function integralCheck(data) {
  return fetch({
    url: '/api/mobile/check-integral',
    method: 'post',
    data: data
  });
}
// 积分领取
export function addIntegralLog(data) {
  return fetch({
    url: '/api/mobile/IntegralRecord-add',
    method: 'post',
    data: data
  });
}