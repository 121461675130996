<template>
  <div class="top-bar-main flex-center-between" >
    <div class="left">
      <Icon name="arrow-left" size="20" @click="back" v-show="!hideBack"></Icon>
    </div>

    <div class="title">{{title}}</div>

    <div class="flex-center right">
      <span v-if="rightText" @click="rightClick">{{rightText}}</span>
      <img :src="logUrl" alt="" v-if="rightIcon == 'detail' " class="big" @click="toLog">
      <img :src="logUrl2" alt="" v-if="rightIcon == 'log' " class="big" @click="toLog"> 
      <img :src="kefuUrl" alt="" v-if="rightIcon == 'kefu' " class="big" @click="toLog"> 
    </div>
  </div>
</template>
<script>
import { Icon } from 'vant'
export default {
  components: {
    Icon
  },
  props: {
    title: String,
    rightText: String,
    rightIcon: String,
    parentId: String,
    hideBack: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      logUrl: require('../../assets/imgs/icon-log2.png'),
      logUrl2: require('../../assets/imgs/icon-money4.png'),
      kefuUrl: require('../../assets/imgs/icon-kefu3.png'),
      animateStop: false,
      clearStyle: false
    }
  },
  computed: {
    screenScale() {
      return this.$store.state.screenScale
    }
  },
  mounted() {
  },
  methods: {
    back() {
      this.$emit('back')
    },
    rightClick() {
      this.$emit('right')
    },
    toHelp() {
      this.$router.push({
        name: 'help'
      })
    },
    toLog() {
			this.$emit('detail')
		},
  }
}
</script>