import fetch from "../utils/fetch";
import qs from "qs";


// 轮播图
export function getBannerList(data) {
  return fetch({
    url: '/api/mobile/carousel-lists',
    method: 'post',
    data: data
  });
}
// 消息
export function getNoticeList(data) {
  return fetch({
    url: '/api/mobile/notice-lists',
    method: 'post',
    data: data
  });
}
// 积分礼品
export function giftList(data) {
  return fetch({
    url: '/api/mobile/BonusGift-lists',
    method: 'post',
    data: data
  });
}
// 签到
export function userSign(data) {
  return fetch({
    url: '/api/mobile/check-in',
    method: 'post',
    data: data
  });
}

// LeaveList
export function getLeaveList(data) {
    return fetch({
        url: '/api/mobile/leave-list',
        method: 'post',
        data: data
    });
}

// 抽奖列表
export function getRaffleList(data) {
  return fetch({
    url: '/api/mobile/PrizeDraw-list',
    method: 'post',
    data: data
  });
}
// 抽奖
export function doRaffle(data) {
  return fetch({
    url: '/api/mobile/PrizeDrawRecord-receive',
    method: 'post',
    data: data
  });
}
export function CheckInList(data) {
  return fetch({
    url: '/api/mobile/check-in-list',
    method: 'post',
    data: data
  });
}

// IntegralRecordLists
export function IntegralRecordLists(data) {
    return fetch({
        url: '/api/mobile/IntegralRecord-lists',
        method: 'post',
        data: data
    });
}

// 抽奖记录
export function raffleLog(data) {
  return fetch({
    url: '/api/mobile/PrizeDrawRecord-list',
    method: 'post',
    data: data
  });
}
// 理财列表
export function financialList(data) {
  return fetch({
    url: '/api/mobile/financial-setting',
    method: 'post',
    data: data
  });
}
// 理财买入
export function financialBuy(data) {
  return fetch({
    url: '/api/mobile/Financial-buy',
    method: 'post',
    data: data
  });
}
// 理财取出
export function financialOut(data) {
  return fetch({
    url: '/api/mobile/Financial-out',
    method: 'post',
    data: data
  });
}
// 理财记录
export function financialLog(data) {
  return fetch({
    url: '/api/mobile/FinancialRecord-lists',
    method: 'post',
    data: data
  });
}

