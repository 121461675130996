<template>
	<div class="withdraw-page page-top">
		<top-bar :title="i18n.t('bindCard.title2')" class="border"  @back="back" ></top-bar>

    <div class="default-form">




      <div class="form-label">{{i18n.t('bindCard.label6')}}</div>
      <div class="form-item">
        <input type="text" :placeholder="i18n.t('bindCard.placeholder6')" v-model="form.address"/>
      </div>

      <div class="form-label">{{i18n.t('bindCard.label4')}}</div>
      <div class="form-item ">
        <input  :type="showPassword ? 'number' : 'password'" inputmode="decimal" :placeholder="i18n.t('bindCard.placeholder4')" v-model="form.password"/>
        <div class="adot" @click="changeShowPassword">
          <img :src="showPassword ? eyeUrl2 : eyeUrl" alt="" >
        </div>
      </div>

       <div class="form-label">{{i18n.t('bindCard.label5')}}</div>
      <div class="form-item">
        <div class="prefit">
          <img :src="bitUrl" alt="">
        </div>
        <input type="text" :class="bitUrl ? 'pre' : ''" :placeholder="i18n.t('bindCard.placeholder5')" v-model="protocolName" readonly @click="changeType"/>
        <div class="adot small" >
          <img :src="downUrl" alt="" >
        </div>
      </div>

      <Button class="btn" @click="submit">{{i18n.t('bindCard.btn')}}</Button>
    </div>

    <!-- <Popup v-model="showPopup" position="bottom">
      <Picker
        show-toolbar
        value-key="text"
        :columns="columns"
        @confirm="chooseType"
        :confirm-button-text="i18n.t('common.ensure')"
        :cancel-button-text="i18n.t('common.cancel')"
        @cancel="cancel"
      ></Picker>
    </Popup> -->

    <Popup v-model="showPopup" class="msg-popup" position="bottom">
      <div class="picker-box">
        <div class="close flex-center-between" @click="cancel">
          <div>{{i18n.t('bindCard.label5')}}</div>
          <img :src="closeUrl" alt="">
        </div>
        <div class="list">
          <div class="bit flex-center-between"  v-for="(item, i) in columns" :key="i" @click="chooseType(item)">
            <div class="flex-center-start">
              <img :src="item.icon" alt="">
              <span class="name">{{item.text}}</span>
            </div>
            <div class="icon">
              <Icon name="success" size="20" color="#000" v-if="item.key == form.protocol"/>
            </div>
          </div>
        </div>
      </div>
    </Popup>
	</div>
</template>
<script>
import topBar from '@/components/topBar'
import { Popup, Button, Tabs, Tab, Picker, Icon } from 'vant'
import { addUsdt  } from '@/api/user'
import { getUserId  } from '@/utils/auth'
export default {
	components: {
    Popup,
    Button,
		topBar,
		Tabs,
		Tab,
    Picker,
    Icon
  },
  data() {
		return {
      bankUrl: require('../../assets/imgs/bank.png'),
      eyeUrl: require('../../assets/imgs/icon-eye.png'),
      eyeUrl2: require('../../assets/imgs/icon-eye2.png'),
      downUrl: require('../../assets/imgs/icon-arrow-down.png'),
      closeUrl: require('../../assets/imgs/icon-close.png'),
      form: {
        protocol: '',
        address: '',
        password: '',
        uid: ''
      },
      showPopup: false,
      protocolName: '',
      showPassword: false,
      bitUrl: '',
      columns: [
        {
          key: 'USDT-TRC20',
          text: 'USDT-TRC20',
          icon: require('../../assets/imgs/bit1.png'),
        },
        {
          key: 'USDT-ERC20',
          text: 'USDT-ERC20',
          icon: require('../../assets/imgs/bit2.png'),
        },
        {
          key: 'USDC-ERC20',
          text: 'USDC-ERC20',
          icon: require('../../assets/imgs/bit2.png'),
        },
        {
          key: 'ETH-ERC20',
          text: 'ETH-ERC20',
          icon: require('../../assets/imgs/bit3.png'),
        },
      ]
		}
	},
  mounted() {
    let type = this.$route.query.type
    let index = this.columns.findIndex(v => {
      return v.key == type
    })
    if (index > -1) {
      this.chooseType(this.columns[index])
    }
  },
	methods: {
		back() {
			this.$router.go(-1)
		},
    toPath(path) {
      if (path) {
        this.$router.push({
          name: path
        })
      }
    },
    changeShowPassword() {
      this.showPassword = !this.showPassword
    },
    chooseType(data) {
      this.form.protocol = data.key
      this.protocolName = data.text
      this.bitUrl = data.icon
      this.showPopup = false
    },
    changeType() {
      this.showPopup = true
    },
    cancel() {
      this.showPopup = false
    },
    submit() {
      if (!this.form.protocol) {
        this.$toast(this.i18n.t('bindCard.placeholder5'))
        return
      }
      if (!this.form.address) {
        this.$toast(this.i18n.t('bindCard.placeholder6'))
        return
      }
      if (!this.form.password) {
        this.$toast(this.i18n.t('bindCard.placeholder4'))
        return
      }
      let form = new FormData()
      form.append('protocol', this.form.protocol)
      form.append('address', this.form.address)
      form.append('passwd', this.form.password)
      form.append('uid', getUserId())
      addUsdt(form).then(res => {
        if (res.code == 0) {
          this.$toast(this.i18n.t('bindCard.success'))
          setTimeout(() => {
            this.back()
          }, 1000);
        } else {
          this.$toast(res.msg)
        }
      })
    }
	}
}
</script>