<template>
	<div class="log-page ">
    <top-bar :title="i18n.t('rechargeLog.title')"   @back="back"></top-bar>

    <div class="list-main">
      <List
        v-if="dataList.length > 0"
        v-model="loading"
        :finished="finished"
        :finished-text="i18n.t('common.noMore')"
        @load="onLoad">
        <div class="list " v-for="(item, i) in dataList" :key="i"> 
          <div class="flex-center-between label">
            <div>{{i18n.t('rechargeLog.label')}}</div>
            <div class="status  flex-center">
              <img :class="item.Status == 1 ? 'small' : ''" :src="item.Status == 1 ? successUrl : (item.Status == 2 ? errorUrl : waitUrl)" alt="">
              {{i18n.t(`rechargeLog.status${item.Status}`)}}
            </div>
          </div>
          <div class="flex-center-between number">
            <div>{{item.Amount ? parseFloat(item.Amount).toFixed(2) : '0.00'}}{{ $store.state.payType }}</div>
            <div>{{formatTime(item.CreatedAt)}}</div>
          </div>
        </div>
      </List>

      <div class="empty flex-column-center" v-if="dataList.length == 0 && isInit">
        <img :src="emptyUrl" alt="">
        <span class="desc">{{i18n.t('trade.empty')}}</span>
      </div>
    </div>
   
	</div>
</template>
<script>
import { getRechargeLog } from '@/api/user'
import topBar from '@/components/topBar'
import { List } from 'vant'
import {formatTime} from "../../utils/common";
export default {
  components: {
    topBar,
    List
  },
  data() {
		return {
      successUrl: require('../../assets/imgs/icon-success.png'),
      waitUrl: require('../../assets/imgs/icon-waiting.png'),
      errorUrl: require('../../assets/imgs/icon-error.png'),
      page: {
        page: 1,
        limit: 20
      },
      isInit: false,
      loading: false,
      finished: false,
      totalPage: 1,
			emptyUrl: require('../../assets/imgs/empty.png'),
      dataList: [],
    
		}
	},
  mounted() {
    this.init()
  },
	methods: {
    formatTime,
    onLoad() {
      this.page.page += 1
      this.init(true)
    },
    init(isAdd) {
      let form = new FormData()
      form.append('page', this.page.page)
      form.append('limit', this.page.limit)
      getRechargeLog(form).then(res => {
        if (res.data.Items) {
          if (isAdd) {
            this.dataList = this.dataList.concat(res.data.Items)
          } else {
            this.dataList = res.data.Items
          }
          this.totalPage = res.data.Pagination ? res.data.Pagination.totalPage : 1
          if (this.totalPage <= this.page.page) {
             this.finished = true;
          }
        }
        this.isInit = true
        this.loading = false
        this.$store.state.showLoading = false
      })
    },
		back() {
			this.$router.go(-1)
		},
    
    toDetail(data) {
      sessionStorage.setItem('logData', JSON.stringify(data))
      this.$router.push({
        name: 'rechargeLogDetail'
      })
    }
	}
}
</script>