<template>
	<div class="order-detail-page page-top">
		<top-bar :title="i18n.t('billDetail.title')"  @back="back" ></top-bar>



		<div class="detail-box">
			<div class="top-box big">
				<div class="box flex-center">
					<div class="img-box" >
						<div class="img" :style="getGooodsBg(`${orderInfo.Goods.ImgUrl}`)"></div>
					</div>
					<div class="title">
						{{orderInfo.Goods.GoodsName}}
					</div>
				</div>


				<div class="flex-center-between line">
					<div>{{i18n.t('billDetail.label1')}}：</div>
					<div>{{orderInfo.Price}} {{ $store.state.payType }}</div>
				</div>
				<div class="flex-center-between line big">
					<div>{{i18n.t('billDetail.label2')}}：</div>
					<div>{{orderInfo.formatTime(orderInfo.CreatedAt)}}</div>
				</div>
				<div class="flex-center-between line big">
					<div>{{i18n.t('billDetail.label3')}}：</div>
					<div>{{orderInfo.EarnCommission}} {{ $store.state.payType }}</div>
				</div>
				<div class="flex-center-between line big">
					<div>{{i18n.t('billDetail.label4')}}：</div>
					<div>{{i18n.t(`billDetail.status${orderInfo.Status}`)}}</div>
				</div>
			</div>
			<div class="bottom-box">
				<div class="flex-center-start label">
					<img :src="addressUrl" alt="">
					<span>{{i18n.t('billDetail.label5')}}：</span>
				</div>
				<div class="line">
					{{orderInfo.ShippingAddress.Address}}
				</div>
				<div class="line">{{orderInfo.ShippingAddress.ZipCode}} {{orderInfo.ShippingAddress.City}}</div>
			</div>
		</div>
	

	</div>
</template>
<script>
import topBar from '@/components/topBar'
import { Popup, Button, Tabs, Tab } from 'vant'
export default {
	components: {
    Popup,
    Button,
		topBar,
		Tabs,
		Tab
  },
  data() {
		return {
			goodsUrl: require('../../assets/imgs/goods1.png'),
			addressUrl: require('../../assets/imgs/icon-address2.png'),
			orderInfo: {
				Goods: {},
				ShippingAddress: {},
			},
			pageType: ''
		}
	},
  mounted() {
		let info = sessionStorage.getItem('billDetail')
    if (info) {
      this.orderInfo = JSON.parse(info)
      this.orderInfo.ShippingAddress.City = this.resetUserInfo(this.orderInfo.ShippingAddress.City)
      this.orderInfo.ShippingAddress.ZipCode = this.resetUserInfo(this.orderInfo.ShippingAddress.ZipCode)
      this.orderInfo.ShippingAddress.Address = this.resetUserInfo(this.orderInfo.ShippingAddress.Address)
    }
  },
	methods: {
		back() {
			this.$router.go(-1)
		},
		getGooodsBg(url) {
			return `background: url(${url}) center center / contain no-repeat;`
		},
		resetUserInfo(info) {
			if (!info) {
				return ''
			}
      let newInfo = ''
      if (info.length <= 4) {
        newInfo = new Array(info.length - 1).join('*') + info.substring(info.length - 2)
      } {
        newInfo = info.substring(0, 2) + new Array(info.length - 3).join('*') + info.substring(info.length - 2)
      }
      return newInfo
    },
	}
}
</script>