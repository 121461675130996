<template>
	<div class="recharge-page page-top">
		<top-bar :title="i18n.t('recharge.title2')" class="border"  @back="back"></top-bar>

    <!-- <div class="page-title">{{i18n.t('recharge.label8')}}</div>
    <div class="tab-box flex">
      <div class="tab flex-center" v-for="(item, i) in tabList" :key="i" @click="changeTab(item, i)" :class="tabIndex == i ? 'active' : ''">{{item.name}}</div>
    </div> -->

    <div class="page-title">{{i18n.t('recharge.label9')}}</div>
    <div class="qrcode-box flex-center">
      <div class="qrcode" id="qrcode" ref="qrcode"></div>
    </div>

    <div class="address-box flex-center">
      <div class="address">{{showAddress}}</div>
      <img :src="copyUrl" class="copy-icon" alt="" @click="copyData">
    </div>

    <div class="page-title">{{i18n.t('recharge.label8')}}</div>
    <div class="default-form">
      <div class="form-item">
        <div class="prefit">
          <img :src="bitUrl" alt="">
        </div>
        <input type="text" :class="bitUrl ? 'pre' : ''" :placeholder="i18n.t('bindCard.placeholder5')" v-model="protocolName" readonly @click="changeType"/>
        <div class="adot small" >
          <img :src="downUrl" alt="" >
        </div>
      </div>
    </div>

    <div class="sub-title">{{i18n.t('recharge.label10')}}：</div>
    <div class="desc clear gray">{{i18n.t('recharge.desc4_1')}}{{ protocolName }}{{i18n.t('recharge.desc4_2')}}</div>
    <div class="desc gray">{{i18n.t(`recharge.desc${protocolName == 'ETH-ERC20' ? '6' : '5'}`)}}</div>

    <div class="btn-box flex-center-between">
      <Button class="red-btn" @click="toPath('rechargeInfo')">{{i18n.t('recharge.btn2')}}</Button>
      <Button class="gray-btn copy-icon" @click="copyData">{{i18n.t('recharge.btn4')}}</Button>
    </div>

    <Popup v-model="showPopup" class="msg-popup" position="bottom">
      <div class="picker-box">
        <div class="close flex-center-between" @click="cancel">
          <div>{{i18n.t('bindCard.label5')}}</div>
          <img :src="closeUrl" alt="">
        </div>
        <div class="list">
          <div class="bit flex-center-between"  v-for="(item, i) in columns" :key="i" @click="chooseType(item)">
            <div class="flex-center-start">
              <img :src="item.icon" alt="">
              <span class="name">{{item.text}}</span>
            </div>
            <div class="icon">
              <Icon name="success" size="20" color="#000" v-if="item.key == protocol"/>
            </div>
          </div>
        </div>
      </div>
    </Popup>
	</div>
</template>
<script>
import topBar from '@/components/topBar'
import { Popup, Button, Tabs, Tab, Icon } from 'vant'
import Clipboard from 'clipboard';
import QRCode from 'qrcodejs2'
import html2canvas from 'html2canvas'
import { getPayManageList } from '@/api/user'
export default {
	components: {
    Popup,
    Button,
		topBar,
		Tabs,
		Tab,
    Icon
  },
  data() {
		return {
      tabIndex: 0,
      tabList: [
        {
          name: 'USDT-TRC20',
          key: 'TRC20'
        },
        {
          name: 'USDT-ERC20',
          key: 'ERC20'
        },
      ],
      copyUrl: require('../../assets/imgs/icon-copy.png'),
      downUrl: require('../../assets/imgs/icon-arrow-down.png'),
      closeUrl: require('../../assets/imgs/icon-close.png'),
      bitUrl: '',
      bitLogo: '',
      protocolName: '',
      protocol: '',
      address: '',
      erc20: '',
      showAddress: '',
      network:"USDT-TRC20",
      showPopup: false,
      columns: [
        {
          key: 'USDT-TRC20',
          text: 'USDT-TRC20',
          icon: require('../../assets/imgs/bit1.png'),
          logo: require('../../assets/imgs/bit-logo1.png'),
        },
        {
          key: 'USDT-ERC20',
          text: 'USDT-ERC20',
          icon: require('../../assets/imgs/bit1.png'),
          logo: require('../../assets/imgs/bit-logo1.png'),
        },
        {
          key: 'USDC-ERC20',
          text: 'USDC-ERC20',
          icon: require('../../assets/imgs/bit3.png'),
          logo: require('../../assets/imgs/bit-logo3.png'),
        },
        {
          key: 'ETH-ERC20',
          text: 'ETH-ERC20',
          icon: require('../../assets/imgs/bit2.png'),
          logo: require('../../assets/imgs/bit-logo2.png'),
        },
      ]
		}
	},
  mounted() {
    this.init()
  },
	methods: {
		init() {
      getPayManageList().then(res => {
        let data = res.data || {}
        this.columns.forEach(v => {
          let index = data.findIndex(val => {
            return v.key == val.Protocol
          })
          if (index > -1) {
            v.address = data[index].Address
          }
        })
        let index = this.columns.findIndex(v => {
          return v.address
        })
        if (index === -1) {
          index = 0
        }
        let bitData = this.columns[index]
        this.address = bitData.address
        this.bitUrl = bitData.icon
        this.bitLogo = bitData.logo
        this.protocol = bitData.key
        this.protocolName = bitData.text
        this.setText(this.address)
      })
		},
    setText(text){

      this.showAddress = text
      // 获取之前的二维码画布元素
      let qrcodeContainer = this.$refs.qrcode;

      // 清除画布内容
      while (qrcodeContainer.firstChild) {
        qrcodeContainer.removeChild(qrcodeContainer.firstChild);
      }
      let qrcode = new QRCode(this.$refs.qrcode, {
        text: text,
        width: 130,
        height: 130,
        correctLevel: QRCode.CorrectLevel.H
      })
      // 添加中心logo
      if (!text) {
        return
      }
      let logo = new Image();
      logo.crossOrigin = 'Anonymous';
      logo.src = this.bitLogo
      //生成log图片
      logo.onload =() =>{
        let container = this.$refs.qrcode
        let qrImg = qrcode._el.getElementsByTagName('img')[0];
        let canvas = qrcode._el.getElementsByTagName('canvas')[0];
        let cxt = canvas.getContext('2d');

        cxt.drawImage(logo, 45, 45, 40, 40);
        qrImg.src = canvas.toDataURL();
        container.appendChild(qrcode._el)
      }
    },
		back() {
			this.$router.go(-1)
		},
    toLog() {

    },
    toPath(path) {
      if (path) {
        this.$router.push({
          name: path
        })
      }
    },
    saveImg() {
			html2canvas(document.getElementById('qrcode')).then(canvas => {
				let url = canvas.toDataURL('image/png')
				let saveUrl = this.base64ToBlob(url)
				let a = document.createElement('a')
				// let evt = document.createEvent('HTMLEvents')
				// evt.initEvent('click', true, true)
				a.download = `${this.i18n.t('recharge.img')}.png`
				a.href = URL.createObjectURL(saveUrl)
				a.click()
			})
		},
    base64ToBlob(url) {
      return
			let parts = url.split(';base64,')
			let contentType = parts[0].split(':')[1]
			let raw = window.atob(parts[1])
			let rawLength = raw.length
			let uInt8Array = new Uint8Array(rawLength)
			for (let i = 0; i < rawLength; i++) {
				uInt8Array[i] = raw.charCodeAt(i)
			}

			return new Blob([uInt8Array], {type: contentType})
		},
    copyData() {
      let data = this.showAddress
      const clipboard = new Clipboard('.copy-icon', {text: () => data});
			this.$toast(this.i18n.t('recharge.copySuccess'))
    },
    changeTab(data, i) {
      this.tabIndex = i
      if(this.tabIndex === 0) {
        this.network = "USDT-TRC20"
        this.setText(this.address)
      } else {
        this.network = "USDT-ERC20"
        this.setText(this.erc20)
      }
    },
    chooseType(data) {
      this.protocol = data.key
      this.protocolName = data.text
      this.bitUrl = data.icon
      this.bitLogo = data.logo
      this.showPopup = false
      this.address = data.address
      this.setText(data.address)
    },
    changeType() {
      this.showPopup = true
    },
    cancel() {
      this.showPopup = false
    },
	}
}
</script>