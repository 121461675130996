<template>
	<div class="integral-page page-top">
		<top-bar :title="i18n.t('integral.title')" class="border"  @back="back" ></top-bar>

    <div class="top-img flex-column-start-center">
      <div class="label">{{i18n.t('integral.label1')}}</div>
      <div class="count">{{userIntergal}}</div>
    </div>

    <div class="integral-info">
      <div class="integral-list">
        <div class="list flex-center"  :class="i18n.locale == 'zh-CN' || i18n.locale == 'zh-TW' ? '' : 'small'" v-for="(item, i) in integralList" :key="i">
          <div class="icon">
            <img :src="item.icon" alt="">
          </div>
          <div class="info flex-1">
            <div class="flex-center-start">
              <span class="bold">{{item.label}}</span>
               <img :src="starUrl" alt=""> 
              <span>{{item.count}}{{i18n.t('integral.label2')}}</span>
            </div>
            <div class="desc">
              {{i18n.t(`integral.label3_${i}`)}}{{item.count}}{{i18n.t('integral.label2')}}
            </div>
          </div>
          <div class="btn small">
            <Button :class="item.status != 2 ? 'disabled' : ''" @click="submit(item)">{{i18n.t(`integral.btn${item.status}`)}}</Button>
          </div>
        </div>
      </div>
    </div>

    <div class="integral-goods">
      <div class="integral-title">{{i18n.t('integral.label4')}}</div>

      <div class="goods-list flex-wrap">
        <div class="goods flex-column-center" v-for="(item, i) in goodsList" :key="i">
          <div class="img-box">
            <img :src="`${item.Img}`" alt="">
          </div>
          <div class="title">{{item.Name}}</div>
          <div class="desc">{{item.ExchangeIntegral}}</div>
          <Button class="btn" @click="toExchange">{{i18n.t('integral.label5')}}</Button>
        </div>
      </div>

      <div class="integral-title">{{i18n.t('integral.label6')}}</div>
      <div class="desc">{{i18n.t('integral.desc')}}</div>
    </div>

    <Popup v-model="showMsg" class="msg-popup">
      <div class="msg-box">
        <div class="close" @click="cancel">
          <img :src="closeUrl" alt="">
        </div>
        <div class="content flex-column-center">
          <div class="success integral" v-if="exchargeSuccess">
            <img :src="`${integralUrl2}?${timeStr}`" alt="" >
          </div>
          <div class="msg" v-if="!exchargeSuccess">{{msg}}</div>
          <div class="msg" v-if="exchargeSuccess">
            {{i18n.t('integral.label7')}}<span class="yellow">{{msgCount}}</span>{{i18n.t('integral.label2')}}
          </div>
        </div>
      </div>
    </Popup>
   
    <div  class="preview-img">
      <img :src="integralUrl" alt="">
    </div>
  
	</div>
</template>
<script>
import topBar from '@/components/topBar'
import { Popup, Button} from 'vant'
import { giftList } from '@/api/index'
import { integralCheck, addIntegralLog  } from '@/api/setting'
import { userInfo  } from '@/api/user'
export default {
	components: {
    Popup,
    Button,
		topBar
  },
  data() {
		return {
      showMsg: false,
      exchargeSuccess: false,
      msgCount: 0,
      closeUrl: require('../../assets/imgs/icon-close.png'),
      integralUrl: require('../../assets/imgs/icon-integral2.png'),
      integralUrl2: require('../../assets/imgs/loading2.gif'),
      starUrl: require('../../assets/imgs/icon-star.png'),
      goodsList: [],
      okNum: 0,
      integralListBak: [
        {
          label: this.i18n.t('integral.step1'),
          icon: require('../../assets/imgs/icon-sign3.png'),
          count: 10,
          status: 3,
          key: 'sign'
        },
        {
          label: this.i18n.t('integral.step2'),
          icon: require('../../assets/imgs/icon-raffle3.png'),
          count: 10,
          status: 3,
          key: 'lottery'
        },
        { 
          label: this.i18n.t('integral.step3'),
          icon: require('../../assets/imgs/icon-order2.png'),
          count: 20,
          status: 3,
          key: 'order'
        },
        {
          label: this.i18n.t('integral.step4'),
          icon: require('../../assets/imgs/icon-share2.png'),
          count: 30,
          status: 3,
          key: 'invite'
        }
      ],
      integralList: [],
      okNum: 0,
      userIntergal: 0,
      timeStr: ''
		}
	},
  computed: {
    msg() {
      return this.i18n.t('index.errorMsg')
    },
  },
  mounted() {
    this.integralList = [].concat(this.integralListBak)
    this.init()
    this.initGift()
  },
	methods: {
    checkPage() {
      if (this.okNum == 3) {
        this.$store.state.showLoading = false
      }
    },
		init() {
      integralCheck().then(res => {
        if (res.code == 0) {
          let data = res.data
          this.integralListBak[0].status = data.Sign ? 2 : 3
          this.integralListBak[1].status = data.Lottery ? 2 : 3
          this.integralListBak[2].status = data.Order ? 2 : 3
          this.integralListBak[3].status = data.Invite ? 2 : 3
        }
        this.integralList = [].concat(this.integralListBak)
        this.okNum += 1
				this.checkPage()
      })

      userInfo().then(res => {
        this.okNum += 1
        this.userIntergal = res.data.UserAccount ? res.data.UserAccount.Integral : 0
      })
		},
    initGift() {
      giftList().then(res => {
        this.okNum += 1
        this.checkPage()
        this.goodsList = res.data.Items.slice(0, 6)
      })
    },
		back() {
			this.$router.go(-1)
		},
    toLog() {

    },
    toPath(path) {
      if (path) {
        this.$router.push({
          name: path
        })
      }
    },
    toExchange() {
      this.showMsg = true
      this.exchargeSuccess = false
    },
    cancel() {
      this.showMsg = false
    },
    submit(data) {
      if (data.status == 2) {
        let form = new FormData()
        form.append('Type', data.key)
        addIntegralLog(form).then(res => {
          if (res.code == 0) {
            this.getTimeStr()
            this.showMsg = true
            this.exchargeSuccess = true
            this.msgCount = data.count
            this.init()
          } else {
            this.$toast(res.msg)
          }
        })
      }
    },
    getTimeStr() {
      let timeStr =  new Date().getTime()
      this.timeStr = timeStr
    }
	}
}
</script>