<template>
  <div class="setting-container main">
    <div class="flex-center-between back ">
      <Icon name="arrow-left" size="20" @click="back"></Icon>
      <span>{{i18n.t('setting.title')}}</span>
      <img :src="kefuUrl" class="big" alt="" @click="toPath('kefu')">
    </div>
    <div class="top-main clear ">
      <div class="flex-center user-info" v-if="isLogin">
        <div>
          <img :src="avatar ? headerObj[avatar] : userUrl" alt="" >
        </div>
        <div class="flex-1 w-1">
          <div class="name">Hi,{{username}}</div>
          <div class="flex-center-start copy">
            <span>UID:{{userId}}</span>
            <img :src="levelUrl" alt="" v-show="false">
          </div>
        </div>
      </div>

      <div class="flex-center user-info top" v-if="!isLogin">
        <div>
          <img :src="userUrl" alt="">
        </div>
        <div class="flex-1 w-1">
          <div class="name">{{i18n.t('setting.label1')}}{{appName}}</div>
          <div class="flex-center-start copy">
            <span>{{i18n.t('setting.label2')}}</span>
          </div>
        </div>
      </div>

      <div class="share-box" v-if="isLogin">
        <Button @click="toPath('share')">{{i18n.t('mine.btn')}}</Button>
			  <div class="desc">Invite Friends</div>
      </div>     

      <div class="tab-box flex-center-between" v-if="!isLogin">
        <Button class="setting-btn yellow " @click="toPath('login')">{{i18n.t('setting.btn')}}</Button>
        <Button class="setting-btn gray" @click="toPath('register')">{{i18n.t('setting.register')}}</Button>
      </div>
    </div>

    <div class="menu-list">
      <div class="list flex-center" v-for="(item, i) in menuList" :key="i" :class="item.space ? 'space' : ''" @click="toPath(item.path)">
        <div class="icon">
          <img :src="item.icon" alt="">
        </div>
        <div class="flex-1 name">{{item.name}}</div>
        <div class="desc flex-center">
          <img :src="rightUrl" alt="">
        </div>
      </div>

    </div>

    <div class="bottom-btn" @click="logout" v-if="isLogin">
      <Button >{{i18n.t('setting.logout')}}</Button>
    </div>

  </div>
</template>
<script>
import { getToken, removeToken, getUserAvatar, getUser, getUserId, setUser, setUserId, setUserAvatar, setRechargeType } from '@/utils/auth'
import { Popup, Button, Icon } from 'vant'
import Clipboard from 'clipboard';
import { userInfo } from '@/api/user'
import "@/utils/sdk.js"
export default {
  components: {
    Popup,
    Button,
    Icon
  },
  data() {
    return {
      rightUrl: require('../../assets/imgs/icon-arrow-right-gray.png'),
      kefuUrl: require('../../assets/imgs/icon-kefu3.png'),
      userUrl: require('../../assets/imgs/header.png'),
      username: '',
      userId: '',
      showModal: false,
      showKefu: false,
      authIndex: 1,
      avatar: '',
      menuList: [
        {
          name: this.i18n.t('setting.menu1'),
          path: 'editPassword'
        },
        {
          name: this.i18n.t('setting.menu2'),
          path: 'editSafePassword'
        },
        {
          name: this.i18n.t('setting.menu3'),
          space: true,
          path: 'lang'
        },
        {
          name: this.i18n.t('setting.menu4'),
          path: 'help'
        },
        {
          name: this.i18n.t('setting.menu5'),
          path: 'about'
        },
      ],
      headerObj:{
        'header-1':  require('../../assets/imgs/header/header-1.png'),
        'header-2':  require('../../assets/imgs/header/header-2.png'),
        'header-3':  require('../../assets/imgs/header/header-3.png'),
        'header-4':  require('../../assets/imgs/header/header-4.png'),
        'header-5':  require('../../assets/imgs/header/header-5.png'),
        'header-6':  require('../../assets/imgs/header/header-6.png'),
        'header-7':  require('../../assets/imgs/header/header-7.png'),
        'header-8':  require('../../assets/imgs/header/header-8.png'),
        'header-9':  require('../../assets/imgs/header/header-9.png'),
        'header-10':  require('../../assets/imgs/header/header-10.png'),
        'header-11':  require('../../assets/imgs/header/header-11.png'),
        'header-12':  require('../../assets/imgs/header/header-12.png'),
        'header-13':  require('../../assets/imgs/header/header-13.png'),
        'header-14':  require('../../assets/imgs/header/header-14.png'),
      },
      levelObj:{
        'level-1':  require('../../assets/imgs/level-1.png'),
        'level-2':  require('../../assets/imgs/level-2.png'),
        'level-3':  require('../../assets/imgs/level-3.png'),
        'level-4':  require('../../assets/imgs/level-3.png'),
        'level-5':  require('../../assets/imgs/level-3.png'),
        'level-6':  require('../../assets/imgs/level-3.png'),
        'level-7':  require('../../assets/imgs/level-3.png'),
        'level-8':  require('../../assets/imgs/level-3.png'),
      },
      levelUrl: '',
      realNameStatus: '',
      showEnsureModal: false,
      kefuList: [],
      isLogin: false
    }
  },
  computed: {
    appName() {
      return this.$store.state.appName
    }
  },
  mounted() {
    if (getToken()) {
      this.init()
      this.username = getUser()
      this.userId = getUserId()
      this.avatar = `header-${getUserAvatar() || 1}`
      this.isLogin = true
    } else { //游客
      this.isLogin = false
      this.$store.state.showLoading = false
      this.avatar = ''
      this.menuList = this.menuList.slice(2, 5)
    }
    
  },
  methods: {
    init() {
      //用户信息
      userInfo().then(res => {
        this.$store.state.showLoading = false
        let data = res.data
        this.username = data.Email || data.Phone
        this.userId = data.ID
        this.levelUrl = this.levelObj[`level-${data.Leave.Leave}`]
        setUser(this.username)
        setUserId(this.userId)
        setUserAvatar(data.Avatar)
        
        this.avatar = `header-${data.Avatar || 1}`
        this.$store.state.shareCode = data.InviteCode
        //认证状态
      })
    },
    toPath(path) {
      if (path) {
        this.$router.push({
          name: path
        })
      }
      
    },
    cancel() {
      this.showModal = false
      this.showEnsureModal = false
      this.showKefu = false
    },
    back() {
      this.$router.go(-1)
    },
    logout() {
      removeToken()
      this.$router.push({
        name: 'login'
      })
    },
    copyData() {
      let data = this.userId
      const clipboard = new Clipboard('.copy-icon', {text: () => data});
      this.$store.state.successMsg = this.i18n.t('setting.success')
    },
  }
}
</script>