<template>
  <div class="other-page">
    <top-bar title="" class="border" @back="back"></top-bar>
    <iframe :src="pageUrl" class="iframe-main" frameborder="0"></iframe>
  </div>
</template>
<script>
import topBar from '@/components/topBar'
export default {
  components: {
    topBar
  },
  data() {
    return {
      pageUrl: ''
    }
  },
  mounted() {
    let url = this.$route.query.url
    this.pageUrl = url
  },
  methods: {
    back() {
      this.$router.go(-1)
    }
  }
}
</script>