<template>
	<div class="share-page page-top">
		<top-bar :title="i18n.t('share.title')" class="border"  @back="back" ></top-bar>

    <div class="top-info flex-center">
      <div class="step " >
        <div class="flex-column-center-start">
          <img :src="stepUrl1" alt="">
          <span class="label" :class="i18n.locale == 'zh-CN' || i18n.locale == 'zh-TW' ? '' : 'small'">{{i18n.t('share.label1')}}</span>
          <span class="flex-center number">1</span>
        </div>
      </div>

      <div class="arrow">
        <img :src="arrowUrl" alt="">
      </div>

      <div class="step " >
        <div class="flex-column-center-start">
          <img :src="stepUrl2" alt="">
          <span class="label" :class="i18n.locale == 'zh-CN' || i18n.locale == 'zh-TW' ? '' : 'small'">{{i18n.t('share.label2')}}</span>
          <span class="flex-center number">2</span>
        </div>
      </div>

      <div class="arrow">
        <img :src="arrowUrl" alt="">
      </div>

      <div class="step " >
        <div class="flex-column-center-start">
          <img :src="stepUrl3" alt="">
          <span class="label" :class="i18n.locale == 'zh-CN' || i18n.locale == 'zh-TW' ? '' : 'small'">{{i18n.t('share.label3')}}</span>
          <span class="flex-center number">3</span>
        </div>
      </div>
    </div>

    <div class="share-info">
      <div class="label flex-center-start">
        <img :src="codeUrl" alt="">
        {{i18n.t('share.label4')}}
      </div>

      <div class="flex-center-between">
        <div class="input">
          {{shareCode}}
        </div>
        <div class="copy-icon btn flex-center" @click="copyData(1)">
          <img :src="copyUrl" alt="">
        </div>
      </div>

      <div class="label flex-center-start">
        <img :src="linkUrl" alt="">
        {{i18n.t('share.label5')}}
      </div>

      <div class="flex-center-between">
        <div class="input">
          {{shareLink}}
        </div>
        <div class="copy-icon btn flex-center" @click="copyData(2)">
          <img :src="copyUrl" alt="">
        </div>
      </div>

      <div class="sub-title">{{i18n.t('share.label6')}}</div>

      <div class="flex-center-between rule-line">
        <div class="percent flex-center">{{oneLevelCommission}}</div>
        <div class="info">{{i18n.t('share.role1')}}{{oneLevelCommission}}</div>
      </div>
      <div class="flex-center-between rule-line">
        <div class="percent flex-center">{{twoLevelCommission}}</div>
        <div class="info">{{i18n.t('share.role2')}}{{twoLevelCommission}}</div>
      </div>
      <!-- <div class="flex-center-between rule-line">
        <div class="percent flex-center">{{threeLevelCommission}}%</div>
        <div class="info">{{i18n.t('share.role3')}}{{threeLevelCommission}}%</div>
      </div> -->
    </div>
	</div>
</template>
<script>
import Clipboard from 'clipboard';
import topBar from '@/components/topBar'
import { Popup, Button} from 'vant'
import { userInfo } from '@/api/user'
export default {
	components: {
    Popup,
    Button,
		topBar
  },
  data() {
		return {
      codeUrl: require('../../assets/imgs/icon-code.png'),
      linkUrl: require('../../assets/imgs/icon-link.png'),
      copyUrl: require('../../assets/imgs/icon-copy.png'),
      arrowUrl: require('../../assets/imgs/icon-step-arrow.png'),
      stepUrl1: require('../../assets/imgs/share-1.png'),
      stepUrl2: require('../../assets/imgs/share-2.png'),
      stepUrl3: require('../../assets/imgs/share-3.png'),
      shareCode: '',
      okNum: 0
		}
	},
  computed: {
    shareLink() {
      return this.$store.state.promoteUrl + '/#/register?code=' + this.shareCode
    },
    oneLevelCommission() {
      return this.$store.state.oneLevelCommission
    },
    twoLevelCommission() {
      return this.$store.state.twoLevelCommission
    },
    threeLevelCommission() {
      return this.$store.state.threeLevelCommission
    },
  },
  mounted() {
    this.init()
  },
	methods: {
    checkPage() {
      if (this.okNum == 1) {
        this.$store.state.showLoading = false
      }
    },
		init() {
      userInfo().then(res => {
        this.okNum += 1
        this.shareCode = res.data.InvitationCode
        this.checkPage()
      })
		},

		back() {
			this.$router.go(-1)
		},
    toLog() {

    },
    toPath(path) {
      if (path) {
        this.$router.push({
          name: path
        })
      }
    },
    copyData(type) {
      let data = type == 1 ? this.shareCode : this.shareLink
      const clipboard = new Clipboard('.copy-icon', {text: () => data});
      this.$toast(this.i18n.t('share.success'))
    },
	}
}
</script>