<template>
	<div class="withdraw-page page-top">
		<top-bar :title="i18n.t('bindCard.title')" class="border"  @back="back" ></top-bar>

    <template v-if="countryName == '日本'">
      <div class="default-form">
        <div class="form-label">{{i18n.t('bindCard.label7')}}</div>
        <div class="form-item">
          <input type="text" :placeholder="i18n.t('bindCard.placeholder7')" v-model="countryName" readonly @click="changeCountry"/>
        </div>
        <div class="form-label">銀行名</div>
        <div class="form-item">
          <input type="text" :placeholder="'銀行名を入力してください'" v-model="form.Company"  />
        </div>
        <div class="form-label">支店名</div>
        <div class="form-item">
          <input type="text" placeholder="支店名" v-model="form.RealName"/>
        </div>
        <div class="form-label">取引種類</div>
        <div class="form-item">
          <input type="text" placeholder="取引種類" v-model="form.Type"/>
        </div>
        <div class="form-label">普通囗座番号</div>
        <div class="form-item">
          <input type="text" placeholder="普通囗座番号" v-model="form.Branch"/>
        </div>

        <div class="form-label">口座名義</div>
        <div class="form-item">
          <input type="text" placeholder="口座名義" v-model="form.Card"/>
        </div>

        <div class="form-label">{{i18n.t('bindCard.label4')}}</div>
        <div class="form-item ">
          <input  :type="showPassword ? 'number' : 'password'" inputmode="decimal" :placeholder="i18n.t('bindCard.placeholder4')" v-model="form.password"/>
          <div class="adot" @click="changeShowPassword">
            <img :src="showPassword ? eyeUrl2 : eyeUrl" alt="" >
          </div>
        </div>

        <Button class="btn" @click="submit">{{i18n.t('bindCard.btn')}}</Button>

      </div>
    </template>
<!--    俄罗斯 -->
    <template v-else-if="countryName == 'Россия' || countryName == 'Қазақстан'">
      <div class="default-form">
        <div class="form-label">{{i18n.t('bindCard.label7')}}</div>
        <div class="form-item">
          <input type="text" :placeholder="i18n.t('bindCard.placeholder7')" v-model="countryName" readonly @click="changeCountry"/>
        </div>
        <div class="form-label">{{i18n.t('bindCard.label1')}}</div>
        <div class="form-item">
          <input type="text" :placeholder="i18n.t('bindCard.placeholder1')" v-model="form.Company" readonly @click="changeType"  />
        </div>

        <div class="form-label">{{i18n.t('bindCard.label2')}}</div>
        <div class="form-item">
          <input type="text" :placeholder="i18n.t('bindCard.placeholder2')" v-model="form.RealName"/>
        </div>

        <div class="form-label">{{i18n.t('bindCard.label3')}}</div>
        <div class="form-item">
          <input type="tel" :placeholder="i18n.t('bindCard.placeholder3')" v-model="form.Card"/>
        </div>

        <div class="form-label">{{i18n.t('bindCard.label4')}}</div>
        <div class="form-item ">
          <input  :type="showPassword ? 'number' : 'password'" inputmode="decimal" :placeholder="i18n.t('bindCard.placeholder4')" v-model="form.password"/>
          <div class="adot" @click="changeShowPassword">
            <img :src="showPassword ? eyeUrl2 : eyeUrl" alt="" >
          </div>
        </div>

        <Button class="btn" @click="submit">{{i18n.t('bindCard.btn')}}</Button>

      </div>
    </template>
    <template v-else>
      <div class="default-form">
        <div class="form-label">{{i18n.t('bindCard.label7')}}</div>
        <div class="form-item">
          <input type="text" :placeholder="i18n.t('bindCard.placeholder7')" v-model="countryName" readonly @click="changeCountry"/>
        </div>
        <div class="form-label">{{i18n.t('bindCard.label1')}}</div>
        <div class="form-item">
          <input type="text" :placeholder="i18n.t('bindCard.placeholder1')" v-model="form.Company"  />
        </div>

        <div class="form-label">{{i18n.t('bindCard.label2')}}</div>
        <div class="form-item">
          <input type="text" :placeholder="i18n.t('bindCard.placeholder2')" v-model="form.RealName"/>
        </div>

        <div class="form-label">{{i18n.t('bindCard.label3')}}</div>
        <div class="form-item">
          <input type="tel" :placeholder="i18n.t('bindCard.placeholder3')" v-model="form.Card"/>
        </div>

        <div class="form-label">{{i18n.t('bindCard.label4')}}</div>
        <div class="form-item ">
          <input  :type="showPassword ? 'number' : 'password'" inputmode="decimal" :placeholder="i18n.t('bindCard.placeholder4')" v-model="form.password"/>
          <div class="adot" @click="changeShowPassword">
            <img :src="showPassword ? eyeUrl2 : eyeUrl" alt="" >
          </div>
        </div>

        <Button class="btn" @click="submit">{{i18n.t('bindCard.btn')}}</Button>
      </div>
    </template>
    <Popup v-model="showPopup" position="bottom">
      <Picker
          show-toolbar
          value-key="text"
          :columns="columns"
          @confirm="chooseType"
          :confirm-button-text="i18n.t('common.ensure')"
          :cancel-button-text="i18n.t('common.cancel')"
          @cancel="cancel"
      ></Picker>
    </Popup>
    <Popup v-model="showCountry" position="bottom">
      <Picker
          show-toolbar
          value-key="text"
          :columns="countryList"
          @confirm="chooseCountry"
          :confirm-button-text="i18n.t('common.ensure')"
          :cancel-button-text="i18n.t('common.cancel')"
          @cancel="cancel"
      ></Picker>
    </Popup>
	</div>
</template>
<script>
import topBar from '@/components/topBar'
import {Popup, Button, Tabs, Tab, Picker} from 'vant'
import { addBank  } from '@/api/user'
export default {
	components: {
    Picker,
    Popup,
    Button,
		topBar,
		Tabs,
		Tab
  },
  data() {
		return {
      bankUrl: require('../../assets/imgs/bank.png'),
      eyeUrl: require('../../assets/imgs/icon-eye.png'),
      eyeUrl2: require('../../assets/imgs/icon-eye2.png'),
      showPopup:false,
      protocolName:'',
      countryName: '',
      columns: [],

      columnsBak: [
        [
          { key: "하나은행", text: "하나은행" },
          { key: "기업은행", text: "기업은행" },
          { key: "농협은행", text: "농협은행" },
          { key: "국민은행", text: "국민은행" },
          { key: "신한은행", text: "신한은행" },
          { key: "우리은행", text: "우리은행" },
          { key: "기업은행", text: "기업은행" },
          { key: "수협은행", text: "수협은행" },
          { key: "한국수출입은행", text: "한국수출입은행" },
          { key: "경남은행", text: "경남은행" },
        ],
        [
          { key: '横浜銀行', text: '横浜銀行'},
          { key: 'ヨコハマギンゴ', text: 'ヨコハマギンゴ'},
          { key: 'みんな銀行', text: 'みんな銀行'},
          { key: 'みんなギンゴ', text: 'みんなギンゴ'},
          { key: '三井住友銀行', text: '三井住友銀行'},
          { key: 'ミツイスミトモギンゴ', text: 'ミツイスミトモギンゴ'},
          { key: 'GMOあおぞらネット銀行', text: 'GMOあおぞらネット銀行'},
          { key: 'ジーエムオーアオゾラネツト', text: 'ジーエムオーアオゾラネツト'},
          { key: 'みずほ銀行', text: 'みずほ銀行' },
          { key: '三菱東京UFJ銀行', text: '三菱東京UFJ銀行' },
          { key: '三井住友銀行', text: '三井住友銀行' },
          { key: 'りそな銀行', text: 'りそな銀行' },
          { key: '埼玉りそな銀行', text: '埼玉りそな銀行' },
          { key: '北海道銀行', text: '北海道銀行' },
          { key: '青森銀行', text: '青森銀行' },
          { key: 'みちのく銀行', text: 'みちのく銀行' },
          { key: '秋田銀行', text: '秋田銀行' },
          { key: '北都銀行', text: '北都銀行' },
          { key: '荘内銀行', text: '荘内銀行' },
          { key: '山形銀行', text: '山形銀行' },
          { key: '岩手銀行', text: '岩手銀行' },
          { key: '東北銀行', text: '東北銀行' },
          { key: '七十七銀行', text: '七十七銀行' },
          { key: '東邦銀行', text: '東邦銀行' },
          { key: '群馬銀行', text: '群馬銀行' },
          { key: '足利銀行', text: '足利銀行' },
          { key: '常陽銀行', text: '常陽銀行' },
          { key: '筑波銀行', text: '筑波銀行' },
          { key: '武蔵野銀行', text: '武蔵野銀行' },
          { key: '千葉銀行', text: '千葉銀行' },
          { key: '千葉興業銀行', text: '千葉興業銀行' },
          { key: '東京都民銀行', text: '東京都民銀行' },
          { key: '横浜銀行', text: '横浜銀行' },
          { key: '第四銀行', text: '第四銀行' },
          { key: '北越銀行', text: '北越銀行' },
          { key: '山梨中央銀行', text: '山梨中央銀行' },
          { key: '八十二銀行', text: '八十二銀行' },
          { key: '北陸銀行', text: '北陸銀行' },
          { key: '富山銀行', text: '富山銀行' },
          { key: '北國銀行', text: '北國銀行' },
          { key: '福井銀行', text: '福井銀行' },
          { key: '静岡銀行', text: '静岡銀行' },
          { key: 'スルガ銀行', text: 'スルガ銀行' },
          { key: '清水銀行', text: '清水銀行' },
          { key: '大垣共立銀行', text: '大垣共立銀行' },
          { key: '十六銀行', text: '十六銀行' },
          { key: '三重銀行', text: '三重銀行' },
          { key: '百五銀行', text: '百五銀行' },
          { key: '滋賀銀行', text: '滋賀銀行' },
          { key: '京都銀行', text: '京都銀行' },
          { key: '近畿大阪銀行', text: '近畿大阪銀行' },
          { key: '池田泉州銀行', text: '池田泉州銀行' },
          { key: '南都銀行', text: '南都銀行' },
          { key: '紀陽銀行', text: '紀陽銀行' },
          { key: '但馬銀行', text: '但馬銀行' },
          { key: '鳥取銀行', text: '鳥取銀行' },
          { key: '山陰合同銀行', text: '山陰合同銀行' },
          { key: '中国銀行', text: '中国銀行' },
          { key: '広島銀行', text: '広島銀行' },
          { key: '山口銀行', text: '山口銀行' },
          { key: '阿波銀行', text: '阿波銀行' },
          { key: '百十四銀行', text: '百十四銀行' },
          { key: '伊予銀行', text: '伊予銀行' },
          { key: '四国銀行', text: '四国銀行' },
          { key: '福岡銀行', text: '福岡銀行' },
          { key: '筑邦銀行', text: '筑邦銀行' },
          { key: '佐賀銀行', text: '佐賀銀行' },
          { key: '十八銀行', text: '十八銀行' },
          { key: '親和銀行', text: '親和銀行' },
          { key: '肥後銀行', text: '肥後銀行' },
          { key: '大分銀行', text: '大分銀行' },
          { key: '宮崎銀行', text: '宮崎銀行' },
          { key: '鹿児島銀行', text: '鹿児島銀行' },
          { key: '琉球銀行', text: '琉球銀行' },
          { key: '沖縄銀行', text: '沖縄銀行' },
          { key: '西日本シティ銀行', text: '西日本シティ銀行' },
          { key: '北九州銀行', text: '北九州銀行' },
          { key: 'ゆうちょ銀行', text: 'ゆうちょ銀行' }
        ],
        [
          { key: 'State Bank of Vietnam，SBV', text: 'State Bank of Vietnam，SBV'},
          { key: 'Vietnam Bank for Agriculture and Rural Development，Agribank', text: 'Vietnam Bank for Agriculture and Rural Development，Agribank'},
          { key: 'Vietnam Joint Stock Commercial Bank for Industry and Trade，VietinBank', text: 'Vietnam Joint Stock Commercial Bank for Industry and Trade，VietinBank'},
          { key: 'Vietnam Bank for Investment and Development，BIDV', text: 'Vietnam Bank for Investment and Development，BIDV'},
          { key: 'Vietnam Joint Stock Commercial Bank for Foreign Trade，Vietcombank', text: 'Vietnam Joint Stock Commercial Bank for Foreign Trade，Vietcombank'},
          { key: 'National Citizen Bank，NCB', text: 'National Citizen Bank，NCB'},
          { key: 'Vietnam Joint Stock Commercial Bank for Industry and Trade，Vietcombank', text: 'Vietnam Joint Stock Commercial Bank for Industry and Trade，Vietcombank'},
          { key: 'Development Bank of Vietnam，DBV', text: 'Development Bank of Vietnam，DBV'},
        ],
        [
          { key: 'ธนาคารกรุงเทพ', text: 'ธนาคารกรุงเทพ'},
          { key: 'ธนาคารกสิกรไทย', text: 'ธนาคารกสิกรไทย'},
          { key: 'ธนาคารไทยพาณิชย', text: 'ธนาคารไทยพาณิชย'},
          { key: 'ธนาคารยูโอบี', text: 'ธนาคารยูโอบี'},
          { key: 'ธนาคารกรุงศรีอยุธยา', text: 'ธนาคารกรุงศรีอยุธยา'},
          { key: 'ธนาคารกรุงไทย', text: 'ธนาคารกรุงไทย'},
          { key: 'ธนาคารทหารไทย', text: 'ธนาคารทหารไทย'},
          { key: 'ธนาคารเกียรตินาคิน', text: 'ธนาคารเกียรตินาคิน'},
          { key: 'ธนาคารธนาชาต', text: 'ธนาคารธนาชาต'},
          { key: 'ธนาคารแห่งประเทศไทย', text: 'ธนาคารแห่งประเทศไทย'},
          { key: 'ธนาคารอาคารสงเคราะห', text: 'ธนาคารอาคารสงเคราะห'},
          { key: 'ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร', text: 'ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร'},
          { key: 'ธนาคารทิสโก', text: 'ธนาคารทิสโก'},
          { key: 'ธนาคารออมสิน', text: 'ธนาคารออมสิน'},
        ],
        [
          { key: 'JPMorgan Chase & Co.', text: 'JPMorgan Chase & Co.'},
          { key: 'Bank of America', text: 'Bank of America'},
          { key: 'Wells Fargo', text: 'Wells Fargo'},
          { key: 'CitiBank', text: 'CitiBank'},
          { key: 'US Bank', text: 'US Bank'},
          { key: 'PNC Bank', text: 'PNC Bank'},
          { key: 'Capital One', text: 'Capital One'},
        ],
        [],
        [],
        [],
        [],
        [],
        [],
        //  俄罗斯常见银行
        [
          { key: 'Сбербанк', text: 'Сбербанк'},
          { key: 'ВТБ', text: 'ВТБ'},
          { key: 'Газпромбанк', text: 'Газпромбанк'},
          { key: 'Альфа-Банк', text: 'Альфа-Банк'},
          { key: 'Россельхозбанк', text: 'Россельхозбанк'},
          { key: 'Райффайзенбанк', text: 'Райффайзенбанк'},
          { key: 'Росбанк', text: 'Росбанк'},
          { key: 'Совкомбанк', text: 'Совкомбанк'},
          { key: 'Российский капитал', text: 'Российский капитал'},
          { key: 'Российский стандарт', text: 'Российский стандарт'},
          { key: 'Тинькофф Банк', text: 'Тинькофф Банк'},
          { key: 'ЮниКредит Банк', text: 'ЮниКредит Банк'},
          { key: 'Тинькофф Банк', text: 'Тинькофф Банк'},
          { key: 'ЮниКредит Банк', text: 'ЮниКредит Банк'},
          { key: 'Россельхозбанк', text: 'Россельхозбанк'},
          { key: 'Райффайзенбанк', text: 'Райффайзенбанк'},
          { key: 'Русский Стандарт', text: 'Русский Стандарт'},
          { key: 'Ак Барс Банк', text: 'Ак Барс Банк'},
        ],
        //   哈萨克斯坦常见银行
        [
          { key: 'Halyk', text: 'Halyk'},
          { key: 'ATFBank', text: 'ATFBank'},
          { key: 'ForteBank', text: 'ForteBank'},
          { key: 'Bank RBK', text: 'Bank RBK'},
          { key: 'Kazkommertsbank', text: 'Kazkommertsbank'},
          { key: 'Eurasian Bank', text: 'Eurasian Bank'},
          { key: 'Bank CenterCredit', text: 'Bank CenterCredit'},
          { key: 'Sberbank Kazakhstan ', text: 'Sberbank Kazakhstan '},
          { key: 'Kaspi Bank', text: 'Kaspi Bank'},

        ],
      ],
      form: {
        RealName: '',
        Company: '',
        Card: '',
        password: '',
        Type: '',
        Branch: '',
        Country: ''
      },
      showPassword: false,
      showCountry: false,
      countryList: [
        {
          key: 0, text: '한국'
        },
        {
          key: 1, text: '日本'
        },
        {
          key: 2, text: 'Việt Nam'
        },
        {
          key: 3, text: 'ประเทศไทย'
        },
        {
          key: 4, text: 'USA'
        },
        //     印尼 马来西亚 新加坡 香港  台湾  印度
        {
          key: 5, text: 'Republik Indonesia'
        },
        {
          key: 6, text: 'Malaysia'
        },
        {
          key: 7, text: 'Singapore'
        },
        {
          key: 8, text: '香港'
        },
        {
          key: 9, text: '台灣'
        },
        {
          key: 10, text: 'भारत गणराज्य'
        },
          // 俄罗斯
        {
          key: 11, text: 'Россия'
        },
          // 哈萨克斯坦
        {
          key: 12, text: 'Қазақстан'
        },
      ],

		}
	},
  mounted() {
  },
	methods: {
    chooseCountry(data) {
      this.form.Country = data.key
      this.countryName = data.text
      this.showCountry = false
      this.form.Company = ''
      this.protocolName = ''
      console.log("this.columnsBak:",this.columnsBak)
      console.log("data.key:",data.key)
      this.columns = this.columnsBak[data.key]
    },
		back() {
			this.$router.go(-1)
		},
    changeCountry() {
      this.showCountry = true
    },
    toPath(path) {
      if (path) {
        this.$router.push({
          name: path
        })
      }
    },
    chooseType(data) {
      this.form.Company = data.key
      this.protocolName = data.text
      this.showPopup = false
    },
    changeShowPassword() {
      this.showPassword = !this.showPassword
    },
    cancel() {
      this.showPopup = false
    },
    changeType() {
      this.showPopup = true
    },
    submit() {
      if (!this.form.Company) {
        this.$toast(this.i18n.t('bindCard.placeholder1'))
        return
      }
      if (!this.form.RealName) {
        this.$toast(this.i18n.t('bindCard.placeholder2'))
        return
      }
      if (!this.form.Card) {
        this.$toast(this.i18n.t('bindCard.placeholder3'))
        return
      }
      if (!this.form.password) {
        this.$toast(this.i18n.t('bindCard.placeholder4'))
        return
      }
      let form = new FormData()
      form.append('Company', this.form.Company)
      form.append('RealName', this.form.RealName)
      form.append('Card', this.form.Card)
      form.append('Country', this.countryName)
      form.append('Type', this.form.Type)
      form.append('Branch', this.form.Branch)
      form.append('FundPassword', this.form.password)
      addBank(form).then(res => {
        if (res.code == 0) {
          this.$toast(this.i18n.t('bindCard.success'))
          setTimeout(() => {
            this.back()
          }, 1000);
        } else {
          this.$toast(res.msg)
        }
      })
    }
	}
}
</script>