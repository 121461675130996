<template>
	<div class="address-page page-top">
		<top-bar :title="i18n.t('address.title')" class="border"  @back="back"></top-bar>

    <div class="address-list">
      <div class="list" v-for="(item, i) in addressList" :key="i">
        <div class="flex-start">
          <div>
            <img :src="addressUrl2" alt="" v-if="item.IsSelected == 1">
            <img :src="addressUrl" alt="" v-else>
          </div>
          <div class="flex-1 w-1">
            <div class="name">
              <span>{{item.FullName}}</span>
              <span class="phone">{{item.Phone}}</span>
            </div>
            <div class="address">{{item.ZipCode}} {{item.Address}} {{item.City}}</div>
          </div>
        </div>
        <div class="flex-center-between bottom-btn">
          <div class="btn" :class="item.IsSelected == 1 ? '' : 'active'" @click="chooseAddress(item)">{{i18n.t('address.label1')}}</div>
          <div class="del">{{i18n.t('address.label2')}}</div>
        </div>
      </div>
    </div>

    <div class="empty flex-column-center" v-if="addressList.length == 0 && isInit">
      <img :src="emptyUrl" alt="">
      <span class="desc">{{i18n.t('address.empty')}}</span>
    </div>

    <div class="page-bottom-btn">
      <Button class="btn" @click="toPath('bindAddress')">{{i18n.t('address.btn')}}</Button>
    </div>

	</div>
</template>
<script>
import topBar from '@/components/topBar'
import { Popup, Button, Tabs, Tab, Form } from 'vant'
import { getAddressList, addressSelect  } from '@/api/setting'
export default {
	components: {
    Popup,
    Button,
		topBar,
		Tabs,
		Tab
  },
  data() {
		return {
      addressUrl: require('../../assets/imgs/icon-address3.png'),
      addressUrl2: require('../../assets/imgs/icon-address4.png'),
			emptyUrl: require('../../assets/imgs/empty.png'),
      addressList: [],
      isInit: false
		}
	},
  mounted() {
    this.init()
  },
	methods: {
		init() {
      getAddressList().then(res => {
        this.isInit = true
        this.addressList = res.data.Items
        this.$store.state.showLoading = false
      })
		},
		
		back() {
			this.$router.go(-1)
		},
    toLog() {

    },
    toPath(path) {
      if (path) {
        this.$router.push({
          name: path
        })
      }
    },
    chooseAddress(data) {
      if (data.IsSelected == 1) {
        return
      }
      let form = new FormData()
      form.append('ID', data.ID)
      addressSelect(form).then(res => {
        if (res.code == 0) {
          this.$toast(this.i18n.t('address.success'))
          this.init()
        } else {
          this.$toast(res.msg)
        }
      })
    }
	}
}
</script>