<template>
  <div class="team-page home-container fixed">
		<div class="flex-center top fixed border">
			{{i18n.t('team.title')}}
		</div>

		<div class="team-info flex">
			<div class="flex-3">
				<div class="label">{{i18n.t('team.label1')}}</div>
				<div class="count" v-if="$store.state.payType == 'THB'">
					{{teamData.RebateCommission.toFixed(2)}}
					<span class="small">{{ $store.state.payType }}</span>
				</div>
        <div class="count" v-else>
          <span class="small">{{ $store.state.payType }}</span>
          {{teamData.RebateCommission.toFixed(2)}}
        </div>
			</div>
			<div class="flex-3">
				<div class="label">{{i18n.t('team.label2')}}</div>
				<div class="count">{{teamData.TotalUserCount}}</div>
			</div>
		</div>

		<div class="team-level flex" v-show="false">
			<div class="flex-1 flex-column-center box">
				<div class="flex-center label">
					<span>{{i18n.t('team.label3')}}</span>
					<img :src="levelUrl1" alt="">
				</div>
				<div class="count">{{teamData.FirstUserCount}}</div>
			</div>
			<div class="flex-1 flex-column-center box">
				<div class="flex-center label">
					<span>{{i18n.t('team.label4')}}</span>
					<img :src="levelUrl2" alt="">
				</div>
				<div class="count">{{teamData.SecondUserCount}}</div>
			</div>
			<div class="flex-1 flex-column-center box">
				<div class="flex-center label">
					<span>{{i18n.t('team.label5')}}</span>
					<img :src="levelUrl3" alt="">
				</div>
				<div class="count">{{teamData.ThirdUserCount}}</div>
			</div>
		</div>

    <div class="team-tab-box flex-center-between">
      <div class="tab" :class="[i == 1 ? 'right' : '', i == 2 ? 'right' : '', tabIndex == i ? 'active' : '', i18n.locale == 'zh-CN' || i18n.locale == 'zh-TW' ? '' : 'small']" v-for="(item, i) in tabList" :key="i" @click="changeTab(i)">
        {{item.name}}
      </div>
    </div>


		<div class="rank-table big " style="padding: 0 0" :class="isInit && dataList.length == 0 ? 'empty' : 'clear'" >
			<div class="th-line flex" :class="i18n.locale == 'vi' || i18n.locale == 'id' ? 'small' : ''" >
				<div class="th flex-2 center">{{i18n.t('team.th1')}}</div>
				<div class="th flex-3 text-center center">{{i18n.t('team.th2')}}</div>
				<div class="th flex-3 text-right center">{{i18n.t('team.th3')}}</div>
				<div class="th flex-3 text-right center pr-10">{{i18n.t('team.th4')}}</div>
<!--				<div class="th flex-1 center"></div>-->
			</div>
			<List
				v-if="dataList.length > 0"
				v-model="loading"
				:finished="finished"
				:finished-text="isInit && dataList.length == 0 ? '' : i18n.t('common.noMore')">
				<div class="table-body">
					<div class="td-line flex" v-for="(item, i) in dataList" :key="i" @click="toDetail(item)">
						<div class="td flex-2 flex-center-start ">
							<div>{{resetId(item.UId)}}</div>
						</div>
						<div class="td flex-3 text-center center">{{parseFloat(item.OrderMoney).toFixed(2)}}</div>
						<div class="td flex-3 text-right center">{{parseFloat(item.OrderCommission).toFixed(2)}}</div>
						<div class="td flex-3 text-right center pr-10">
							{{getMyCommission(item.OrderCommission)}}
						</div>
<!--						<div class="td flex-1 flex-center-end pr-5">-->
<!--							<img :src="rightUrl" alt="">-->
<!--						</div>-->
					</div>
				</div>

			</List>
			<div class="empty-box" v-if="isInit && dataList.length == 0">
				<img :src="emptyUrl" alt="">
				<span>{{i18n.t('common.empty')}}</span>
			</div>
		</div>
  </div>
</template>
<script>
import { teamReport } from '@/api/user'
import {  List} from 'vant';
import { getToken } from '@/utils/auth'
export default {
  components: {
    List
  },
  data() {
    return {
      tabIndex: 0,
      rightUrl: require('../../assets/imgs/icon-arrow-right.png'),
      emptyUrl: require('../../assets/imgs/empty.png'),
      levelUrl1: require('../../assets/imgs/level.png'),
      levelUrl2: require('../../assets/imgs/level2.png'),
      levelUrl3: require('../../assets/imgs/level3.png'),
      dataList: [],
      isInit: false,
      loading: false,
      finished: false,
      page: {
        page: 1,
        limit: 100,
      },
      totalPage: 1,
      teamData: {
        TotalCommission: 0,
        TotalUserCount: 0,
        FirstUserCount: 0,
        SecondUserCount: 0,
        ThirdUserCount: 0,
        RebateCommission: 0,
      },
      tabList: [
        {
          key: '1',
          name: this.i18n.t('team.label3')
        },
        {
          key: '2',
          name: this.i18n.t('team.label4')
        },
        // {
        //   key: '3',
        //   name: this.i18n.t('team.label5')
        // },
      ],
      okNum: 0
    }
  },
  computed: {
    oneLevelCommission() {
      return this.$store.state.oneLevelCommission
    },
    twoLevelCommission() {
      return this.$store.state.twoLevelCommission
    },
    threeLevelCommission() {
      return this.$store.state.threeLevelCommission
    },
  },
  mounted() {
    this.initReport()

  },
  methods: {
    checkPage() {
      if (this.okNum == 1) {
        this.$store.state.showLoading = false
      }
    },
    initReport() {
      teamReport().then(res => {
        this.okNum += 1
				this.checkPage()
        this.teamData = res.data
        this.dataList = this.teamData.FirstReport || []
        this.finished = true
        this.isInit = true
      })
    },
    getPercent(data) {
      let total = parseFloat(data.WorkMoney)
      let income = parseFloat(data.Income)
      if (total && income) {
        let percent = (income / total * 100).toFixed(1)
        return percent + '%'
      } else {
        return '- -'
      }
    },
    toDetail(data) {
      this.$router.push({
        name: 'orderList',
        query: {
          date: data.CreatedAt.substring(0, 10)
        }
      })
    },
		resetId(id) {
      id += ''
      if (id.length > 4) {
        return id.substring(0, 2) + new Array(id.length - 3).join('*') + id.substring(id.length - 2)
      } else {
        return id
      }
    },
    changeTab(i) {
      if (this.tabIndex != i) {
        this.tabIndex = i
        let key = i == 0 ? 'FirstReport' : (i == 1 ? 'SecondReport' : 'ThirdReport')
        this.dataList = this.teamData[key] || []
      }
    },
    getMyCommission(val) {
      // let percent = this.tabIndex == 0 ? this.oneLevelCommission : (this.tabIndex = 1 ? this.twoLevelCommission : this.threeLevelCommission)
      let percent = 0
      switch (this.tabIndex) {
        case 0:
          percent = this.oneLevelCommission
          break;
        case 1:
          percent = this.twoLevelCommission
          break;
        case 2:
          percent = this.threeLevelCommission
          break;
      }
      console.log("percent")
      console.log(percent)
      console.log(this.tabIndex)
      return parseFloat(parseFloat(percent) * val / 1).toFixed(2)
    }
  }
}
</script>