<template>
	<div class="debt-page ">
    <top-bar :title="i18n.t('integralLog.title')"   @back="back"></top-bar>

    <div class="list-main">
      <List
        v-if="dataList.length > 0"
        v-model="loading"
        :finished="finished"
        :finished-text="i18n.t('common.noMore')"
        @load="onLoad">
        <div class="list " v-for="(item, i) in dataList" :key="i"> 
          <div class="flex-center-between label">
            <div>{{i18n.t('integralLog.label1')}}</div>
            <div>{{formatTime(item.CreatedAt)}}</div>
          </div>
          <div class="flex-center-between number">
            <div>{{item.Integral}}</div>
            <div>{{i18n.t('integralLog.label2')}}</div>
          </div>
        </div>
      </List>

      <div class="empty flex-column-center" v-if="dataList.length == 0 && isInit">
        <img :src="emptyUrl" alt="">
        <span class="desc">{{i18n.t('common.empty')}}</span>
      </div>
    </div>
   
	</div>
</template>
<script>
import {IntegralRecordLists, raffleLog} from '@/api/index'
import topBar from '@/components/topBar'
import { List } from 'vant'
import {formatTime} from "../../utils/common";
export default {
  components: {
    topBar,
    List
  },
  data() {
		return {
      form: {
        bit: '',
        type: '',
        account: ''
      },
      page: {
        page: 1,
        limit: 20
      },
      isInit: false,
      loading: false,
      finished: false,
      totalPage: 1,
			emptyUrl: require('../../assets/imgs/empty.png'),
      dataList: [],
    
		}
	},
  mounted() {
    this.isInit = true
    this.finished = true
    this.init()
  },
	methods: {
    formatTime,
    onLoad() {
      this.page.page += 1
      this.init(true)
    },
    init(isAdd) {
      let form = new FormData()
      form.append('page', this.page.page)
      form.append('limit', this.page.limit)
      IntegralRecordLists(form).then(res => {
        if (res.data.Items) {
          if (isAdd) {
            this.dataList = this.dataList.concat(res.data.Items)
          } else {
            this.dataList = res.data.Items
          }
          this.totalPage = res.data.Pagination ? res.data.Pagination.totalPage : 1
          if (this.totalPage <= this.page.page) {
             this.finished = true;
          }
        }
        this.isInit = true
        this.loading = false
        this.$store.state.showLoading = false
      })
    },
		back() {
			this.$router.go(-1)
		},
    
    toDetail(data) {
      sessionStorage.setItem('logData', JSON.stringify(data))
      this.$router.push({
        name: 'raffleLogDetail'
      })
    }
	}
}
</script>