<template>
  <div id="app">
    <transition :name="transitionName">
      <router-view></router-view>
    </transition>

    <div class="safe-left" id="safeLeft"></div>
    <div class="safe-right" id="safeRight"></div>

    <div class="loading-page flex-column-center" v-show="showLoading">
      <img :src="loadingUrl" alt="">
      <span>{{i18n.t('common.loading')}}</span>
    </div>

  </div>
</template>
<script>
import { Popup, Button} from 'vant'
import { configList, userInfo,manifest } from '@/api/user'
import loadJs from "@/assets/loadjs"
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import { getDefaultLanguage } from '@/utils/common'
import {kefuList} from "@/api/setting";
import {getBannerList} from  '@/api/index';
export default {
  components: {
    Popup,
    Button
  },
  data() {
    return {
      // successUrl: require('./assets/imgs/modal-success.png'),
      // errorUrl: require('./assets/imgs/modal-info.png'),
      // warningUrl: require('./assets/imgs/modal-info.png'),
      // bgUrl: require('./assets/imgs/modal-bg.png'),
      loadingUrl: require('./assets/imgs/loading.gif'),
      transitionName: '',
      hideKefu: false,
      message: '',
      showMsg: false,
      iconType: '',
    };
  },
  computed: {
    successMsg() {
      return this.$store.state.successMsg
    },
    errorMsg() {
      return this.$store.state.errorMsg
    },
    warningMsg() {
      return this.$store.state.warningMsg
    },
    showLoading() {
      return this.$store.state.showLoading
    },
  },
  watch: {
    $route(to, from) {
      if (to.meta.index < from.meta.index) {
        this.transitionName = 'slide-right'
      } else if (to.meta.index > from.meta.index) {
        this.transitionName = 'slide-left'
      }
      let name = this.$route.name
      if (name == 'download') {
        this.hideKefu = true
      }
    },
    successMsg: {
      deep: true,
      handler(val) {
        if (val) {
          this.showMsgModal('success')
        }
      }
    },
    warningMsg: {
      deep: true,
      handler(val) {
        if (val) {
          this.showMsgModal('warning')
        }
      }
    },
    errorMsg: {
      deep: true,
      handler(val) {
        if (val) {
          this.showMsgModal('error')
        } else {
          this.cancel()
        }
      }
    },
  },
  mounted() {
    this.initManifest()
    this.init()
    this.getBanner()
    this.runMeiqiaScript()
    let locale = localStorage.getItem('locale')
    const defaultLanguage = getDefaultLanguage()
    this.i18n.locale = locale ? locale : defaultLanguage


    //左右滑
    this.resetMover()

    //计算字体缩放比例
    let screenWidth =  document.getElementById('app').offsetWidth
    if (screenWidth > 540) {
      screenWidth = 540
    }
    this.$store.state.screenScale = screenWidth / 375

    let leftDom = document.getElementById('safeLeft')
    let rightDom = document.getElementById('safeRight')
    if (leftDom) {
      leftDom.addEventListener('touchstart', (e) => {
        e.preventDefault()
      })
      leftDom.addEventListener('touchmove', (e) => {
        e.preventDefault()
      })
    }
    if (rightDom) {
      rightDom.addEventListener('touchstart', (e) => {
        e.preventDefault()
      })
      rightDom.addEventListener('touchmove', (e) => {
        e.preventDefault()
      })
    }
  },
  methods: {
    getBanner(){
      getBannerList().then(res => {
        this.bannerList = res.data.Items
        // 存储到localStorage
        localStorage.setItem('bannerList', JSON.stringify(res.data.Items))
      })
    },
    preloadImagesWithLinkTag(imagesArray){
      imagesArray.forEach(imageSrc => {
        const link = document.createElement('link'); // 创建一个新的 link 元素
        link.rel = 'preload'; // 设置 rel 属性为 preload
        link.as = 'image'; // 指定预加载的内容类型为图片
        link.href = imageSrc; // 设置图片的源地址
        document.head.appendChild(link); // 将 link 元素添加到页面的 head 部分
      });
    },
    initManifest(){
      manifest().then(res => {
        // console.log("manifest",res.preload_images)
        this.preloadImagesWithLinkTag(res.preload_images)
      })
    },
    runMeiqiaScript() {
      // if(this.isRunMeiqiaScript) {
      //   return
      // }
      // this.isRunMeiqiaScript = true
      //客服
      kefuList().then(res => {
        let that = this
        // console.log(res.data.Items)
        // this.kefuList = res.data.Items
        for(var i in res.data.Items) {
          let data = res.data.Items[i]
          if(data.Type === 'meiqia') {
            // console.log(data)
            // this.kefuUrl = data.Link + '&language=en&metadata={"ID":"' +window.localStorage.WebUserId+ '","email":"'+  window.localStorage.WebUserName+'"}'
            // this.runMeiqiaScript()
            const match = data.Link.match(/eid=([^&]+)/);
            const eidValue = match ? match[1] : null;
            this.loadScript()
            this.initMeiQia(eidValue)
          }
        }

      })
    },
    loadScript(){
      const script = document.createElement('script');
      script.async = true;
      script.charset = 'UTF-8';
      script.src = 'https://static.meiqia.com/dist/meiqia.js';
      document.head.appendChild(script);
      window._MEIQIA = window._MEIQIA || function() {
        (window._MEIQIA.a = window._MEIQIA.a || []).push(arguments);
      };
    },
    initMeiQia(eidValue){
      // 异步加载美洽 JS 文件
      _MEIQIA('withoutBtn');
      _MEIQIA('init');
      let that = this

    },
    init() {
      configList().then(res => {
        let data = res.data.list

        // 语言
        this.$store.state.languageList = data.filter(v => {
          return v.name == 'language'
        })[0].value


        //团队分成比例
        this.$store.state.oneLevelCommission = data.filter(v => {
          return v.name == 'oneLevelCommission'
        })[0].value
        this.$store.state.twoLevelCommission = data.filter(v => {
          return v.name == 'twoLevelCommission'
        })[0].value
        this.$store.state.threeLevelCommission = data.filter(v => {
          return v.name == 'threeLevelCommission'
        })[0].value

        // app_withdraw_fee
        this.$store.state.app_withdraw_fee = data.filter(v => {
          return v.name == 'app_withdraw_fee'
        })[0].value


        // 分享链接
        this.$store.state.promoteUrl = data.filter(v => {
          return v.name == 'app_promotion'
        })[0].value

        // reg_code
        this.$store.state.reg_code = data.filter(v => {
          return v.name == 'reg_code'
        })[0].value


        let appName = data.filter(v => {
          return v.name == 'app_name'
        })[0].value
        this.$store.state.appName = appName
        document.title = appName
      })

    },
    showMsgModal(type) {
      this.iconType = type
      this.message = type == 'success' ? this.successMsg : (type == 'error' ? this.errorMsg : this.warningMsg)
      this.showMsg = true
      if (type != 'error') {
        setTimeout(() =>{
          this.cancel()
        }, 2000)
      }
    },
    cancel() {
      this.showMsg = false
      this.$store.state.successMsg = ''
      this.$store.state.errorMsg = ''
      this.$store.state.warningMsg = ''
    },
    resetMover() {
      let startX = 0
      let startY = 0
      window.document.addEventListener('touchstart', (e) => {
        startX = e.targetTouches[0].pageX
        startY = e.targetTouches[0].pageY
      })
      window.document.addEventListener('touchmove', (e) => {
        let moveX = e.targetTouches[0].pageX
        let moveY = e.targetTouches[0].pageY
        if (Math.abs(moveX - startX) > Math.abs(moveY - startY)) {//左右滑
          e.preventDefault()
        }
      }, { passive: false })
      //
    }
  }
}
</script>

<style>
/* 设置顶部状态栏的背景颜色 */
/* 在 iOS 14 及以上版本中无效 */
/* 在 iOS 13 及以下版本中生效 */
:root {
  --apple-status-bar-color: #000000;
}

/* 设置顶部状态栏的前景颜色 */
/* 在 iOS 14 及以上版本中生效 */
/* 在 iOS 13 及以下版本中无效 */
@media (prefers-color-scheme: dark) {
  /* 暗色模式 */
  /* 在暗色模式下的前景色 */
  :root {
    --apple-status-bar-style: #ffffff
  }
}
@media (prefers-color-scheme: light) {
  /* 亮色模式 */
  /* 在亮色模式下的前景色 */
  :root {
    --apple-status-bar-style: #000000;
  }
}
</style>
<style lang="less">
/* 页面切换动画效果 */
.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active {
  height: 100%;
  will-change: transform;
  transition: all 200ms;
  position: absolute;
  // backface-visibility: hidden;
  // perspective: 1000px;
}
.slide-right-enter,
.slide-right-enter-active {
  animation: bounce-left-in 200ms;
}
.slide-right-leave-active {
  animation: bounce-left-out 200ms;
}
.slide-left-enter,
.slide-left-enter-active {
  animation: bounce-right-in 200ms;
}
.slide-left-leave-active {
  animation: bounce-right-out 200ms;
}

@keyframes bounce-right-in {
  0% {
    transform: translate3d(100%, 0, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes bounce-right-out {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-100%, 0, 0);
  }
}
@keyframes bounce-left-in {
  0% {
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes bounce-left-out {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(100%, 0, 0);
  }
}
</style>