<template>
  <div class="company-page">
    <top-bar :title="i18n.t(`content.title7`)" class="" @back="back"></top-bar>

    <div class="ceo-info flex-center-start">
      <div class="header">
        <img :src="headerUrl" alt="">
      </div>
      <div>
        <div class="name">Chris Feng</div>
        <div class="position">Shopee(CEO)</div>
      </div>
    </div>

    <div class="content">
      {{$t('content.content8')}}
    </div>

    <div class="flex-column-center img-box">
      <div class="title">Shopee Mall {{$t('content.content9')}}</div>
      <img :src="companyUrl" alt="">
    </div>
  </div>
</template>
<script>
import { articleList } from '@/api/setting'
import topBar from '@/components/topBar'
export default {
  components: {
    topBar
  },
  data() {
    return {
      headerUrl: require('../../assets/imgs/company1.png'),
      companyUrl: require('../../assets/imgs/company2.png')
    }
  },
  mounted() {
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
  }
}
</script>