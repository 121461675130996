<template>
  <div class="bill-page home-container fixed">
		<div class="flex-center top fixed border">
			{{i18n.t('bill.title')}}
		</div>

		<div class="card-list flex-center-between">
			<div class="card">
				<div class="label" :class="i18n.locale == 'zh-CN' || i18n.locale == 'zh-TW' ? '' : 'small'">
						{{i18n.t('bill.label1')}}
				</div>
				<div class="flex-center-between">
					<div class="icon">
						<img :src="moneyUrl1" alt="">
					</div>
					<div class="count" v-if="$store.state.payType == 'THB'">
						<span class="big">{{commissionData.TodayOrderCommission.toFixed(2)}}</span>
						<span class="label small">{{ $store.state.payType }}</span>
					</div>
          <div class="count" v-else>
            <span class="label small mr-5">{{ $store.state.payType }}</span>
            <span class="big">{{commissionData.TodayOrderCommission.toFixed(2)}}</span>
          </div>
				</div>
			</div>
			<div class="card">
				<div class="label" :class="i18n.locale == 'zh-CN' || i18n.locale == 'zh-TW' ? '' : 'small'">
					{{i18n.t('bill.label2')}}
				</div>
				<div class="flex-center-between">
					<div class="icon">
						<img :src="moneyUrl2" alt="">
					</div>
					<div class="count" v-if="$store.state.payType == 'THB'">
						<span class="big">{{commissionData.OrderCommission.toFixed(2)}}</span>
						<span class="label small">{{ $store.state.payType }}</span>
					</div>
          <div class="count" v-else>
            <span class="label small mr-5">{{ $store.state.payType }}</span>
            <span class="big">{{commissionData.OrderCommission.toFixed(2)}}</span>
          </div>
				</div>
			</div>
		</div>

		<div class="space-line"></div>

		<div class="modal-title">{{i18n.t('bill.label3')}}</div>

		<div class="rank-table big " >
			<div class="th-line flex">
				<div class="th flex-3">{{i18n.t('bill.th1')}}</div>
				<div class="th flex-3 text-right center">{{i18n.t('bill.th2')}}</div>
				<div class="th flex-3 text-right center">{{i18n.t('bill.th3')}}</div>
				<div class="th flex-2 center"></div>
			</div>
			<List
				v-if="rankList.length > 0"
				v-model="loading"
				:finished="finished"
				:finished-text="isInit && rankList.length == 0 ? '' : i18n.t('common.noMore')"
				@load="onLoad">
				<div class="table-body">
					<div class="td-line flex" v-for="(item, i) in rankList" :key="i" @click="toDetail(item)">
						<div class="td flex-3 flex-center-start">
							<div>{{item.Time.substring(8, 10)}}</div>
							<div class="ml-5">{{getMonthStr(item)}}</div>
						</div>
						<div class="td flex-3 text-right center">{{parseFloat(item.OrderMoney).toFixed(2)}}</div>
						<div class="td flex-3 text-right center">
							{{parseFloat(item.OrderCommission).toFixed(2)}}
						</div>
						<div class="td flex-2 flex-center-end pr-5">
							<img :src="rightUrl" alt="">
						</div>
					</div>
				</div>

			</List>
			<div class="empty-box" v-if="isInit && rankList.length == 0">
				<img :src="emptyUrl" alt="">
				<span>{{i18n.t('common.empty')}}</span>
			</div>
		</div>
  </div>
</template>
<script>
import { userInfo, commissionInfo } from '@/api/user'
import { billList } from '@/api/task'
import { List } from 'vant';
import { getToken } from '@/utils/auth'
export default {
  components: {
    List
  },
  data() {
    return {
      rightUrl: require('../../assets/imgs/icon-arrow-right.png'),
      emptyUrl: require('../../assets/imgs/empty.png'),
      moneyUrl1: require('../../assets/imgs/icon-money1.png'),
      moneyUrl2: require('../../assets/imgs/icon-money2.png'),
      rankList: [],
      isInit: false,
      loading: false,
      finished: false,
      page: {
        page: 1,
        limit: 100,
      },
      totalPage: 1,
      reportTotal: '0.00',
      commissionData: {
        OrderCommission: 0,
        TodayOrderCommission: 0
      },
      okNum: 0
    }
  },
  mounted() {
    this.init()
    this.initReport()
  },
  methods: {
    checkPage() {
      if (this.okNum == 2) {
        this.$store.state.showLoading = false
      }
    },
    onLoad() {
      this.page.page += 1
      this.init(true)
    },
    initReport() {
      commissionInfo().then(res => {
        this.okNum += 1
				this.checkPage()
        this.commissionData = res.data
      })
    },
    init(isAdd) {
      this.loading = true
      let form = new FormData()
      form.append('page', this.page.page)
      form.append('limit', this.page.limit)
      billList(form).then(res => {
        this.okNum += 1
				this.checkPage()
        this.isInit = true
        this.loading = false

        if (res.data.Items) {
          this.totalPage = res.data.Pagination.totalPage
          if (this.totalPage <= this.page.page) {
            this.finished = true
          }
          if (this.totalPage < this.page.page) {
            return
          }
          let list = res.data.Items
          if (isAdd) {
            this.rankList = this.rankList.concat(list)
          } else {
            this.rankList = list
          }
        }
      }).catch(err => {
        this.isInit = true
        this.loading = false
      })
    },
    getPercent(data) {
      let total = parseFloat(data.WorkMoney)
      let income = parseFloat(data.Income)
      if (total && income) {
        let percent = (income / total * 100).toFixed(1)
        return percent + '%'
      } else {
        return '- -'
      }
    },
    getMonthStr(data) {
      let month = data.Time.substring(5, 7)
      let monthStr = ''
      switch(month) {
        case '01': monthStr = 'January'; break;
        case '02': monthStr = 'February'; break;
        case '03': monthStr = 'March'; break;
        case '04': monthStr = 'April'; break;
        case '05': monthStr = 'May'; break;
        case '06': monthStr = 'June'; break;
        case '07': monthStr = 'July'; break;
        case '08': monthStr = 'August'; break;
        case '09': monthStr = 'September'; break;
        case '10': monthStr = 'October'; break;
        case '11': monthStr = 'November'; break;
        case '12': monthStr = 'December'; break;
        default: break;
      }
      return monthStr
    },
    toDetail(data) {
      this.$router.push({
        path: '/orderList',
        query: {
          date: data.Time
        }
      })
    }
  }
}
</script>