<template>
  <div class="role-page help-page">
    <top-bar :title="i18n.t(`content.title6`)" class="" @back="back"></top-bar>

    <div class="notice-list">
      <div class="list big" >
        <div class="bg-box">
          <div class="bg bg6 ">
            <div class="text-box flex-column-start-center center2 black">
              <div class="text1">{{i18n.t('content.label19')}}</div>
              <div class="text2" :class="i18n.locale == 'zh-CN' || i18n.locale == 'zh-TW' ? '' : 'clear'">{{i18n.t('content.label20')}}</div>
            </div>
          </div>
        </div>
        <div class="sub-title">{{i18n.t('content.title6')}}</div>
        
        <div class="help-list clear">
          <div class="list " :class="item.showContent ? 'show' : ''" v-for="(item, i) in helpData" :key="i" >
            <div class="title flex-center-between" @click="changeOpen(item, i)">
              <div  class="label">{{item.Title}}</div>
              <div class="icon">
                <img :src="downUrl" alt="">
              </div>
            </div>

            <div class="content" v-html="item.Content">
            </div>
          </div>
        </div>
        <div class="help-list clear">
          <vue-core-video-player src="https://mall.sgp1.cdn.digitaloceanspaces.com/IMG_1744.MP4"></vue-core-video-player>
          <video  autoplay style="width: 100%">
            <source src="https://mall.sgp1.cdn.digitaloceanspaces.com/IMG_1744.MP4" type="video/mp4" />
          </video>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { articleList } from '@/api/setting'
import topBar from '@/components/topBar'
import VueCoreVideoPlayer from 'vue-core-video-player'
export default {
  components: {
    topBar,
    VueCoreVideoPlayer
  },
  data() {
    return {
      noticeList: [],
      roleInfo: {},
      type: '',
      contentType: '',
      downUrl: require('../../assets/imgs/icon-arrow-down-gray.png'),
      helpData: [

      ]
    }
  },
  created() {
    this.$store.state.showLoading = false
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    changeOpen(data, i) {
      let index = this.helpData.findIndex(v => {
        return v.showContent
      })
      if (index > -1 && index != i) {
        this.$set(this.helpData[index], 'showContent', false)
      }
      let show = !data.showContent
      this.$set(this.helpData[i], 'showContent', show)
    }
  }
}
</script>