<template>
	<div class="home-container mine-page fixed">
		<div class="flex-center top fixed ">
			{{i18n.t('mine.title')}}
		</div>

		<div class="mine-info">
			<div class="label">{{i18n.t('mine.label1')}}</div>
			<div class="count" v-if="$store.state.payType == 'THB'">
				{{userMoney}}
				<span class="small">{{ $store.state.payType }}</span>
			</div>
      <div class="count" v-else>
        <span>{{ $store.state.payType }}</span>
        {{userMoney}}
      </div>
		</div>
		<div class="detail-box">
			<div class="detail-info flex-wrap">
				<div class="tab flex-column-center">
					<div class="flex-center name" :class="i18n.locale == 'zh-CN' || i18n.locale == 'zh-TW' ? 'big' : ''">
						<img :src="orderUrl" alt="">
						<span>{{i18n.t('mine.label2')}}</span>
					</div>
					<div class="number" v-if="$store.state.payType == 'THB'">
						{{commissionData.TodayCommission.toFixed(2)}}
						<span class="small">{{ $store.state.payType }}</span>
					</div>
          <div class="number" v-else>
            <span class="small">{{ $store.state.payType }}</span>
            {{commissionData.TodayCommission.toFixed(2)}}
          </div>
				</div>
				<div class="tab flex-column-center">
					<div class="flex-center name" :class="i18n.locale == 'zh-CN' || i18n.locale == 'zh-TW' ? 'big' : ''">
						<img :src="moneyUrl" alt="">
						<span>{{i18n.t('mine.label3')}}</span>
					</div>
					<div class="number" v-if="$store.state.payType == 'THB'">
						{{withdrawMoney}}
						<span class="small">{{ $store.state.payType }}</span>
					</div>
          <div class="number" v-else>
            <span class="small">{{ $store.state.payType }}</span>
            {{withdrawMoney}}
          </div>
				</div>
				<div class="tab flex-column-center">
					<div class="flex-center name" :class="i18n.locale == 'zh-CN' || i18n.locale == 'zh-TW' ? 'big' : ''">
						<img :src="teamUrl" alt="">
						<span>{{i18n.t('mine.label4')}}</span>
					</div>
					<div class="number" v-if="$store.state.payType == 'THB'">
						{{commissionData.Commission.toFixed(2)}}
						<span class="small">{{ $store.state.payType }}</span>
					</div>
          <div class="number" v-else>
            <span class="small">{{ $store.state.payType }}</span>
            {{commissionData.Commission.toFixed(2)}}
          </div>
				</div>
				<div class="tab flex-column-center">
					<div class="flex-center name" :class="i18n.locale == 'zh-CN' || i18n.locale == 'zh-TW' ? 'big' : ''">
						<img :src="moneyUrl2" alt="">
						<span>{{i18n.t('mine.label5')}}</span>
					</div>
					<div class="number" v-if="$store.state.payType == 'THB'">
						{{freezeMoney}}
						<span class="small">{{ $store.state.payType }}</span>
					</div>
          <div class="number" v-else>
            <span class="small">{{ $store.state.payType }}</span>
            {{freezeMoney}}
          </div>
				</div>
			</div>
		</div>

		<div class="tabs-box flex-center-between">
			<div class="tab flex-column-center" @click="toRecharge">
				<img :src="rechargeUrl" alt="">
				<span>{{i18n.t('mine.label6')}}</span>
			</div>
			<div class="tab flex-column-center" @click="toWithdraw">
				<img :src="withdrawUrl" alt="">
				<span>{{i18n.t('mine.label7')}}</span>
			</div>
		</div>

		<div  class="share-box">
			<Button @click="toPath('share')">{{i18n.t('mine.btn')}}</Button>
			<div class="desc">Invite Friends</div>
		</div>

		<div class="space-line"></div>

		<div class="modal-title">{{i18n.t('mine.title3')}}</div>

		<div class="menu-box flex">
			<div class="menu flex-column-center" v-for="(item, i) in menuList2" :key="i" @click="toPath(item.path)">
				<img :src="item.url" alt="">
				<span class="name" :class="i18n.locale == 'zh-CN' || i18n.locale == 'zh-TW' ? '' : 'small'">{{item.name}}</span>
			</div>
		</div>

		<div class="modal-title">{{i18n.t('mine.title2')}}</div>

		<div class="menu-box flex">
			<div class="menu flex-column-center" v-for="(item, i) in menuList1" :key="i" @click="toPath(item.path)">
				<img :src="item.url" alt="">
				<span class="name" :class="i18n.locale == 'zh-CN' || i18n.locale == 'zh-TW' ? '' : 'small'">{{item.name}}</span>
			</div>
		</div>

		<div class="modal-title">{{i18n.t('mine.title4')}}</div>

		<div class="menu-box clear flex-start">
			<div class="menu  flex-column-center" v-for="(item, i) in menuList3" :key="i" @click="toPath(item.path)">
				<img :src="item.url" alt="">
				<span class="name" :class="i18n.locale == 'zh-CN' || i18n.locale == 'zh-TW' ? '' : 'small'">{{item.name}}</span>
			</div>
		</div>
	</div>
</template>
<script>
import { Button } from 'vant'
import { userInfo, commissionInfo, getBankList, getUsdtList } from '@/api/user'
export default {
	components: {
		Button
	},
	data() {
		return {
			userMoney: '0.00',
			withdrawMoney: '0.00',
			freezeMoney: '0.00',
			orderUrl: require('../../assets/imgs/mine-order.png'),
			moneyUrl: require('../../assets/imgs/mine-money.png'),
			moneyUrl2: require('../../assets/imgs/mine-money2.png'),
			teamUrl: require('../../assets/imgs/mine-team.png'),
			rechargeUrl: require('../../assets/imgs/icon-recharge.png'),
      withdrawUrl: require('../../assets/imgs/icon-withdraw.png'),
			commissionData: {
				Commission: 0,
				FinishCount: 0,
				
				TeamCommission: 0,
				OrderCommission: 0,
				TodayCommission: 0,
			},
			vipLevelObj: {
				Leave: 1,
				Num: 0
			},
			isBind: true,
			okNum: 0
		}
	},
	computed: {
    payType() {
      return this.$store.state.payType
    },
		menuList1() {
			return [
				{
					url: require('../../assets/imgs/icon-light.png'),
					name: this.i18n.t('mine.menu2'),
					path: 'help'
				},
				{
					url: require('../../assets/imgs/icon-about.png'),
					name: this.i18n.t('mine.menu3'),
					path: 'about'
				},
				{
					url: require('../../assets/imgs/icon-kefu2.png'),
					name: this.i18n.t('mine.menu4'),
					path: 'kefu'
				},
        {
          url: "",
          name: "",
          path: 'none'
        },
			]
		},
		menuList2() {
			return [
				{
					url: require('../../assets/imgs/icon-finance2.png'),
					name: this.i18n.t('mine.menu5'),
					path: 'financeLog'
				},
				{
					url: require('../../assets/imgs/icon-sign2.png'),
					name: this.i18n.t('mine.menu6'),
					path: 'signLog'
				},
				{
					url: require('../../assets/imgs/icon-vip.png'),
					name: this.i18n.t('mine.menu7'),
					path: 'integralLog'
				},
				{
					url: require('../../assets/imgs/icon-recharge2.png'),
					name: this.i18n.t('mine.menu8'),
					path: 'debt'
				},
			]
		},
		menuList3() {
			return [
				{
					url: require('../../assets/imgs/icon-address.png'),
					name: this.i18n.t('mine.menu9'),
					path: 'address'
				},
			]
		},
  },
	watch: {
    payType() {
      this.initBank()
    }
  },
	mounted() {
		this.init()
		this.initBank()
	},
	methods: {
		checkPage() {
      if (this.okNum == 3) {
        this.$store.state.showLoading = false
      }
    },
		initBank() {
      if (this.payType == 'usdt') {
        getUsdtList().then(res => {
					this.okNum += 1
					this.checkPage()
          if (res.data.list.length == 0) {
            this.isBind = false
          } else {
            this.isBind = true
          }
        })
      } else {
        getBankList().then(res => {
					this.okNum += 1
					this.checkPage()
          if (res.data.Items.length == 0) {
            this.isBind = false
          } else {
            this.isBind = true
          }
        })
      }
    },
		init() {
			userInfo().then(res => {
				this.okNum += 1
				this.checkPage()
				this.userMoney = res.data.UserAccount ? res.data.UserAccount.Balance.toFixed(2) : '0.00'
				this.withdrawMoney = res.data.UserAccount ? res.data.UserAccount.Withdraw.toFixed(2) : '0.00'
				this.freezeMoney = res.data.UserAccount ? res.data.UserAccount.Freeze.toFixed(2) : '0.00'
				this.vipLevelObj = res.data.Leave
			})
			commissionInfo().then(res => {
				this.okNum += 1
				this.checkPage()
				this.commissionData = res.data
			})

		},
		toPath(path) {
      if(path === "none") {
        return
      }
			if (path) {
				this.$router.push({
					name: path
				})
			}
		},
		toWithdraw() {
			this.$router.push({
        name: 'withdraw'
      })
      // if (this.isBind) {
      //   this.$router.push({
      //     name: 'withdraw'
      //   })
      // } else {
			// 	this.$toast(this.payType == 'usdt' ? this.i18n.t('mine.desc1') : this.i18n.t('mine.desc2'))
      //   this.$router.push({
      //     name: this.payType == 'usdt' ? 'bindUsdt' : 'bindCard'
      //   })
      // }
    },
		toRecharge() {
			this.$router.push({
				name: this.payType == 'usdt' ? 'rechargeUsdt' : 'recharge'
			})
		}
	}
}
</script>
