<template>
  <div class="role-page">
    <top-bar :title="i18n.t(`content.title4`)" class="" @back="back"></top-bar>

    <div class="notice-list">
      <div class="list big" >
        <div class="bg-box ">
          <div class="bg bg4">
            <div class="text-box big2 flex-column-center blue">
              <div class="text1 small">{{i18n.t('content.label7')}}</div>
              <div class="text2 small" :class="i18n.locale == 'zh-CN' || i18n.locale == 'zh-TW' ? '' : 'clear'">{{i18n.t('content.label8')}}</div>
            </div>
          </div>
        </div>
        <div class="sub-title">{{i18n.t('content.title4')}}</div>
        <div class="content clear">
          <div>
            {{i18n.t('content.content6')}}
          </div>
          <div>
            {{i18n.t('content.content6_1')}}<br />
            {{i18n.t('content.content6_2')}}<br />
            {{i18n.t('content.content6_3')}}<br />
            {{i18n.t('content.content6_4')}}<br />
            {{i18n.t('content.content6_5')}}<br />
            {{i18n.t('content.content6_6')}}<br />
            {{i18n.t('content.content7_1')}}<br />
            {{i18n.t('content.content7_2')}}<br />
            {{i18n.t('content.content7_3')}}<br />
            {{i18n.t('content.content7_4')}}<br />
            {{i18n.t('content.content7_5')}}<br />
            {{i18n.t('content.content7_6')}}<br />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { articleList } from '@/api/setting'
import topBar from '@/components/topBar'
export default {
  components: {
    topBar
  },
  data() {
    return {
      noticeList: [],
      roleInfo: {},
      type: '',
      contentType: '',
    }
  },
  mounted() {
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
  }
}
</script>