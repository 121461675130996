<template>
	<div class="withdraw-page page-top">
		<top-bar :title="i18n.t('withdraw.title')" class="border"  @back="back"></top-bar>

    <div class="bank-info clear type-bit" >
      <div class="info-line">
        <span class="label">{{$t('withdraw.label4')}}：</span>
        <span class="value">{{bankInfo.RealName}}</span>
      </div>
      <div class="info-line">
         <span class="label">{{$t('withdraw.label5')}}：</span>
        <span class="value">{{bankInfo.Company}}</span>
      </div>
      <div class="info-line">
         <span class="label">{{$t('withdraw.label6')}}：</span>
        <span class="value">{{resetCard(bankInfo.Card)}}</span>
      </div>
      <div class="card  right money" >${{userMoney}}</div>
    </div>

    <div class="default-form">
      <div class="form-label">{{i18n.t('withdraw.label1')}}</div>
      <div class="form-item">
        <input type="number" inputmode="inputmode" :placeholder="i18n.t('withdraw.placeholder1')" @change="changeMoney" v-model="form.money"/>
      </div>
      <div class="form-desc">{{i18n.t('withdraw.label2')}}：${{userMoney}}</div>
      <div class="form-desc">{{i18n.t('withdraw.fee')}}：{{app_withdraw_fee}}%</div>


      <div class="form-label">{{i18n.t('withdraw.label3')}}</div>
      <div class="form-item">
        <input type="password" inputmode="decimal" :placeholder="i18n.t('withdraw.placeholder2')" v-model="form.password"/>
      </div>

      <Button class="btn" @click="submit">{{i18n.t('withdraw.btn')}}</Button>
    </div>
	</div>
</template>
<script>
import topBar from '@/components/topBar'
import { Popup, Button, Tabs, Tab } from 'vant'
import { getBankList, userInfo, doWithdraw, getUsdtList  } from '@/api/user'
export default {
	components: {
    Popup,
    Button,
		topBar,
		Tabs,
		Tab
  },
  data() {
		return {
      bankUrl: require('../../assets/imgs/bank.png'),
      bitUrl4: require('../../assets/imgs/bit4.png'),
      form: {
        money: '',
        password: ''
      },
      userMoney: '0.00',
      bankInfo: {},
      okNum: 0
		}
	},
  computed: {
    app_withdraw_fee() {
      return this.$store.state.app_withdraw_fee
    },
  },
  mounted() {
    this.init()
    this.initBank()
  },
	methods: {
    checkPage() {
      if (this.okNum == 2) {
        this.$store.state.showLoading = false
      }
    },
    initBank() {
      getBankList().then(res => {
        this.okNum += 1
        this.checkPage()
        if (res.data.Items.length > 0) {
          this.bankInfo = res.data.Items[0]
        }
      })
    },
		init() {

      userInfo().then(res => {
        this.userMoney = res.data.UserAccount ? res.data.UserAccount.Withdraw.toFixed(2) : '0.00'
        this.okNum += 1
				this.checkPage()
      })
		},

		back() {
			this.$router.go(-1)
		},

    toPath(path) {
      if (path) {
        this.$router.push({
          name: path
        })
      }
    },
    resetCard(card) {
      if (!card) {
        return ''
      }
      let newCard = ''
      try {
        if(card.length < 4) {
          return card + "******"
        }
        if (card) {
          newCard = card.substring(0, 4) + new Array(card.length - 6).join('*') + card.substring(card.length - 3)
        }
        return newCard
      } catch (e) {
        return card + "******"
      }
    },
    submit() {
      if (!this.form.money) {
        this.$toast(this.i18n.t('withdraw.placeholder1'))
        return
      }
      if (!this.form.password) {
        this.$toast(this.i18n.t('withdraw.placeholder2'))
        return
      }
      let form = new FormData()
      form.append('Price', this.form.money)
      form.append('Passwd', this.form.password)
      form.append('Type', this.payType)
      form.append('Id', this.bankInfo.ID)
      doWithdraw(form).then(res => {
        if (res.code == 0) {
          this.$toast(this.i18n.t('withdraw.success'))
          this.form.money = ''
          this.form.password = ''
          this.init()
        } else {
          this.$toast(res.msg)
        }
      })
    },
    changeMoney() {
      // if (this.form.money > parseFloat(this.userMoney)) {
      //   this.form.money = parseFloat(this.userMoney)
      // }
    },
    resetAddress(address) {
      if (address) {
        return address.substring(0, 8) + new Array(address.length - 15).join('*') + address.substring(address.length - 8)
      } else {
        return ''
      }
    }
	}
}
</script>