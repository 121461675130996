<template>
	<div class="recharge-page page-top">
		<top-bar :title="i18n.t('recharge.title2')" class="border"  @back="back" ></top-bar>

    <!-- <div class="top-img">
      <img :src="bgUrl" alt="">
       <div class="text-box flex-column-start-center " :class="i18n.locale == 'zh-CN' || i18n.locale == 'zh-TW' ? '' : 'small'">
        <div class="text1">{{i18n.t('content.label21')}}</div>
        <div class="text2" >{{i18n.t('content.label22')}}</div>
      </div>
    </div> -->

	  <div class="bank-info  type-bit">
      <div class="info-line">
        <span class="label">{{$t('withdraw.label4')}}：</span>
        <span class="value">{{bankInfo.RealName}}</span>
      </div>
      <div class="info-line">
         <span class="label">{{$t('withdraw.label5')}}：</span>
        <span class="value">{{bankInfo.Company}}</span>
      </div>
      <div class="info-line">
         <span class="label">{{$t('withdraw.label6')}}：</span>
        <span class="value">{{(bankInfo.Card)}}</span>
      </div>
      <div class="border" ></div>
    </div>
		
    <div class="page-title">{{i18n.t('recharge.label7')}}</div>

    <div class="desc gray">{{i18n.t('recharge.new1')}}</div>
    <div class="desc gray">{{i18n.t('recharge.new2')}}</div>
    <div class="desc gray">{{i18n.t('recharge.new3')}}</div>



    <div class="btn-box flex-center-between">
      <Button class="red-btn" :class="i18n.locale == 'zh-CN' || i18n.locale == 'zh-TW' ? '' : 'small'" @click="toPath('kefu')">{{i18n.t('recharge.btn1')}}</Button>
      <Button class="gray-btn" :class="i18n.locale == 'zh-CN' || i18n.locale == 'zh-TW' ? '' : 'small'" @click="toPath('rechargeInfo')">{{i18n.t('recharge.btn2')}}</Button>
    </div>

	</div>
</template>
<script>
import topBar from '@/components/topBar'
import { Popup, Button, Tabs, Tab } from 'vant'
import { helpList  } from '@/api/setting'
export default {
	components: {
    Popup,
    Button,
		topBar,
		Tabs,
		Tab
  },
  data() {
		return {
      bgUrl: require('../../assets/imgs/recharge-bg.png'),
      bankInfo: {
        RealName: 'Charle',
        Company: 'Citibank',
        Card: '6000 XXXX XXXX 1588'
      }
		}
	},
  mounted() {
  },
	methods: {
		init() {
		},
		
		back() {
			this.$router.go(-1)
		},
    toLog() {

    },
    toPath(path) {
      if (path) {
        this.$router.push({
          name: path
        })
      }
    },
    resetCard(card) {
      let newCard = ''
      try {
        if(card.length < 4) {
          return card + "******"
        }
        if (card) {
          newCard = card.substring(0, 4) + new Array(card.length - 6).join('*') + card.substring(card.length - 3)
        }
        return newCard
      } catch (e) {
        return card + "******"
      }
    },
	}
}
</script>