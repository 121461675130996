<template>
	<div class="sign-page page-top">
		<top-bar :title="i18n.t('sign.title')" class="border"  @back="back" ></top-bar>

    <div class="top-img flex-column-start-center">
      <div class="label">{{i18n.t('sign.label1')}}</div>
      <div class="count">{{rankList[current].SignReward}}</div>
    </div>

    <div class="sign-info">
      <Button :disabled="isSigned" class="btn" @click="submit">{{i18n.t('sign.label2')}}</Button>

      <div class="info-label">{{i18n.t('sign.label3')}}</div>

      <div class="rank-list">
        <div class="list flex-center" v-for="(item, i) in rankList" :key="i">
          <div class="flex-center mr-20">{{`V${item.Leave}`}}</div>
          <div class="name flex-1">{{i18n.t('sign.label4')}}</div>
          <div class="count" v-if="$store.state.payType == 'THB'">{{item.SignReward}} {{ $store.state.payType }}</div>
          <div class="count" v-else>{{ $store.state.payType }} {{item.SignReward}} </div>
        </div>
      </div>

      <div class="info-label">{{i18n.t('sign.label5')}}</div>

      <div class="desc">{{i18n.t('sign.desc')}}</div>
    </div>

    <Popup v-model="showMsg" class="msg-popup">
      <div class="msg-box">
        <div class="close" @click="cancel">
          <img :src="closeUrl" alt="">
        </div>
        <div class="content sign flex-column-center">
          <div class="success sign flex-center">
            <img :src="`${signUrl2}?${timeStr}`" alt="">
            <span class="yellow">{{msgCount}}</span>
          </div>
          <div class="msg" >
            {{i18n.t('sign.label6')}}<span class="yellow">{{msgCount}}</span>{{i18n.t('sign.label7')}}
          </div>
        </div>
      </div>
    </Popup>
	</div>
</template>
<script>
import topBar from '@/components/topBar'
import { Popup, Button} from 'vant'
import { userSign,getLeaveList  } from '@/api/index'
import { userInfo  } from '@/api/user'
export default {
	components: {
    Popup,
    Button,
		topBar
  },
  data() {
		return {
      bankUrl: require('../../assets/imgs/bank.png'),
      isSigned: false,
      showMsg: false,
      closeUrl: require('../../assets/imgs/icon-close.png'),
      signUrl: require('../../assets/imgs/msg-sign.png'),
      signUrl2: require('../../assets/imgs/loading3.gif'),
      msgCount: '',
      checkLoading:[],
      current: 0,
      rankList: [
        // {
        //   level: 1,
        //   money: 2.88
        // },
        // {
        //   level: 2,
        //   money: 4.88
        // },
        // {
        //   level: 3,
        //   money: 6.88
        // },
        // {
        //   level: 4,
        //   money: 8.88
        // },
        // {
        //   level: 5,
        //   money: 10.88
        // },
        // {
        //   level: 6,
        //   money: 12.88
        // },
      ],
      timeStr: ''
		}
	},
  mounted() {
    this.init()
  },
  watch: {
    checkLoading: {
      handler(val) {
        if (val.length == 2) {
          this.$store.state.showLoading = false
        }
      },
      deep: true
    }
  },
	methods: {
		init() {
      userInfo().then(res => {
        let level = res.data.Leave.Leave
        let index = this.rankList.findIndex(v => {
          return level = v.level
        })
        if (index < 0) {
          index = 0
        }
        // this.msgCount = this.rankList[index].money
        this.current = index
        // this.$store.state.showLoading = false
        this.checkLoading.push(1)
      })
      getLeaveList().then(res => {
        if (res.code == 0) {
          console.log(res)
          // this.isSigned = res.data.IsSign
          this.rankList = res.data.Items
          // this.$store.state.showLoading = false
        }
        this.checkLoading.push(1)
        // this.$store.state.showLoading = false
      })
		},
		back() {
			this.$router.go(-1)
		},
    toLog() {

    },
    toPath(path) {
      if (path) {
        this.$router.push({
          name: path
        })
      }
    },
    submit() {
      userSign().then(res => {
        if (res.code == 0) {
          this.$toast(this.i18n.t('sign.success'))
          this.isSigned = true
          this.init()
          this.getTimeStr()
          this.msgCount = res.data
          this.showMsg = true
        } else {
          this.$toast(res.msg)
        }
      })
    },
    cancel() {
      this.showMsg = false
    },
    getTimeStr() {
      let timeStr =  new Date().getTime()
      this.timeStr = timeStr
    }
	}
}
</script>