<template>
	<div class="recharge-page page-top">
		<top-bar :title="i18n.t('recharge.title3')" class="border"  @back="back" ></top-bar>

    <div class="select-box flex-center-start" @click="toPath('rechargeUsdt')">
      <div>
        <img :src="bitUrl" alt="">
      </div>
      <div>
        <div class="label">{{$t('recharge.label11')}}</div>
        <div class="desc-label">{{$t('recharge.label12')}}</div>
      </div>
    </div>

    <div class="select-box flex-center-start" @click="toPath('rechargeBank')">
      <div>
        <img class="small" :src="bankUrl" alt="">
      </div>
      <div>
        <div class="label">{{$t('recharge.label13')}}</div>
        <div class="desc-label">{{$t('recharge.label14')}}</div>
      </div>
    </div>

	</div>
</template>
<script>
import topBar from '@/components/topBar'
import { Popup, Button, Tabs, Tab } from 'vant'
import { helpList  } from '@/api/setting'
export default {
	components: {
    Popup,
    Button,
		topBar,
		Tabs,
		Tab
  },
  data() {
		return {
      bankUrl: require('../../assets/imgs/icon-bank.png'),
      bitUrl: require('../../assets/imgs/bit4.png'),
		}
	},
  mounted() {
  },
	methods: {
		init() {
		},
		
		back() {
			this.$router.go(-1)
		},
    toLog() {

    },
    toPath(path) {
      if (path) {
        this.$router.push({
          name: path
        })
      }
    },
	}
}
</script>