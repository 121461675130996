<template>
	<div class="debt-page " >
    <top-bar :title="$t('debt.title')"   @back="back" v-if="!hideTopBar"></top-bar>


    <div class="list-main">
      <List
        v-if="dataList.length > 0"
        v-model="loading"
        :finished="finished"
        :finished-text="i18n.t('common.noMore')"
        @load="onLoad">
        <div class="list " v-for="(item, i) in dataList" :key="i" v-if="item.Type > 1">
          <div class="flex-center-between">
            <div class="label">{{getTypeName(item.Type)}}</div>
            <div class="label gray"> {{resetTime(item.CreatedAt)}}</div>
          </div>
          <div class="flex-center-between">
            <div class=" count" >{{parseFloat(item.Practical) > 0 ? '+' : ''}}{{parseFloat(item.Practical).toFixed(2)}}  {{$store.state.payType}} </div>
            <div>{{$t('debt.label2')}}： {{parseFloat(item.DebtAfter).toFixed(2)}}</div>
          </div>
        </div>
      </List>

      <div class="empty flex-column-center" v-if="dataList.length == 0 && isInit">
        <img :src="emptyUrl" alt="">
        <span class="desc">{{i18n.t('common.empty')}}</span>
      </div>
    </div>


	</div>
</template>
<script>
import { debtType, debtList } from '@/api/user'
import topBar from '@/components/topBar'
import { List, Picker, Popup } from 'vant'
import { resetTime } from '@/utils/common'
import moment from 'moment'
export default {
  components: {
    topBar,
    List,
    Picker,
    Popup
  },
  data() {
		return {
      form: {
        type: undefined,
        typeName: ''
      },
      showPopup: false,
      successUrl: require('../../assets/imgs/icon-success.png'),
      waitUrl: require('../../assets/imgs/icon-waiting.png'),
      errorUrl: require('../../assets/imgs/icon-error.png'),
      page: {
        page: 1,
        limit: 20
      },
      isInit: false,
      loading: false,
      finished: false,
      totalPage: 1,
			emptyUrl: require('../../assets/imgs/empty.png'),
      dataList: [],
      typeList: []
		}
	},
  computed: {
    hideTopBar() {
      return this.$store.state.hideTopBar
    }
  },
  mounted() {

    this.initType()
    this.init()
  },
	methods: {
    resetTime,
    initType() {
      debtType().then(res => {
        let data = res.data
        let list = [
          {
            value: '',
            label: '全部'
          }
        ]
        for (let key in data) {
          let param = {
            value: key,
            label: data[key]
          }
          list.push(param)
        }
        this.typeList = list

      })
    },
    onLoad() {
      this.page.page += 1
      this.init(true)
    },
    init(isAdd) {
      this.finished = false
      let form = new FormData()
      form.append('page', this.page.page)
      form.append('limit', this.page.limit)
      form.append('Type', '')
      debtList(form).then(res => {
        if (res.data.Items) {
          if (isAdd) {
            this.dataList = this.dataList.concat(res.data.Items)
          } else {
            this.dataList = res.data.Items
          }
          this.totalPage = res.data.Pagination ? res.data.Pagination.totalPage : 1
          if (this.totalPage <= this.page.page) {
             this.finished = true;
          }
        }
        this.isInit = true
        this.loading = false
        this.$store.state.showLoading = false
      })
    },
		back() {
			this.$router.go(-1)
		},

    toDetail(data) {
      sessionStorage.setItem('logData', JSON.stringify(data))
      this.$router.push({
        name: 'rechargeLogDetail'
      })
    },
    changeType() {
      this.showPopup = true
    },
    cancel() {
      this.showPopup = false
    },
    onConfirm(e) {
      this.form.type = e.value
      this.form.typeName = e.label
      this.showPopup = false

      this.page.page = 1
      this.init()
    },
    getTypeName(type) {
      let index = this.typeList.findIndex(v => {
        return v.value == type
      })
      if (index > -1) {
        return this.typeList[index].label
      } else {
        return ''
      }
    }
	}
}
</script>