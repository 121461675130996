<template>
	<div class="withdraw-page page-top">
		<top-bar :title="i18n.t('bindAddress.title')" class="border"  @back="back" ></top-bar>

    <div class="default-form">
      <div class="form-item">
        <input type="text" :placeholder="i18n.t('bindAddress.label1')" readonly v-model="form.Country" @click="changeCountry"/>
        <div class="adot small" >
          <img :src="downUrl" alt="" >
        </div>
      </div>

      <div class="form-item">
        <input type="text" :placeholder="i18n.t('bindAddress.label2')" v-model="form.FullName"/>
      </div>

      <div class="form-item">
        <input type="text" :placeholder="i18n.t('bindAddress.label3')" v-model="form.Address"/>
      </div>

      <div class="form-item">
        <input type="text" :placeholder="i18n.t('bindAddress.label4')" v-model="form.City"/>
      </div>

      <div class="form-item">
        <input type="text" :placeholder="i18n.t('bindAddress.label5')" v-model="form.Province"/>
      </div>

      <div class="form-item ">
        <input  type="text"  :placeholder="i18n.t('bindAddress.label6')" v-model="form.ZipCode"/>
      </div>
      <div class="form-item ">
        <input  type="text"  :placeholder="i18n.t('bindAddress.label7')" v-model="form.Phone"/>
      </div>

      <Button class="btn" @click="submit">{{i18n.t('bindAddress.btn')}}</Button>
    </div>

    <Popup v-model:show="showPopup" position="bottom">
			<div class="country-list" >
				<div class="title flex-center-start">
          <Icon name="arrow-left" size="18" @click="cancel" ></Icon>
          <span>{{i18n.t('bindAddress.placeholder1')}}</span>
        </div>
        <div class="search-list flex-center-between">
          <div class="flex-center">
            <img :src="searchUrl" alt="">
            <input type="text" v-model="keyword" :placeholder="i18n.t('login.labelPlaceholder4')" @input="searchCountry" @change="searchCountry">
          </div>
          <div @click="cancel">{{i18n.t('login.cancel')}}</div>
        </div>
        <div class="group-list login" v-for="(item, i) in countryList" :key="i">
          <div class="list flex-center-between" :class="child.key == phonePre ? 'active' : ''" v-for="(child, index) in item.children" :key="index" @click.stop="chooseCountry(child)">
            <div class="img-box flex-center">
              <img :src="child.icon" alt="">
              <span>{{getCountryName(child.title)}}</span>
            </div>
            <div>
              {{child.key}}
            </div>
          </div>
        </div>
			</div>
    </Popup>
	</div>
</template>
<script>
import topBar from '@/components/topBar'
import { Popup, Button, Tabs, Tab } from 'vant'
import { addAddress  } from '@/api/setting'
export default {
	components: {
    Popup,
    Button,
		topBar,
		Tabs,
		Tab
  },
  data() {
		return {
      bankUrl: require('../../assets/imgs/bank.png'),
      searchUrl: require('../../assets/imgs/icon-search.png'),
      downUrl: require('../../assets/imgs/icon-arrow-down.png'),
      topUrl: require('../../assets/imgs/icon-arrow-top.png'),
      form: {
        Country: '',
        FullName: '',
        Address: '',
        City: '',
        Province: '',
        ZipCode: '',
        Phone: '',
      },
      countryList: [],
      showPopup: false,
      keyword: '',
      phonePre: ''
		}
	},
  computed: {
    countryListBak() {
      return this.$store.state.countryList
    }
  },
  mounted() {
    this.countryList = [].concat(this.countryListBak)
  },
	methods: {
		back() {
			this.$router.go(-1)
		},
    toPath(path) {
      if (path) {
        this.$router.push({
          name: path
        })
      }
    },
    submit() {
      if (!this.form.Country) {
        this.$toast(this.i18n.t('bindAddress.placeholder1'))
        return
      }
      if (!this.form.FullName) {
        this.$toast(this.i18n.t('bindAddress.placeholder2'))
        return
      }
      if (!this.form.Address) {
        this.$toast(this.i18n.t('bindAddress.placeholder3'))
        return
      }
      if (!this.form.City) {
        this.$toast(this.i18n.t('bindAddress.placeholder4'))
        return
      }
      if (!this.form.Province) {
        this.$toast(this.i18n.t('bindAddress.placeholder5'))
        return
      }
      if (!this.form.ZipCode) {
        this.$toast(this.i18n.t('bindAddress.placeholder6'))
        return
      }
      if (!this.form.Phone) {
        this.$toast(this.i18n.t('bindAddress.placeholder7'))
        return
      }
      let form = new FormData()
      form.append('Country', this.form.Country)
      form.append('FullName', this.form.FullName)
      form.append('Address', this.form.Address)
      form.append('City', this.form.City)
      form.append('Province', this.form.Province)
      form.append('ZipCode', this.form.ZipCode)
      form.append('Phone', this.phonePre + this.form.Phone)
      addAddress(form).then(res => {
        if (res.code == 0) {
          this.$toast(this.i18n.t('bindAddress.success'))
          setTimeout(() => {
            this.back()
          }, 1000);
        } else {
          this.$toast(res.msg)
        }
      })
    },

    cancel() {
      this.showPopup = false
    },
    changeCountry() {
      this.showPopup = true
    },
    chooseCountry(data) {
      this.phonePre = data.key
      this.form.Country = this.getCountryName(data.title)
      this.showPopup = false
    },
    getCountryName(name) {
      if (!name) {
        return ''
      }
      let newName = ''
      newName = name.substring(0, name.indexOf('(') - 1)
      return newName
    },
    searchCountry() {
      if (this.keyword) {
        this.countryList = this.countryListBak.map(v => {
          let children = v.children.filter(val => {
            return val.title.toLowerCase().indexOf(this.keyword.toLowerCase()) > -1
          })
          if (children.length > 0) {
            return {
              children: children
            }
          } else {
            return {
              children: []
            }
          }
        })
      } else {
        this.countryList = [].concat(this.countryListBak)
      }
    }

	}
}
</script>