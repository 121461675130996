<template>
  <div class="login-page">
    <topBar :title="i18n.t('login.title')" @back="back" class="border" rightIcon="kefu" @detail="toPath('kefu')"></topBar>

    <div class="logo" v-show="step == 1">
      <img :src="logoUrl" alt="">
    </div>

    <div class="login-tab flex-start" v-if="step == 1">
      <div class="tab" :class="tabIndex == 1 ? 'active' : ''" @click="changeTab(1)">{{i18n.t('login.tab1')}}</div>
<!--      <div class="tab" :class="tabIndex == 2 ? 'active' : ''" @click="changeTab(2)">{{i18n.t('login.tab2')}}</div>-->
    </div>

    <div class="login-form">
      <div class="desc"  v-show="step == 2">
        {{i18n.t('login.label1')}} ID：{{tabIndex == 1 ? form.phone : form.email}} {{i18n.t('login.label2')}}
      </div>

      <div class="form-item  flex-center" v-show="step == 1 && tabIndex == 1">
        <div class="pre flex-center" @click="changePre">
          {{phonePre}}
          <img :src="downUrl" alt="">
        </div>
        <div class="flex-1">
          <div class="input clear">
            <input type="tel" v-model="form.phone" @focus="onInputFocus" @blur="onInputBlur" :placeholder="i18n.t('login.labelPlaceholder5')">
          </div>
        </div>
      </div>

      <div class="form-item  flex-center" v-show="step == 1 && tabIndex == 2">
        <div class="flex-1">
          <div class="input ">
            <input type="text" v-model="form.email" :placeholder="i18n.t('login.labelPlaceholder1')">
          </div>
        </div>
      </div>

      <div class="form-item flex-center" v-show="step == 2">
        <div class="icon">
        </div>
        <div class="input">
          <input type="password" v-model="form.password" @focus="onInputFocus" @blur="onInputBlur" :placeholder="i18n.t('login.labelPlaceholder2')">
        </div>
      </div>

      <Button class="login-btn" @click="submit">{{i18n.t(`login.btn${step}`)}}</Button>
    </div>


    <div class="forget" v-if="step == 1" >
<!--      <span @click="toPath('resetPassword')">{{i18n.t('login.forget')}}</span>-->
      <span @click="toPath('kefu')">{{i18n.t('login.forget')}}</span>
    </div>

    <div class="register"  v-show="showBottomText">
      <Divider  :style="{ color: '#454545', borderColor: '#454545', padding: '0 17px' }">
        <span @click="toPath('register')">{{i18n.t('login.register')}}</span>
      </Divider>
    </div>

    <Popup v-model:show="showPopup" position="bottom">
			<div class="country-list" >
				<div class="title flex-center-start">
          <Icon name="arrow-left" size="18" @click="cancel" ></Icon>
          <span>{{i18n.t('login.labelPlaceholder3')}}</span>
        </div>
        <div class="search-list flex-center-between">
          <div class="flex-center">
            <img :src="searchUrl" alt="">
            <input type="text" v-model="keyword" :placeholder="i18n.t('login.labelPlaceholder4')" @input="searchCountry" @change="searchCountry">
          </div>
          <div @click="cancel">{{i18n.t('login.cancel')}}</div>
        </div>
        <div class="group-list login" v-for="(item, i) in countryList" :key="i">
          <div class="list flex-center-between" :class="child.key == phonePre ? 'active' : ''" v-for="(child, index) in item.children" :key="index" @click.stop="chooseCountry(child)">
            <div class="img-box flex-center">
              <img :src="child.icon" alt="">
              <span>{{getCountryName(child.title)}}</span>
            </div>
            <div>
              {{child.key}}
            </div>
          </div>
        </div>
			</div>
    </Popup>
  </div>
</template>
<script>
  import { Button, Popup, Icon, Divider  } from 'vant'
  import { phoneLogin, emailLogin } from '@/api/login'
  import { setToken, setUser, setUserId } from '@/utils/auth'
  import topBar from '@/components/topBar'
  const FingerprintJS = require('@fingerprintjs/fingerprintjs')
  const fpPromise = FingerprintJS.load()
  export default {
    components: {
      Button,
      Popup,
      Icon,
      Divider,
      topBar
    },
    data() {
      return {
        tabIndex: 1,
        step: 1,
        form: {
          phone: '',
          email: '',
          password: '',
          Fingerprint: ''
        },
        keyword: '',
        phonePre: '+65',
        searchUrl: require('../../assets/imgs/icon-search.png'),
        downUrl: require('../../assets/imgs/icon-arrow-down.png'),
        logoUrl: require('../../assets/imgs/logo3.png'),
        showPopup: false,
        showBottomText: true,
        countryList: []
      }
    },
    computed: {
      countryListBak() {
        return this.$store.state.countryList
      }
    },
    mounted() {
      this.countryList = [].concat(this.countryListBak)
      fpPromise.then(fp => fp.get()).then(result => {
        // This is the visitor identifier:
        const visitorId = result.visitorId
        this.form.Fingerprint = visitorId
      })
    },
    methods: {
      onInputFocus() {
        // 输入框获得焦点时，监听软键盘弹出事件
        window.addEventListener('resize', this.adjustPageHeight);
        // 恢复底部文字显示
        this.showBottomText = false;
      },
      onInputBlur() {
        // 输入框失去焦点时，移除事件监听
        window.removeEventListener('resize', this.adjustPageHeight);
        // 恢复底部文字显示
        this.showBottomText = true;
      },
      adjustPageHeight() {
        // 获取当前页面高度
        const newHeight = window.innerHeight;

        // 如果当前页面高度小于初始高度，表示软键盘弹出
        if (newHeight < this.initialHeight) {
          // 调整页面高度，可以使用你喜欢的动画效果
          document.body.style.height = this.initialHeight + 'px';
          // 隐藏底部文字
          this.showBottomText = false;
        } else {
          // 恢复默认高度
          document.body.style.height = 'auto';
          // 恢复底部文字显示
          this.showBottomText = true;
        }
      },
      back() {
        if(this.step != 1) {
          this.step -= 1
          return
        }
        this.$router.push({
          name: 'index'
        })
      },
      toPath(path) {
        if (path) {
          this.$router.push({
            name: path
          })
        }
      },
      changeTab(index) {
        this.tabIndex = index
      },
      submit() {
        if (!this.form.phone && this.tabIndex == 1) {
          this.$toast(this.i18n.t('login.labelPlaceholder5'))
          return
        }
        if (!this.form.email && this.tabIndex == 2) {
          this.$toast(this.i18n.t('login.labelPlaceholder1'))
          return
        }
        if (this.step == 1) {
          this.step = 2
          return
        }
        if (!this.form.password) {
          this.$toast(this.i18n.t('login.labelPlaceholder2'))
          return
        }
        let form = new FormData()
        if (this.tabIndex == 1) {
          form.append('Phone', this.phonePre + this.form.phone)
          form.append('Password', this.form.password)
          phoneLogin(form).then(res => {
            if (res.code == 0) {
              setToken(res.data.token)
              setUser(this.phonePre + this.form.phone)
              setUserId(res.data.id)
              this.$router.push({
                name: 'index'
              })
            } else {
              this.$toast(res.msg)
            }
          })
        } else {
          form.append('Email', this.form.email)
          form.append('Password', this.form.password)
          emailLogin(form).then(res => {
            if (res.code == 0) {
              setToken(res.data.token)
              setUser(this.phonePre + this.form.phone)
              setUserId(res.data.id)
              this.$router.push({
                name: 'index'
              })
            } else {
              this.$toast(res.msg)
            }
          })

        }
      },
      cancel() {
        this.showPopup = false
      },
      changePre() {
        this.showPopup = true
      },
      chooseCountry(data) {
        this.phonePre = data.key
        this.showPopup = false
      },
      getCountryName(name) {
        if (!name) {
          return ''
        }
        let newName = ''
        newName = name.substring(0, name.indexOf('(') - 1)
        return newName
      },
      searchCountry() {
        if (this.keyword) {
          this.countryList = this.countryListBak.map(v => {
            let children = v.children.filter(val => {
              return val.title.toLowerCase().indexOf(this.keyword.toLowerCase()) > -1
            })
            if (children.length > 0) {
              return {
                children: children
              }
            } else {
              return {
                children: []
              }
            }
          })
        } else {
          this.countryList = [].concat(this.countryListBak)
        }
      }
    }
  }
</script>