<template>
	<div class="order-detail-page">
    <top-bar :title="i18n.t('orderList.title')"  @back="back" ></top-bar>

    <div class="task-list">
      <List
        v-if="dataList.length > 0"
        v-model="loading"
        :finished="finished"
        :finished-text="i18n.t('common.noMore')"
        @load="onLoad">
        <div class="detail-box" v-for="(item, i) in dataList" :key="i">
          <div class="flag" v-if="item.BurstOrderID != 0 || item.PresetOrderId != 0">
            <img :src="flagUrl" alt="">
          </div>
          <div class="top-box big">
            <div class="box flex-center">
              <div class="img-box" >
                <img :src="item.Goods.ImgUrl" alt="">
              </div>
              <div class="title">
                {{item.Goods.GoodsName}}
              </div>
            </div>


            <div class="flex-center-between line" :class="i18n.locale == 'zh-CN' || i18n.locale == 'zh-TW' ? '' : 'small'">
              <div>{{i18n.t('billDetail.label1')}}：</div>
              <div>{{parseFloat(item.Price).toFixed(2)}} {{ $store.state.payType }}</div>
            </div>
            <div class="flex-center-between line big" :class="i18n.locale == 'zh-CN' || i18n.locale == 'zh-TW' ? '' : 'small'">
              <div>{{i18n.t('billDetail.label2')}}：</div>
              <div>{{formatTime(item.CreatedAt)}}</div>
            </div>
            <div class="flex-center-between line big" :class="i18n.locale == 'zh-CN' || i18n.locale == 'zh-TW' ? '' : 'small'">
              <div>{{i18n.t('billDetail.label3')}}：</div>
              <div>{{parseFloat(item.EarnCommission).toFixed(2)}} {{ $store.state.payType }}</div>
            </div>
            <div class="flex-center-between line big" :class="i18n.locale == 'zh-CN' || i18n.locale == 'zh-TW' ? '' : 'small'">
              <div>{{i18n.t('billDetail.label4')}}：</div>
              <div>{{i18n.t(`billDetail.status${item.Status}`)}}</div>
            </div>
          </div>
          <div class="bottom-box" v-show="false">
            <div class="flex-center-start label">
              <img :src="addressUrl" alt="">
              <span>{{i18n.t('billDetail.label5')}}：</span>
            </div>
            <div class="line">
              {{resetUserInfo(item.ShippingAddress.Address)}}
            </div>
            <div class="line">{{resetUserInfo(item.ShippingAddress.ZipCode)}} {{resetUserInfo(item.ShippingAddress.City)}}</div>
          </div>
        </div>

      </List>

      <div class="empty flex-column-center" v-if="dataList.length == 0 && isInit">
        <img :src="emptyUrl" alt="">
        <span class="desc">{{i18n.t('common.empty')}}</span>
      </div>
    </div>

	</div>
</template>
<script>
import { orderList, orderComplete } from '@/api/task'
import topBar from '@/components/topBar'
import { List, Popup, Button, Form } from 'vant'
import moment from 'moment'
import {formatTime} from "@/utils/common";
export default {
  components: {
    topBar,
    List,
    Popup,
    Button
  },
  data() {
		return {
      form: {
        bit: '',
        type: '',
        account: ''
      },
      page: {
        page: 1,
        limit: 20
      },
      isInit: false,
      loading: false,
      finished: false,
      totalPage: 1,
      showPopup: false,
			emptyUrl: require('../../assets/imgs/empty.png'),
			addressUrl: require('../../assets/imgs/icon-address2.png'),
      flagUrl: require('../../assets/imgs/order-flag.png'),
      dataList: [],
      orderData: {
        Goods: {}
      },
      startDate: '',
      endDate: ''
		}
	},
  mounted() {

    let date = this.$route.query.date
    if (date) {
      this.startDate = moment(date).startOf('days').format('YYYY-MM-DD HH:mm:ss')
      this.endDate = moment(date).endOf('days').format('YYYY-MM-DD HH:mm:ss')
    } else {
      this.startDate = moment().startOf('days').format('YYYY-MM-DD HH:mm:ss')
      this.endDate = moment().endOf('days').format('YYYY-MM-DD HH:mm:ss')
    }
    this.init()
  },
	methods: {
    formatTime,
    onLoad() {
      this.page.page += 1
      this.init(true)
    },
    init(isAdd) {
      let form = new FormData()
      form.append('page', this.page.page)
      form.append('limit', this.page.limit)
      form.append('Status', '')
      form.append('start', this.startDate)
      form.append('end', this.endDate)
      orderList(form).then(res => {
        if (res.data.Items) {
          let list = res.data.Items.filter(v => {
            return v.Status == 1 || v.Status == 4
          })
          if (isAdd) {
            this.dataList = this.dataList.concat(list)
          } else {
            this.dataList = list
          }
          this.totalPage = res.data.Pagination.totalPage
          if (this.totalPage <= this.page.page) {
             this.finished = true;
          }
        }
        this.isInit = true
        this.loading = false
        this.$store.state.showLoading = false
      })
    },
    resetUserInfo(info) {
			if (!info) {
				return ''
			}
      let newInfo = ''
      if (info.length <= 4) {
        newInfo = new Array(info.length - 1).join('*') + info.substring(info.length - 2)
      } {
        newInfo = info.substring(0, 2) + new Array(info.length - 3).join('*') + info.substring(info.length - 2)
      }
      return newInfo
    },
		back() {
			this.$router.go(-1)
		},
    cancel() {
      this.showPopup = false
    },
    toDetail(data, i) {
      sessionStorage.setItem('orderDetail', JSON.stringify(data))
      this.$router.push({
        name: 'orderDetail',
      })
    },
	}
}
</script>