<template>
  <div class="role-page">
    <top-bar :title="i18n.t(`content.title2`)" class="" @back="back"></top-bar>

    <div class="notice-list">
      <div class="list big" >
        <div class="bg-box">
          <div class="bg bg2 ">
            <div class="text-box flex-column-start-center center2 yellow">
              <div class="text1">{{i18n.t('content.label13')}}</div>
              <div class="text2" :class="i18n.locale == 'zh-CN' || i18n.locale == 'zh-TW' ? '' : 'clear'">{{i18n.t('content.label14')}}</div>
            </div>
          </div>
        </div>
        <div class="sub-title">{{i18n.t('content.title2')}}</div>
        <div class="content clear">
          <div>
            {{i18n.t('content.content2_1')}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { articleList } from '@/api/setting'
import topBar from '@/components/topBar'
export default {
  components: {
    topBar
  },
  data() {
    return {
      noticeList: [],
      roleInfo: {},
      type: '',
      contentType: '',
    }
  },
  mounted() {
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
  }
}
</script>