<template>
  <div class="role-page">
    <top-bar :title="i18n.t(`content.title5`)" class="" @back="back"></top-bar>

    <div class="notice-list">
      <div class="list big" >
        <div class="bg-box">
          <div class="bg bg5 ">
            <div class="text-box flex-column-start-center center2 black">
              <div class="text1 small">{{i18n.t('content.label15')}}</div>
              <div class="text3" >{{i18n.t('content.label16')}}</div>
            </div>
          </div>
        </div>
        <div class="sub-title">{{i18n.t('content.label1')}}</div>

        <div class="kefu-list">
          <div class="list flex-center" v-for="(item, i) in kefuData" :key="i" @click="chooseKefu(item)">
            <img :src="`${item.Avatar}`" alt="">
            <div class="flex-1">{{item.Name}}</div>
            <img class="small" :src="rightUrl" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { kefuList } from '@/api/setting'
import topBar from '@/components/topBar'
import {getUser, getUserId} from "@/utils/auth";
export default {
  components: {
    topBar
  },
  data() {
    return {
      rightUrl: require('../../assets/imgs/icon-arrow-right-gray.png'),
      noticeList: [],
      roleInfo: {},
      type: '',
      contentType: '',
      kefuData: [ ]
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      kefuList().then(res => {
        this.kefuData = res.data.Items
      })
    },
    back() {
      this.$router.go(-1)
    },
    chooseKefu(data) {
      this.to(data.Type, data.Link)
    },
    runMeiqiaScript() {
      const script = document.createElement('script');
      script.async = true;
      script.charset = 'UTF-8';
      script.src = 'https://static.meiqia.com/dist/meiqia.js';
      document.head.appendChild(script);
      window._MEIQIA = window._MEIQIA || function() {
        (window._MEIQIA.a = window._MEIQIA.a || []).push(arguments);
      };
    },
    to(Type, Link) {
      let tmp = undefined
      let that = this
      if(Type == "chatwoot") {

        if(window.$chatwoot === undefined) {
          tmp = setInterval(function (){
            // that.chatwoot()
            if (window.$chatwoot !== undefined) {
              clearInterval(tmp)
              window.$chatwoot.toggle('open');
            }
          },50)
        } else {
          window.$chatwoot.toggle('open');
        }
      } else if (Type == 'meiqia') {
        if(Link.indexOf('chatlink.html') > -1) {
          let url = Link  + '&ID=' + getUserId() + '&name=' + getUser() + '&username=' + getUser()
          window.localStorage.setItem('kefuUrl', url)
          that.$router.push({
            name: 'kf2'
          })
          return
        }
        this.meiqia(Link)
      } else {
        switch (Type) {
          case "wechat":
            Link = "weixin://" + Link
            break;
          case "qq":
            Link = "mqq://"+ Link
            break;
          case "whatsapp":
            Link = "whatsapp://send?phone="+ Link
            break;
          case "telegram":
            Link = "tg://resolve?domain="+ Link
            break;
          case "facebook":
            Link = "fb://"+ Link
            break;
          case "twitter":
            Link = "twitter://"+ Link
            break;
          case "instagram":
            Link = "instagram://"+ Link
            break;
            case "line":
              // 检查是否包含line
            if(Link.indexOf("line.me") > -1) {
            } else {
              Link = "https://line.me/R/"+ Link
            }
            break;
        }
        this.toKefuPath(Link)
      }
    },
    toKefuPath(path) {
      let a = document.createElement('a')
      a.href = path
      a.target = '_blank'
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    },
    chatwoot(Link){
      let that = this;
      if (Link.length === 0) {
        return;
      }
      let len = Link.indexOf("/widget");
      if (len === -1) {
        return;
      }
      var BASE_URL=Link.substr(0,len);
      let tokenlen = Link.indexOf("token=");
      let token = Link.substr(tokenlen+6);
      window.chatwootSDK.run({
        websiteToken: token,
        baseUrl: BASE_URL
      });
      that.inter = setInterval(function(){
        if(document.getElementsByClassName("woot-widget-bubble")[0] !== undefined) {
          that.show = true;
          clearInterval(that.inter);
          window.$chatwoot.toggleBubbleVisibility('hide');
        }
      },50);
    },
    meiqia(Link){
      this.runMeiqiaScript()
      const match = Link.match(/eid=([^&]+)/);
      const eidValue = match ? match[1] : null;
      _MEIQIA('entId', eidValue);
      _MEIQIA('withoutBtn');
      _MEIQIA('showPanel');
      _MEIQIA('language','en')
      // 传递顾客信息
      _MEIQIA('metadata', {
        ID: getUserId(), // 美洽默认字段
        name: getUser(), // 美洽默认字段
        username: getUser(), // 美洽默认字段
      });
      let that = this
//点击关闭按钮后的回调
      _MEIQIA('beforeCloseWindow', function() {
        // alert('点击了关闭按钮');
        // 返回
        that.$router.push({
          name: 'kefu'
        })
      });
    },
  }
}
</script>