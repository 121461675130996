<template>
  <div class="role-page1">
    <iframe class="kefu-iframe" v-if="kefuUrl !== undefined" :src="kefuUrl"></iframe>
  </div>
</template>
<script>
import { Button } from 'vant'
import { getKefuList } from '@/api/user'
import {getUser, getUserId} from "@/utils/auth";
import Vue from 'vue';
import {kefuList} from "@/api/setting";
import topBar from "@/components/topBar/index.vue"; // 引入 Vue
export default {
  components: {
    topBar,
    Button,
  },
  data() {
    return {
      kefuList: [],
      // kefuUrl: '/#/kf2'
      kefuUrl: undefined,
      LiveChat: false,
      username: "",
      session: {},
      token: "",
      uid: "",
      maximized: "maximized",
      license: ""
    }
  },
  mounted() {
    this.kefuUrl = window.localStorage.getItem('kefuUrl')
  },
  methods: {
    runMeiqiaScript() {
      const script = document.createElement('script');
      script.async = true;
      script.charset = 'UTF-8';
      script.src = 'https://static.meiqia.com/dist/meiqia.js';
      document.head.appendChild(script);
      window._MEIQIA = window._MEIQIA || function() {
        (window._MEIQIA.a = window._MEIQIA.a || []).push(arguments);
      };
    },

    init() {
      kefuList().then(res => {
        this.kefuData = res.data.Items
      })
    },
    to(Type,Link){
      let tmp = undefined
      let that = this
      if(Type == "chatwoot") {
        if(window.$chatwoot === undefined) {
          tmp = setInterval(function (){
            // that.chatwoot()
            if (window.$chatwoot !== undefined) {
              clearInterval(tmp)
              window.$chatwoot.toggle('open');
            }
          },50)
        } else {
          window.$chatwoot.toggle('open');
        }
      } else {
        switch (Type) {
          case "wechat":
            Link = "weixin://" + Link
            break;
          case "qq":
            Link = "mqq://"+ Link
            break;
          case "whatsapp":
            Link = "whatsapp://send?phone="+ Link
            break;
          case "telegram":
            Link = "tg://resolve?domain="+ Link
            break;
          case "facebook":
            Link = "fb://"+ Link
            break;
          case "twitter":
            Link = "twitter://"+ Link
            break;
          case "instagram":
            Link = "instagram://"+ Link
            break;
          case "line":
            Link = "https://line.me/R/"+ Link
            break;
        }
        this.toPath(Link)
      }
    },
    toPath(path) {
      let a = document.createElement('a')
      a.href = path
      a.target = '_blank'
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    },
    chatwoot(Link){
      let that = this;
      if (Link.length === 0) {
        return;
      }
      let len = Link.indexOf("/widget");
      if (len === -1) {
        return;
      }
      var BASE_URL=Link.substr(0,len);
      let tokenlen = Link.indexOf("token=");
      let token = Link.substr(tokenlen+6);
      window.chatwootSDK.run({
        websiteToken: token,
        baseUrl: BASE_URL
      });
      that.inter = setInterval(function(){
        if(document.getElementsByClassName("woot-widget-bubble")[0] !== undefined) {
          that.show = true;
          clearInterval(that.inter);
          window.$chatwoot.toggleBubbleVisibility('hide');
        }
      },50);
    },

  }
}
</script>
<style scoped>
.kefu-iframe{
  flex: 1; /* 让 iframe 填充剩余空间 */
  width: 100%;
  height: 100%;
  border: none; /* 可选：移除边框 */
}
.role-page1 {
  display: flex;
  flex-direction: column;
  height: 100vh; /* 设置为全屏高度 */
}
</style>